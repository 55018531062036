<div class="product-container-loader">
    <ngx-skeleton-loader
    [theme]="{
      width: '280px',
      height: '40px',
      'background-color': 'lightgray',
      'border-radius': '10px',
      'margin-bottom': '25px'
    }"
  >
  </ngx-skeleton-loader>
  <div class="products-loader">
      <app-product-loader *ngFor="let num of [].constructor(4)"></app-product-loader>
  </div>
</div>
