<div class="card-container" (click)="onCardClick()">
  <div>
    <img
      src="{{
        '../../../assets/icons/' +
          (card.franquicia === VISA_CARD_TYPE ? 'visa.svg' : card.franquicia === MASTER_CARD_TYPE ? 'mastercard.svg' : 'tarjeta.svg')
      }}"
      alt=""
      class="card-icon"
      [ngClass]="[
        cardSelected?.token !== card.token ? 'not-selected' : 'selected',
        card.franquicia === VISA_CARD_TYPE ? 'visa' : 'mastercard'
      ]"
    />
    ********{{ card.ultimos }}
  </div>
  <div class="col d-flex flex-row-reverse align-self-center">
    <img
      src="{{
        '../../../assets/icons/payment/' +
          (cardSelected?.token === card.token ? 'on.svg' : 'off.svg')
      }}"
      alt=""
      class="selected-icon"
    />
  </div>
</div>
