import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, filter } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart/shopping-cart.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { Category } from 'src/app/interfaces/products.interface';
import { BaseHeaderComponent } from '../base-header/base-header.component';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent extends BaseHeaderComponent implements OnInit, OnDestroy {

  showInput: boolean = false;

  search = new FormControl('');

  constructor(
    protected shoppingCartService: ShoppingCartService,
    protected userService: UserService,
    protected router: Router,
    protected productService: ProductsService,
    protected authService: AuthService,
    protected localStorage: LocalstorageService,
    @Inject(PLATFORM_ID) protected platFormId
  ) {
    super(router, userService, productService, shoppingCartService, authService, localStorage, platFormId);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => 
      {
        if(this.router.url == '/categories/busqueda') {
          this.showInput = true;
        } else {
          this.showInput = false;
        }
      })
    if(this.router.url == '/categories/busqueda') {
      this.showInput = true;
    }
    this.search.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value: string) => {
        this.productService.onSendSearchParam(value.toLowerCase());
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  showCloseInput(): void {
    this.showInput = !this.showInput;
  }

  clicSearch(): void {
    if(this.router.url != '/categories/busqueda') {
      const categoryBusqueda: Category = { nombre: 'busqueda', slug: 'busqueda' };
      this.showInput = true;
      this.navigateCategory(categoryBusqueda);
    } else {
      this.showCloseInput();
    }
  }

}
