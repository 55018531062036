<div
  class="modal fade-modal"
  id="mapCoverageModal"
  aria-labelledby="mapCoverageLabel"
>
  <div class="modal-dialog show2">
    <div class="modal-content">
      <div class="modal-body">
        <div class="col- col-title">
          <p class="title h3">Indica una dirección para la entrega</p>
        </div>
        <div class="row row-map">
          <div class="row">
            <div class="col-12">
              <p class="advice h7">
                Verifica si tu posición es la correcta. Si no la es, arrastra el
                mapa a la posición correcta.
              </p>
            </div>
          </div>

          <div class="col-12 col-map">
          <google-map
          width="100%"
          height="300px"
          [center]="pointGPS"
            (mapDragend)="changeCenter()"
          >
            <map-marker [position]="pointGPS" [options]="marker.options">
            </map-marker>
          </google-map>
        </div>
        </div>
        <div>
          <div class="row row-btn">
            <div class="col-4 col-before">
              <p
                class="before h5"
                data-toggle="modal"
                data-target="#coverageFormModal"
                data-dismiss="modal"
              >
                <i class="fa fa-chevron-left"></i>

                Volver
              </p>
            </div>
            <div class="col-5 col-btn">
              <button
                type="submit"
                class="btn btn-primary btn-continue h5"
                data-toggle="modal"
                data-target="#addAddressModal"
                data-dismiss="modal"
                (click)="validatePosition()"
              >
                Validar cobertura
              </button>
              <!-- Button which close this modal -->
              <button
                [hidden]="true"
                id="closeMapCovarage"
                data-dismiss="modal"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
