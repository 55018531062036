import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BrandsService {
  baseURL = environment.backendUrl;
  headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient) {}
  /**
   * Get all brands from firebase returns a Promise
   */
  getBrands() {
    try {
      return firebase.firestore().collection('marcas').get();
    } catch (error) {
      console.log(`Error getting brands: ${error}`);
    }
  }

  /**
   * Gets a brand by the specified name
   * @param name
   */
  getBrandByName(name: string) {
    try {
      return firebase.firestore().collection('marcas').where('nombre', '==', name).get();
    } catch (error) {
      console.log(`Error getting brand by name: ${error}`);
    }
  }
}
