<app-address-form
  (geolocationData)="getGeolocationData($event)"
  [salePoints]="salePoints"
  [user]="user"
>
</app-address-form>

<app-address-map
  [pointGPS]="pointGPS"
  [address]="address"
  (salePointData)="getSalePointData($event)"
  [salePoints]="salePoints"
>
</app-address-map>
<app-address-add
  [pointGPS]="pointGPS"
  [salePoint]="salePoint"
  [coverage]="coverage"
  [address]="address"
  [user]="user"
>
</app-address-add>
<div *ngIf="user">
  <app-address-list
    [addressList]="user.direcciones"
    [salePoints]="salePoints"
    [user]="user"
  ></app-address-list>
</div>

<app-deliver-selector (openModal)='openModal($event)' [user]="user"></app-deliver-selector>
<app-salepoint-selector [salePoints]="salePoints" [user]="user"></app-salepoint-selector>

<!-- Buttons which open modals -->
<button [hidden]="true" id="openAddressListModal" data-toggle="modal" data-target="#addressListModal"></button>
<button [hidden]="true" id="openCovarageFormModal" data-toggle="modal" data-target="#coverageFormModal"></button>
<button [hidden]="true" id="openSalePointSelector" data-toggle="modal" data-target="#salePointSelectorModal"></button>
<button [hidden]="true" id="openDeliverySelector" data-toggle="modal" data-target="#deliverSelectorModal"></button>

