<div class="calculator-float">
    <button class="btn btn-calculator" data-toggle="modal" data-target="#calculatorForm">
        <img src="../../../assets/icons/home-main/calculator_icon.svg" alt="icono calculadora">
    </button>
</div>

<div class="modal fade" id="calculatorForm">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="modal-header d-flex justify-content-between">
                    <h5 class="modal-title" id="exampleModalLabel">
                        <img src="../../../assets/icons/home-main/calculator_icon.svg" alt="icono calculadora"> 
                        Calculadora
                    </h5>
                    <button type="button" class="close justify-content-end" data-dismiss="modal"
                        aria-label="Close" #closeButton (click)="resetForms()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="d-flex">
                    <span class="title-line"></span>
                </div>
                <ng-container *ngIf="!showOutput">
                    <form [formGroup]="calculatorInputForm">
                        <div class="form-container d-flex flex-column">
                            <div>
                                <label class="form-label">Consumo mensual (kg)</label>
                                <input type="number" class="form-control" formControlName="consumoMesKg" placeholder="Escribe aqui" />
                            </div>
                            <div>
                                <label class="form-label">Puntos de venta</label>
                                <input type="number" class="form-control" formControlName="PDV" placeholder="Escribe aqui" />
                            </div>
                            <div>
                                <label class="form-label">Fecuencia Semanal</label>
                                <input type="number" class="form-control" formControlName="frecuenciaSemanal" placeholder="Escribe aqui" />
                            </div>
                            <div>
                                <label class="form-label">Valor Centralizado</label>
                                <span class="currency-input">
                                    <p>$</p><input type="number" class="form-control" formControlName="valorCentralizado" placeholder="Escribe aqui" />
                                </span>
                            </div>
                            <div>
                                <label class="form-label">Valor Descentralizado</label>
                                <span class="currency-input">
                                    <p>$</p><input type="number" class="form-control" formControlName="valorDescentralizado" placeholder="Escribe aqui" />
                                </span>
                            </div>
                            <div>
                                <label class="form-label">Precio por kg en pesos</label>
                                <span class="currency-input">
                                    <p>$</p><input type="number" class="form-control" formControlName="precioKgEnPesos" placeholder="Escribe aqui" />
                                </span>
                            </div>
                        </div>
                        <button class="btn btn-calculate" [disabled]="!calculatorInputForm.valid" (click)="calculate(calculatorInputForm.value, $event)">
                            Calcular
                        </button>
                    </form>
                </ng-container>
                <ng-container *ngIf="showOutput">
                    <form [formGroup]="calculatorInputForm">
                        <div class="form-container d-flex flex-column">
                            <div class="row">
                                <div class="col-6">
                                    <label class="form-label">Consumo mensual (kg)</label>
                                    <input type="number" [attr.disabled]="true" class="form-control" formControlName="consumoMesKg"/>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">Puntos de venta</label>
                                    <input type="number" [attr.disabled]="true" class="form-control" formControlName="PDV"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label class="form-label">Fecuencia Semanal</label>
                                    <input type="number" [attr.disabled]="true" class="form-control" formControlName="frecuenciaSemanal"/>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">Valor Centralizado</label>
                                    <span class="currency-input" [ngClass]="{'disabled': showOutput}">
                                        <p [attr.disabled]="true">$</p><input type="number" [attr.disabled]="true" class="form-control" formControlName="valorCentralizado"/>
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label class="form-label">Valor Descentralizado</label>
                                    <span class="currency-input" [ngClass]="{'disabled': showOutput}">
                                        <p [attr.disabled]="true">$</p><input type="number" [attr.disabled]="true" class="form-control" formControlName="valorDescentralizado"/>
                                    </span>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">Precio por kg en pesos</label>
                                    <span class="currency-input" [ngClass]="{'disabled': showOutput}">
                                        <p>$</p><input type="number" [attr.disabled]="true" class="form-control" formControlName="precioKgEnPesos"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                    <hr>
                    <form [formGroup]="calculatorOutputForm">
                        <div class="form-container d-flex flex-column">
                            <div class="row">
                                <div class="col-6 d-flex flex-column justify-content-end">
                                    <label class="form-label">Semanal</label>
                                    <input type="number" class="form-control" formControlName="semanal"/>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">Por entrega según frecuencia</label>
                                    <input type="number" class="form-control" formControlName="entregaFrecuencia"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label class="form-label">Por entrega por PDV</label>
                                    <input type="number" class="form-control" formControlName="porPDV"/>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">Costo por entrega</label>
                                    <span class="currency-input">
                                        <p>$</p><input type="number" class="form-control" formControlName="costoEntrega"/>
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label class="form-label">Costo mensual</label>
                                    <span class="currency-input">
                                        <p>$</p><input type="number" class="form-control" formControlName="costoMensual"/>
                                    </span>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">Costo anual</label>
                                    <span class="currency-input">
                                        <p>$</p><input type="number" class="form-control" formControlName="costoAnual"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-calculate" (click)="printForm(calculatorInputForm.value, calculatorOutputForm.value)">
                            Imprimir
                        </button>
                    </form>
                </ng-container>
            </div>
        </div>
    </div>
</div>