import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Product } from 'src/app/interfaces/products.interface';

@Component({
  selector: 'app-video-product',
  templateUrl: './video-product.component.html',
  styleUrls: ['./video-product.component.scss']
})
export class VideoProductComponent implements OnInit {
  
  _product: Product;

  public sUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer,) { }

  @Input() set product(value: Product) {
    this._product = value;
  };
  /**
   * Get the current pruduct 
   */ 
  get product(): Product {
    return this._product;
  }

  ngOnInit(): void {
     this.sUrl = this.getVideoIframe(this.product.video);
  }


  /**
   * Metodo para cargar video
   * @param url ruta del viedeo.
   */
   getVideoIframe(url): SafeResourceUrl {
    if (url) {
      console.log("iniciando video");
      let video: string;
      let results: any[];
      results = url.match('[\\?&]v=([^&#]*)');
      video = (results === null) ? url : results[1];
      console.log("terminado video");
      return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video);
    }
    if (url === null) {
      return '';
    }
  }



}
