<div class="layout d-flex flex-column">
  <div class="flex-grow-1 d-flex flex-column">
    <router-outlet></router-outlet>
  </div>
</div>

<div *ngIf="mdlShoppingCart">
  <app-shopping-cart
    (closeShoppingCard)="closeShoppingCard($event)"
  ></app-shopping-cart>
</div>


<!-- Modal -->
<div class="modal fade" id="modeDeliveryDisableModal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="modeDeliveyDisableTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
          <img src="../../../assets/icons/home-main/caution.png" alt="Signo de pregunta" class="question-icon">
          <p class="modal-title" id="questionsTitle">{{ message }}</p>
      </div>
      <div class="modal-footer">
        <button  (click)="chooseModeDelivery()" type="button" class="btn btn-primary" data-dismiss="modal">{{ txtButton }}</button>
    </div>
    </div>
  </div>
</div>

<app-footer class="flex-end"></app-footer>
