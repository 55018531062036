
<div
  class="modal fade"
  id="info-variable-modal"
  aria-labelledby="mapCoverageLabel"
>
  <div class="modal-dialog">
    <div class="modal-content img-variable-weigth">
      <div class="modal-body">
        <p class="close-info-modal" data-dismiss="modal">X Cerrar</p>
      </div>
    </div>
  </div>
</div>
