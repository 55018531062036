<app-header></app-header>
<app-mobile-header></app-mobile-header>

<div class="container-fluid recetas-contianer">
  <div class="row title-contianer">
    <div class="col-12 custom-banner">
      <img src="../../../assets/banners/BannerCategorias.png" alt="Banner">
      <div class="overlay-text">
        Inicio / Recetas / {{categoriaSelected}}
      </div>
    </div>
  </div>
  <div class="filter-container">
    <div class="text-filter">
      Mostrando {{currentPage}}-{{ totalItems < itemsPerPage ? totalItems : itemsPerPage }} de {{totalItems}} resultados
    </div>
    <app-order-button [options]="orderOptions" (optionEmitter)="handleOrderButtonEvent($event)"></app-order-button>
  </div>


  <section *ngIf="!mainLoading && recetasSelected">
    <div class="row custom-row" [hidden]=" recetasSelected.length === 0">
      <app-ver-recetas class="col-md-4 custom-md-4" *ngFor="let receta of recetasSelected" [rec]="receta"></app-ver-recetas>
    </div>
    <div class="row custom-row" [hidden]=" recetasSelected.length !== 0">
      <div class="col-md-4">

      </div>
      <div class="col-md-4">
        <img src="../../../../assets/icons/header/logo-calypso.png" class="img-100 pad-img" />
        <div class="text-products-empty"> ¡Aun no hay recetas en esta categoría!</div>
      </div>
    </div>
  </section>
  <div *ngIf="mainLoading || (!mainLoading && recetasSelected.length <= 0)">
    <app-product-container-loader ></app-product-container-loader>
  </div>
  <app-pagination [itemsPerPage]="itemsPerPage" [totalItems]="totalItems" [currentPage]="currentPage"
    (redirectToPageEmitter)="onGoToPage($event)" (nextPageEmitter)="nextPage()"
    (previousPageEmitter)="previousPage()"></app-pagination>
</div>
