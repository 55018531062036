import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { User } from '../../interfaces/user.interface';
import * as moment from 'moment';
import { UserService } from 'src/app/core/services/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { TransactionService } from '../../core/services/transaction/transaction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { format } from 'path';
import { formattedError } from '@angular/compiler';
import { emailPattern } from 'src/app/global/utils';

@Component({
  selector: 'app-add-credit-card',
  templateUrl: './add-credit-card.component.html',
  styleUrls: ['./add-credit-card.component.scss'],
})
export class AddCreditCardComponent implements OnInit {
  /**
   * ViewChild to get modal close button
   */
  @ViewChild('closeButton') closeButton;

  @Output() added = new EventEmitter<boolean>();

  /**
   * Used for check if a card was add.
   */
  wasAdded = false;

  /** Save the subscribe tu user logged */
  subscriptionUserLogged

  /**
   * Stores the initial data de email
   */
  public emailInit = '';

  /**
   * Array that contains the fronted pattern of the Mastercard cards.
   */
  private masterCard = ['51', '52', '53', '54', '55'];


  /**
   * Variable that store modal form
   */
  cardForm = this.formBuilder.group({
    cardNumber: ['', [Validators.required, Validators.pattern('[0-9-]+')]],
    cardHolder: ['', Validators.required],
    documentType: ['', Validators.required],
    documentNumber: ['', Validators.required],
    email: [this.emailInit, Validators.required, Validators.pattern(emailPattern)],
    expirationMonth: ['', Validators.required],
    expirationYear: ['', Validators.required],
    cvc: [
      '',
      [Validators.required, Validators.minLength(3), Validators.maxLength(3)],
    ],
    cardHolderPhone: ['', Validators.required],
    billingCountry: ['', Validators.required],
  });

  /**
   * Variable that store the document types showed to user
   */
  documentTypes = [
    { name: 'Cédula de ciudadanía', shortName: 'CC' },
    { name: 'Cédula de extranjería', shortName: 'CE' },
    { name: 'Pasaporte', shortName: 'PPN' },
  ];
  /**
   * Variable that store the bulling countries showed to user
   */
  billingCountries = [{ name: 'Colombia' }];

  /**
   * Variable that store the months of the year
   */
  expirationMonths = Array(12)
    .fill(0)
    .map((x, i) => `${i + 1}`.padStart(2, '0'));

  /**
   * Variable that store 10 years from now on
   */
  expirationYears = Array(10)
    .fill(moment().year())
    .map((x, i) => x + i);

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private transactionService: TransactionService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.subscriptionUserLogged = this.userService.getUserIdLogged().subscribe((userId) => {
      if (userId) {
        return this.userService
          .getUserbyId(userId)
          .get()
          .then((doc) => {
            const user = doc.data() as User;
            this.emailInit = user.email;
          })
          .catch((error) => {
            this.failedTransaction(error);
          });
      }
      this.failedTransaction('', 'Usuario no encontrado.');
    });
  }

  ngOnDestroy(){
    this.subscriptionUserLogged.unsubscribe()
  }

  /**
   * Function execute when submit form. Get form data and send to firebase
   */
  onSaveCard(): void {
    if (this.cardForm.invalid) {
      return;
    }

    this.spinner.show();
    const cardData = {
      cardNumber: this.cardForm.get('cardNumber').value,
      cardHolder: this.cardForm.get('cardHolder').value,
      documentType: this.cardForm.get('documentType').value,
      documentNumber: this.cardForm.get('documentNumber').value,
      expirationMonth: this.cardForm.get('expirationMonth').value,
      expirationYear: this.cardForm.get('expirationYear').value,
      cvc: this.cardForm.get('cvc').value,
      cardHolderPhone: this.cardForm.get('cardHolderPhone').value,
      billingCountry: this.cardForm.get('billingCountry').value,
    };

    if (cardData.cardNumber.substr(0, 1) === '4' ||
      this.masterCard.includes(cardData.cardNumber.substr(0, 2))) {
      this.storeEpaycoAndUpdateUser(cardData);
    } else {
      this.spinner.hide();
      this.toastr.info('Aceptamos solo Visa y Mastercard');
    }
  }

  /**
   * Function to store
   * @param cardData Data to store as payment method on user object
   */
  storeEpaycoAndUpdateUser(cardData): void {
    this.userService.getUserIdLogged().subscribe((userId) => {
      if (userId) {
        return this.userService
          .getUserbyId(userId)
          .get()
          .then((doc) => {
            const user = doc.data() as User;
            user.email = this.cardForm.get('email').value;
            const epaycoObj = this.createEpaycoObject(cardData, user);
            this.saveEpayco(epaycoObj, user);
          })
          .catch((error) => {
            this.failedTransaction(error);
          });
      }
      this.failedTransaction('', 'Usuario no encontrado.');
    });
  }

  /**
   * Send request to validate card dato on Epayco and store on user if successful
   * @param cardData Data to store as payment method on user object
   * @param user Data of user on session
   */
  saveEpayco(cardData, user): any {
    return this.transactionService.storeCard(cardData).subscribe(
      (res) => {
        if (res.success) {
          res.tarjeta.documento = cardData.usuario.documento;
          if (user.metododepago) {
            user.metododepago.push(res.tarjeta);
          } else {
            user.metododepago = [res.tarjeta];
          }
          this.updateUser(user);
          this.wasAdded = true;
          this.added.emit(this.wasAdded);
        } else {
          this.failedTransaction(res);
        }
      },
      (err) => {
        this.failedTransaction(err);
      }
    );
  }

  /**
   * Send request to update user when card added successful
   * @param user Data of user on session
   */
  updateUser(user): void {
    this.userService
      .updateUserData(user)
      .then((updateUser) => {
        this.spinner.hide();
        this.toastr.success('Se agregó la tarjeta correctamente.');
        this.closeButton.nativeElement.click();
      })
      .catch((error) => {
        this.failedTransaction(error);
      });
  }

  /**
   * Function that return data to send to backend and validate card data
   * @param cardData Data to store as payment method on user object
   * @param user Data of user on session
   */
  createEpaycoObject(cardData, user): object {
    const obj = {
      card: {
        number: '' + cardData.cardNumber.replace(/-/g, '').replace(/ /g, ''),
        exp_year: '' + cardData.expirationYear,
        exp_month: '' + cardData.expirationMonth,
        cvc: '' + cardData.cvc,
      },
      /**
       * Datos de titular que se guardan en la tarjeta.
       */
      usuario: {
        id: user.uid,
        name: cardData.cardHolder,
        email: user.email,
        telefono: '' + cardData.cardHolderPhone,
        ciudad: user.puntoDeVentaCiudad,
        direccion: user.direccion,
        cedula: '' + cardData.documentNumber,
        documento: '' + cardData.documentType,
      },
    };
    return obj;
  }

  /**
   * Function that handle error or unsuccessful request
   * @param error Message to log
   * @param message Message to show on toast
   */
  failedTransaction(error?, message = 'Error al agregar la tarjeta.'): void {
    this.spinner.hide();
    this.toastr.error(message + (error ? ' Error: ' + error : ''));
    console.log(`Failed to update user: ${error}`);
  }

  /**
   * Function that format car number to split every 4 numbers
   * @param event params that have the dom event
   */
  formatCard(event: any): void {
    let formatedCard = event.target.value
      .replace(/\W/gi, '')
      .replace(/(.{4})/g, '$1-');
    if (formatedCard.endsWith('-')) {
      formatedCard = formatedCard.substring(0, formatedCard.length - 1);
    }
    this.cardForm.controls.cardNumber.setValue(formatedCard);
  }

  resetForm() {
    this.cardForm.reset();
  }
}
