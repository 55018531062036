
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecetasRoutingModule } from './recetas-routing.module';
import { RecetasAllComponent } from './components/recetas-all/recetas-all.component';
import { SharedModule } from '../shared/shared.module';
import { VerRecetasComponent } from './components/ver-recetas/ver-recetas.component';
import { RecetaComponent } from './components/receta/receta.component';
import { ComnsalesPipe } from './pipe/comnsales.pipe';
import { OurStoresModule } from '../our-stores/our-stores.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProductsModule } from '../products/products.module';



@NgModule({
  declarations: [RecetasAllComponent, VerRecetasComponent, RecetaComponent, ComnsalesPipe],
  imports: [
    CommonModule,
    RecetasRoutingModule,
    OurStoresModule,
    ProductsModule,
    SharedModule,
    NgxSpinnerModule,
    NgxSkeletonLoaderModule
  ]
})
export class RecetasModule { }
