import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { UserService } from 'src/app/core/services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceRecetasService } from 'src/app/core/services/recetas/service-recetas.service';
import { Category, Product } from 'src/app/interfaces/products.interface';
import { ProductsService } from 'src/app/core/services/products/products.service';
import * as _ from 'lodash';
import { Address, SalePoint, ShippingMethod, ShippingMethodType } from 'src/app/interfaces/salePoints.interface';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SalePointsService } from 'src/app/core/services/sale-points/sale-points.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart/shopping-cart.service';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';
import { User } from 'src/app/interfaces/user.interface';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  @Output() navigateEvent = new EventEmitter<string>();
  @Output() openAddressModalEvent = new EventEmitter();
  @Output() deployShoppingCartEvent = new EventEmitter();
  @Output() logoutEvent = new EventEmitter();

  usuario: any;

  public tiendasTab: boolean = false;
  public conocenosTab: boolean = false;
  public recetasTab: boolean = false;

  public cat = [];
  // Lista de recetas global
  public recetasGlobales: any[] = [];
  public recipeCategories = [
    {
      nombre: 'Todas',
      url: '../../../../assets/imgs/imgs-tests/categorias-todas.png',
    },
  ];

  public conocenosContent = [
    {
      nombre: '¿Quienes somos?',
      image: '../../../assets/imgs/img-about/conoce_somos.png',
      slug: 'quienes-somos'
    },
    {
      nombre: 'Contáctanos',
      image: '../../../assets/imgs/img-about/conoce_contact.png',
      slug: 'contactanos'
    },
    {
      nombre: 'Trabaja con nosotros',
      image: '../../../assets/imgs/img-about/conoce_work.png',
      slug: 'trabaja-con-nosotros'
    }
  ]

  public tempCategories = [
    {
      nombre: 'Res',
      image: '../../../assets/imgs/Img-our-products/res_stock.png'
    },
    {
      nombre: 'Pollo',
      image: '../../../assets/imgs/Img-our-products/pollo_stock.png'
    },
    {
      nombre: 'Cerdo',
      image: '../../../assets/imgs/Img-our-products/cerdo_stock.png'
    },
    {
      nombre: 'Pescado',
      image: '../../../assets/imgs/Img-our-products/pescado_stock.png'
    },
    {
      nombre: 'Mariscos',
      image: '../../../assets/imgs/Img-our-products/mariscos_stock.png'
    },
    {
      nombre: 'Pavo',
      image: '../../../assets/imgs/Img-our-products/pavo_stock.png'
    },
    {
      nombre: 'Carbohidratos',
      image: '../../../assets/imgs/Img-our-products/carbohidratos_stock.png'
    },
    {
      nombre: 'Frutas y Verduras',
      image: '../../../assets/imgs/Img-our-products/fyv_stock.png'
    },
    {
      nombre: 'Quesos y Lacteos',
      image: '../../../../assets/imgs/Img-our-products/quesos_stock.png'
    },
    {
      nombre: 'Salsas',
      image: '../../../assets/imgs/Img-our-products/salsas_stock.png'
    },
    {
      nombre: 'Pan y Otros',
      image: '../../../assets/imgs/Img-our-products/pan_stock.png'
    },
    {
      nombre: 'Tortillas Mexicanas',
      image: '../../../assets/imgs/Img-our-products/totillas_stock.png'
    },
    {
      nombre: 'Pasabocas',
      image: '../../../assets/imgs/Img-our-products/pasabocas_stock.png'
    },
    {
      nombre: 'Condimentos',
      image: '../../../assets/imgs/Img-our-products/condimentos_stock.png'
    },
    {
      nombre: 'Aceites',
      image: '../../../assets/imgs/Img-our-products/aceites_stock.png'
    },
    {
      nombre: 'Bebidas instantaneas',
      image: '../../../assets/imgs/Img-our-products/bebidas_stock.png'
    },
    {
      nombre: 'Pre-listos',
      image: '../../../assets/imgs/Img-our-products/prelistos_stock.png'
    },
    {
      nombre: 'Encurtidos',
      image: '../../../assets/imgs/Img-our-products/encurtidos_stock.png'
    },
    {
      nombre: 'Empaques',
      image: '../../../assets/imgs/Img-our-products/empaques_stock.png'
    },
    {
      nombre: 'Otros',
      image: '../../../assets/imgs/Img-our-products/otros_stock.png'
    },
  ];
  public selectedCategory = '';
  public salePoints: SalePoint[];
  public categories: Category[] = [];
  public productsToShow: Product[] = [];
  public subscribeShippingMethodGlobal: Subscription;

  public user: User = {
    email: '',
    celular: '',
    createdAt: NaN,
    nombre: '',
    uid: '',
    tipo: '',
    aceptoTerminos: false,
    direccionIp: '',
  };

  @ViewChild('sidemenucheckbox') checkbox;

  constructor(
    public userService: UserService,
    private router: Router,
    private serviceRecetasService: ServiceRecetasService,
    private productsService: ProductsService,
    protected authService: AuthService,
    protected activatedRoute: ActivatedRoute,
    protected productService: ProductsService,
    protected salePointsService: SalePointsService,
    protected shoppingCartService: ShoppingCartService,
    protected localStorage: LocalstorageService,
    @Inject(PLATFORM_ID) protected platFormId
    ) {}

  ngOnInit(): void {
    this.userService.getUserIdLogged().subscribe(id => this.usuario = id);
    this.getRecipes();
    this.getSalePointOperation();
    if (isPlatformBrowser(this.platFormId)) {
      this.initializeUserAndProductInfo();
      this.observableAddress();
    }
  }

  onClose(): void {
    this.checkbox.nativeElement.checked = false;
  }
  onDeployShoppingCart(): void {
    this.deployShoppingCartEvent.emit();
    this.onClose();
  }
  openAddressModal(): void {
    this.openAddressModalEvent.emit();
    this.onClose();
  }
  logout(): void {
    this.localStorage.removeItem('raspaGana')
    this.localStorage.removeItem('user');
    this.logoutEvent.emit();
    this.onClose();
    setTimeout(() => {
      location.href = location.href;
    }, 1000);
  }
  navigate(route: string): void {
    this.navigateEvent.emit(route);
    this.onClose();
  }

  collapseTab(tab: string) {
    this.tiendasTab = tab === 'tienda' ? !this.tiendasTab : false;
    this.recetasTab = tab === 'recetas' ? !this.recetasTab : false;
    this.conocenosTab = tab === 'conocenos' ? !this.conocenosTab : false;
  }

  getRecipes() {
    this.serviceRecetasService.getCategorias().then((res) => {
      res.forEach((element) => {
        this.cat = element.data().categorias;
      });
      this.cat.sort((a, b) => a.nombre.localeCompare(b.nombre));
      this.cat.forEach((element) => {
        this.recipeCategories.push(element);
      });
    });
    this.serviceRecetasService.getRecetas().then((res) => {
      res.forEach((element) => {
        this.recetasGlobales.push(element.data());
      });
    });
  }

  updateSelectedRecipe(selected) {
    this.serviceRecetasService.setRecipe(selected);
  }

  redirectRecipe(categorie): void {
    this.router.navigate(['/recetas']);
    this.updateSelectedRecipe(categorie);
    let sideMenu = document.getElementById('menuToggleInput')  as HTMLInputElement;
    sideMenu.checked = false;
    this.recetasTab = !this.recetasTab;
  }

  observableAddress(): void {
    this.subscribeShippingMethodGlobal = this.userService.sendGlobalShippingMethodObservable.subscribe(
      (shippingMethod) => {
        this.getCategoriesWithProductsAPI(shippingMethod, true);
      }
    );
  }

  getSalePointOperation(): void {
    this.salePointsService.getSalePoints()?.onSnapshot((res) => {
      this.salePoints = res.docs.map((doc) => {
        let data = doc.data() as SalePoint;
        return data;
      }).filter(sp => sp.bodega && sp.zonaCobertura && sp.lat && sp.lng && sp.tpv);
    }
    );
  }

  initializeUserAndProductInfo(): void {
    if (isPlatformBrowser(this.platFormId)) {
      const userLogged = JSON.parse(this.localStorage.getItem('userLogged'));
      if (userLogged) {
        this.userService.getUserIdLogged().subscribe((userId) => {
          if (userId) {
            this.getUserInfo(userId);
          }
        });
      } else {
        const shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod'));
        this.getCategoriesWithProductsAPI(shippingMethod, false);
        this.getShippingMethodActivated(shippingMethod);
      }
    }
  }

  /**
   * Get user information
   * @param userId User id
   */
  getUserInfo(userId: string): void {
    this.userService.getUserbyId(userId).onSnapshot((doc) => {
      this.user = doc.data() as User;
      let address: Address;
      let shippingMethod: ShippingMethod;
      // Send a address if there is valid and coveraged address
      if (address  && this.user.tipoEntrega !== 'Recogida') {
        address = this.user.direcciones.filter(
          (address) => address.principal === true
        )[0];
        shippingMethod = {
          type: ShippingMethodType.SHIPPING,
          address,
        };
        this.getCategoriesWithProductsAPI(shippingMethod, false);
      } else {
        shippingMethod = { type: ShippingMethodType.SHIPPING };
        this.getCategoriesWithProductsAPI(shippingMethod, false);
      }
      if (this.user.tipoEntrega && this.user.tipoEntrega === 'Recogida') {
        shippingMethod = {
          type: ShippingMethodType.IN_STORE,
          salePoint: this.salePoints.filter((sp) => sp.id === this.user.puntoDeVentaId)[0],
        };
        this.getCategoriesWithProductsAPI(shippingMethod, false);
      }
      this.getShippingMethodActivated(shippingMethod);
    });
  }

  getShippingMethodActivated(shippingMethod: ShippingMethod): void {
    if (shippingMethod?.type === ShippingMethodType.SHIPPING) {
      if (shippingMethod.address) {
        this.userService.onSendGlobalShippingMethod(shippingMethod);
      } else {
        if (isPlatformBrowser(this.platFormId)) {
          shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod'));
          this.userService.onSendGlobalShippingMethod(shippingMethod);
        }
      }
    } else if (shippingMethod?.type === ShippingMethodType.IN_STORE) {
      this.userService.onSendGlobalShippingMethod(shippingMethod);
    }
  }

  async getCategoriesWithProductsAPI(shippingMethod: ShippingMethod, isObservable: boolean) {
    this.categories = [];
    const citySelected = JSON.parse(this.localStorage.getItem('citySelected'));

    let allProducts = this.productsService.allProductsResponse ?? await this.productsService.getAllProductsAPI();
    let categories = this.productsService.allCategoriesResponse ?? await this.productsService.getAllCategoriesAPI();
          
    if (allProducts && categories) {
      this.productsService.getCategoriesAndProductsAPI(categories, allProducts).then((data) => {
        if (isObservable || citySelected) {
          const result = this.productsService.filterCategoriesByWarehouse(data, shippingMethod);
          this.categories = _.orderBy(result, ['index', 'nombre'], ['asc', 'asc']);
        } else {
          this.categories = _.orderBy(data, ['index', 'nombre'], ['asc', 'asc']);
        }
        this.productsService.categoriesWithProductsResponse = this.categories;
      })
    }
  }

  redirectStore(slug): void {
    this.router.navigate(['/tienda/', slug]);    
    let sideMenu = document.getElementById('menuToggleInput')  as HTMLInputElement;
    this.tiendasTab = false;
    sideMenu.checked = false;
  }

}
