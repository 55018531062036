import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/interfaces/products.interface';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit {
  @Input() public checkout: boolean;
  @Input() product: Product;
  costPerGram: number;
  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(): void {
    this.getCostPerGram(this.product);
  }

  /**
   * Calculated the cost by gram for a product
   * @param product Order product
   */
  getCostPerGram(product: Product) {
    this.costPerGram = parseFloat(
      (product.precio / product.pesoGramos).toFixed(2)
    );
  }
}
