import {
  Component,
  Input,
  OnInit,
  Inject,
  PLATFORM_ID,
  OnChanges,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Address, SalePoint, ShippingMethodType } from '../../../interfaces/salePoints.interface';
import * as $ from 'jquery';
import { UserService } from 'src/app/core/services/user/user.service';
import { User } from 'src/app/interfaces/user.interface';
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { GoogleMap } from '@angular/google-maps';
import { GeolocationService } from '../../../core/services/geolocation/geolocation.service';
import { address, segmentedName } from '../../../interfaces/user.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit {
  pointGPS: any;
  salePoint: SalePoint;
  coverage: boolean;
  @Input() salePoints: SalePoint[];
  @Input() address: any;
  @Input() user: User;
  @Output() salePointData = new EventEmitter();
  @Output() geolocationData = new EventEmitter();
  public center = { lat: 4.7110, lng: -74.0721 };
  public zoomInit = 4.7;
  public confirmAdd = false;

  public customNom: any;
  public customCity: any;
  public customLabel: any;
  marker = {
    options: {
      icon: '../../../../assets/imgs/img-address/casa-mapa.png',
    },
  };
  mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true, // Ocultar todas las etiquetas predeterminadas
    zoomControl: true,
  };

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  /**Saved the citites nomenclatures */
  cities: string[];
  /**Validate coverage */
  validCoverage: boolean;
  /**Validate coverageAdd */
  validCoverageAdd: boolean;
  /**Saved the short nomenclatures */
  nomenclaturaCorta: string = '';

  /**Saved the  */
  nomenclaturas: string[] = [
    'Avenida',
    'Avenida Calle',
    'Avenida Carrera',
    'Calle',
    'Carrera',
    'Diagonal',
    'Transversal'
  ];
  /**Saved the  */
  etiquetas: string[] = [
    'Casa',
    'Oficina',
    'Conjunto',
    'Otro',
  ];
  /**To block whit request */
  clicRequest: boolean = false;
  /**Show the address indicates in the html */
  indicationsView: string = '';
  /**Form which save the address information */
  addressForm = new FormGroup({
    ciudad: new FormControl('', [Validators.required]),
    nomenclatura: new FormControl('', [Validators.required]),
    numeroUno: new FormControl('', [Validators.required]),
    numeroDos: new FormControl('', [Validators.required]),
    numeroTres: new FormControl('', [Validators.required]),
    etiqueta: new FormControl('', [Validators.required]),
    indicaciones: new FormControl('', [Validators.required]),
  });
  constructor(
    private geolocationService: GeolocationService,
    private userService: UserService,
    private toastr: ToastrService,
    public router: Router,
    @Inject(PLATFORM_ID) private platFormId
    ) {}

  ngOnInit(): void {
    this.addressForm.get('indicaciones').valueChanges.subscribe((value) => {
      this.changeIndications(value);
    });
  }
  /**
   * Saved the indications
   * @param value string with contained the indications address
   */
  changeIndications(value: string) {
    this.indicationsView = value;
  }

  /**
   * Saved the new addres at the localStorage o firebase according to if there is user or not.
   * @param addressForm object with information of indications and etiqueta address
   */
  addAddress(addressForm: object) {
    this.clicRequest = true;
    if (this.user) {
      if (this.salePoint !== undefined) {
        this.addNewAddressToUser(addressForm);
      } else {
        this.addNewAddressToUserNoCovarege(addressForm);
      }
    } else {
      this.addNewAddressToStorage(addressForm);
    }
  }
  checkSelectLabel() {
    const selectLab = this.addressForm.get('etiqueta').value;
    this.customLabel = selectLab === 'Selecciona una opción' || selectLab === ''
     ? true : false
  }
  checkSelectOption() {
    this.coverage = true;
    const selectedCity = this.addressForm.get('ciudad').value;
    const selectAdd = this.addressForm.get('nomenclatura').value;

    this.customCity = selectedCity === 'Selecciona una opción' || selectedCity === ''
    ? true : false
    this.customNom = selectAdd === 'Selecciona una opción' || selectAdd === ''
     ? true : false

    if (selectedCity === 'Selecciona una opción' ||
        selectAdd === 'Selecciona una opción') {
      this.confirmAdd = false;
      this.zoomInit = 5.0;
      this.coverage = true;
      this.validCoverage = true;
    }
    if (selectedCity !== 'Selecciona una opción' ||
        selectAdd !== 'Selecciona una opción') {
      this.confirmAdd = false;
      this.zoomInit = 4.7;
      this.coverage = true;
      this.validCoverage = true;
    }
    // this.geolocate(this.addressForm.value);
  }
  checkFormData() {
    this.confirmAdd = false;
    this.coverage = true;
    this.validCoverage = true;
    this.zoomInit = 6.0;
    // Verifica si algún campo del formulario está vacío
    if (this.addressForm.get('numeroUno').value === '' ||
    this.addressForm.get('numeroDos').value === '' ||
    this.addressForm.get('numeroTres').value === '') {
      this.confirmAdd = false;
      this.zoomInit = 4.7;
      this.coverage = true;
      this.validCoverage = true;
    }
    // this.geolocate(this.addressForm.value);
  }
  /**
   * Saved new address at firebase
   * @param addressForm object with information of indications and etiqueta address
   */
  addNewAddressToUser(addressForm) {
    this.user.direccion =
      this.address.nomenclatura.replace(/ /g, '') +
      ' ' +
      this.address.numeroUno.replace(/ /g, '') +
      '#' +
      ' ' +
      this.address.numeroDos.replace(/ /g, '') +
      '-' +
      this.address.numeroTres.replace(/ /g, '');

    let address = {
      ciudad: this.address.ciudad,
      cobertura: this.coverage,
      etiqueta: addressForm.etiqueta,
      indicaciones: addressForm.indicaciones,
      inventario: this.salePoint.bodega || '',
      lat: this.center.lat,
      lng: this.center.lng,
      municipioAledano: '',
      nomenclatura: this.address.nomenclatura,
      numeroUno: this.address.numeroUno,
      numeroDos: this.address.numeroDos,
      numeroTres: this.address.numeroTres,
      principal: true,
    };

    this.user.puntoDeVentaBarrio = this.salePoint.barrio;
    this.user.puntoDeVentaBodega = this.salePoint.bodega || '';
    this.user.puntoDeVentaCiudad = this.salePoint.nombreMunicipio;
    this.user.puntoDeVentaCodMun = this.salePoint.codigoMunicipio;
    this.user.puntoDeVentaDepto = this.salePoint.codigoDepartamento;
    this.user.puntoDeVentaId = this.salePoint.id;
    this.user.puntoDeVentaNombre = this.salePoint.nombre;
    this.user.puntoDeVentaTpv = this.salePoint.tpv;
    this.user.puntoDeVentaWhatsapp = this.salePoint.whatsapp;
    // Validations of user.direcciones
    if (
      this.user.direcciones === undefined ||
      this.user.direcciones.length === 0  ||
      (this.user.direcciones.length === 1 && this.user.direcciones[0] === undefined)
    ) {
      this.user.direcciones = [address];
    } else {

      this.user.direcciones.forEach((direccion) => {
        direccion.principal = false;
      });
      this.user.direcciones.push(address);
    }
    this.user.tipoEntrega = "Domicilio";
    // Update user
    this.userService
      .updateUserData(this.user)
      .then(() => {
        this.clicRequest = false;
        this.userService.onSendGlobalShippingMethod({
          type: ShippingMethodType.SHIPPING,
          address: address
        });
        if (window.innerWidth < 490) {
          // Tamaño de pantalla pequeño: alineación al centro
          this.toastr.success('La dirección fue agregada exitosamente.',null,{
            positionClass: 'toast-top-center',
          });
        } else {
          // Tamaño de pantalla grande: alineación a la derecha
          this.toastr.success('La dirección fue agregada exitosamente.',null,{
            positionClass: 'toast-top-right',
          });
        }
        this.router.navigate(['/']);
        $('#addAddressModal')?.click();
      })
      .catch((error) => {
        this.clicRequest = false;
        this.toastr.error('No hemos podido agregar tu dirección.');
        console.log(`Error adding address: ${error}`);
      });
      this.confirmAdd = false;
  }
  /**
   * Saved new address at localStorage
   * @param addressForm object with information of indications and etiqueta address
   */
  addNewAddressToStorage(addressForm) {
    let address = {
      ciudad: this.address.ciudad,
      cobertura: this.coverage,
      etiqueta: addressForm.etiqueta,
      indicaciones: addressForm.indicaciones,
      inventario: this.salePoint !== undefined ? this.salePoint.bodega : '',
      salePoint: this.salePoint,
      lat: this.center.lat,
      lng: this.center.lng,
      municipioAledano: '',
      nomenclatura: this.address.nomenclatura,
      numeroUno: this.address.numeroUno,
      numeroDos: this.address.numeroDos,
      numeroTres: this.address.numeroTres,
      principal: true,
      distribuidor: this.salePoint !== undefined ?
                                  this.salePoint.distribuidor !== undefined ?
                                    this.salePoint.distribuidor : ''
                                  :'',
    };

    if (isPlatformBrowser(this.platFormId)) {
      let shippingMethod = {
        type: ShippingMethodType.SHIPPING,
        address: address
      };
      localStorage.setItem('shippingMethod', JSON.stringify(shippingMethod));
      this.coverage ? localStorage.setItem('cobertura', 'SI') : localStorage.setItem('cobertura', 'NO');
      this.userService.onSendGlobalShippingMethod(shippingMethod);
      this.router.navigate(['/']);
    }
    this.clicRequest = false;
    if (window.innerWidth < 490) {
      // Tamaño de pantalla pequeño: alineación al centro
      this.toastr.success('La dirección fue agregada exitosamente.',null,{
        positionClass: 'toast-top-center',
      });
    } else {
      // Tamaño de pantalla grande: alineación a la derecha
      this.toastr.success('La dirección fue agregada exitosamente.',null,{
        positionClass: 'toast-top-right',
      });
    }
    $('#addAddressModal')?.click();
    this.confirmAdd = false;
  }

  /**
   * Saved new address at firebase when there isn't covarege
   * @param addressForm object with information of indications and etiqueta address
   */
  addNewAddressToUserNoCovarege(addressForm) {
    this.user.direccion =
      this.address.nomenclatura.replace(/ /g, '') +
      ' ' +
      this.address.numeroUno.replace(/ /g, '') +
      '#' +
      ' ' +
      this.address.numeroDos.replace(/ /g, '') +
      '-' +
      this.address.numeroTres.replace(/ /g, '');

    let address = {
      ciudad: this.address.ciudad,
      cobertura: this.coverage,
      etiqueta: addressForm.etiqueta,
      indicaciones: addressForm.indicaciones,
      inventario: '',
      lat: this.center.lat,
      lng: this.center.lng,
      municipioAledano: '',
      nomenclatura: this.address.nomenclatura,
      numeroUno: this.address.numeroUno,
      numeroDos: this.address.numeroDos,
      numeroTres: this.address.numeroTres,
      principal: true,
    };

    // Validations of user.direcciones
    if (
      this.user.direcciones == undefined ||
      this.user.direcciones.length == 0
    ) {
      this.user.direcciones = [address];
    } else {
      this.user.direcciones.forEach((direccion) => {
        direccion.principal = false;
      });
      this.user.direcciones.push(address);
    }

    this.user.tipoEntrega = "Domicilio";

    // Update user
    this.userService
      .updateUserData(this.user)
      .then(() => {
        this.clicRequest = false;
        this.userService.onSendGlobalShippingMethod({
          type: ShippingMethodType.SHIPPING,
          address: address
        });

        this.router.navigate(['/']);
        if (window.innerWidth < 490) {
          // Tamaño de pantalla pequeño: alineación al centro
          this.toastr.success('La dirección fue agregada exitosamente.',null,{
            positionClass: 'toast-top-center',
          });
        } else {
          // Tamaño de pantalla grande: alineación a la derecha
          this.toastr.success('La dirección fue agregada exitosamente.',null,{
            positionClass: 'toast-top-right',
          });
        }
        $('#addAddressModal')?.click();
      })
      .catch((error) => {
        this.clicRequest = false;
        this.toastr.error('No hemos podido agregar tu dirección.');
        console.log(`Error adding address: ${error}`);
      });
      this.confirmAdd = false;
  }

  ngOnChanges() {
    if (this.salePoints) {
      this.getCities(this.salePoints);
    }
  }
  openModal(event): void {
    $(event)?.click();
    this.closeModal();
  }
  closeModal(): void {
    $('#closeCovarageForm')?.click();
  }
  /**
   * Get the cities with service
   * @param salePoints Array that contained the sale points
   */
  getCities(salePoints: SalePoint[]) {
    let cities = [];
    salePoints.forEach((salePoint) => {
      cities.push(salePoint.nombreMunicipio);
    });
    cities = Array.from(new Set(cities));
    cities.push('Briceño', 'Gachancipá', 'Soledad', 'Sopó');
    cities.sort();
    this.cities = cities;
  }
  cambiarBoton(){
    this.confirmAdd = true;
  }
  submitForm() {
    this.cambiarBoton();
    if (this.addressForm.valid) {
      this.geolocate(this.addressForm.value);
    }
  }
  /**
   * Located the address
   * @param address Addres selected
   */
  async geolocate(address: Address) {
    this.cambiarBoton();
    this.address = this.addressForm.value;
    let { nomenclatura, ciudad, numeroUno, numeroDos, numeroTres } = address;
    let searchAddress =
      nomenclatura.replace(/ /g, '') +
      ' ' +
      numeroUno.replace(/ /g, '') +
      ' ' +
      numeroDos.replace(/ /g, '') +
      '-' +
      numeroTres.replace(/ /g, '') +
      ' ,' +
      ciudad.replace(/ /g, '');
    let res: any = await this.geolocationService
      .getGeolocationGPS(searchAddress, this.salePoints)
      .then((coordinates) => {
        this.zoomInit = 17.0;
        return coordinates;
      })
      .catch((error) => {
        return error;
      });
      if ((res.match === undefined && res.precision === 'RANGE_INTERPOLATED') || 
      (res.match === undefined && res.precision === 'ROOFTOP')) {
        this.validCoverageAdd = true;
      } else{
        this.validCoverageAdd = false;
      }
      this.center = res.pointGPS;
      this.confirmAdd = true;
    res.address = this.addressForm.value;
    this.geolocationData.emit(res);
  }
  /**
   * Update the location of point according to the map
   */
  changeCenter() {
    let coordinateCenter = JSON.parse(JSON.stringify(this.map.getCenter()));
    this.pointGPS = coordinateCenter;
    this.center = coordinateCenter;
  }


  /**Validate the position in the map */
  async validatePosition() {
    let res: any;
    if (this.addressForm.value.ciudad && this.addressForm.value.ciudad.includes('Rodadero')) {
      let rodaderoSalePoint = this.salePoints.filter(sp => sp.nombre.includes('Rodadero'))[0];
      res = { coverage: true, salePointInfo: rodaderoSalePoint }
    } else {
      res = await this.geolocationService
        .validateLocationMap(this.center, this.salePoints)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error;
        });
    }
    res.pointGPS = this.center;
    this.salePoint = res.salePointInfo;
    this.coverage = res.coverage;
    this.validCoverage = this.validCoverageAdd;
    if (this.coverage && this.validCoverage) {
      this.addAddress(this.addressForm.value)
      await this.salePointData.emit(res);
      $('#closeCovarageForm')?.click();
      this.confirmAdd = false;
    }
  }
}
