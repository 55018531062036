import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { User } from 'src/app/interfaces/user.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';
import { ShippingMethod, ShippingMethodType } from 'src/app/interfaces/salePoints.interface';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { LocalstorageService } from '../localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  /** Saved the global shipping method */
  globalShippingMethod: ShippingMethod;
  /** Global shipping method subject */
  private sendShippingMethodGlobalSubject = new Subject<ShippingMethod>();
  /** Global shipping method observable */
  sendGlobalShippingMethodObservable = this.sendShippingMethodGlobalSubject.asObservable();
  /** Url backend */
  baseURL: String = environment.backendUrl;
  headers: HttpHeaders;
  isBrowser: boolean;

  constructor(
    private afAuth: AngularFireAuth,
    private http: HttpClient,
    private productsService: ProductsService,
    private localStorage: LocalstorageService,
    @Inject(PLATFORM_ID) private platFormId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platFormId);
    this.initShippingMethodData();
  }

  /**
   * Edit an user
   * @param user
   */
  async updateUserData(user: User) {
    // Mientras se resuelve la info de los punto de ventas de los distribuidores.
    for (const item in user) {
      if (user[item] === undefined) {
        delete user[item];
      }
      if (item == 'direcciones' && user[item][0] == undefined) {
        user[item] = []
      }
    }
    try {
      const userRef = firebase.firestore().doc(`usuarios/${user.uid}`);
      return userRef.set(user);
    } catch (error) {
      console.log(`Error updating user: ${error}`);
    }
  }

  /**Functions which get ip address from backend */
  getIpAddress() {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(this.baseURL + '' + 'api/get-ip', {
      headers: this.headers,
    });
  }

  /**Get the user id from user logged */
  getUserIdLogged() {
    return this.afAuth.authState.pipe(map((auth) => auth?.uid));
  }

  /** Gets the logged user */
  async getLoggedUser(): Promise<User> {
    let user: User;
    try {
      let userId = (await this.afAuth.authState.toPromise()).uid;
      let query = await firebase.firestore().collection('usuarios').where('uid', '==', userId).get();
      if (!query.empty) {
        user = (query.docs.pop() as unknown) as User;
      }
    } finally {
      return user;
    }
  }

  /**
   * Functions which return user information
   * @param idUser User id
   */
  getUserbyId(idUser: string) {
    try {
      return firebase.firestore().doc(`usuarios/${idUser}`);
    } catch (error) {
      console.log(`Error getting user by id: ${error}`);
    }
  }

  /**
   * Functions which return user information
   * @param idUser User id
   */
  getUserbyIdPromise(idUser: string) {
    return new Promise((success, fail) => {
      try {
        firebase
          .firestore()
          .collection('usuarios')
          .where('uid', '==', idUser)
          .get()
          .then((data: any) => {
            success(data.docs);
          })
          .catch((er) => {
            fail(er);
          });
      } catch (error) {
        console.log(`Error getting user by id: ${error}`);
        fail(error);
      }
    });
  }

  /**
   * Functions which return user information by phone
   * @param phone User celular
   */
  getUserbyPhone(phone: string) {
    try {
      return firebase.firestore().collection('usuarios').where('celular', '==', phone).get();
    } catch (error) {
      console.log(`Error getting user by phone: ${error}`);
    }
  }
  /**
   * Functions which return user information by phone
   * @param email User email
   */
  getUserbyEmail(email: string) {
    try {
      return firebase.firestore().collection('usuarios').where('email', '==', email).get();
    } catch (error) {
      console.log(`Error getting user by email: ${error}`);
    }
  }

  /**
   * Gets the sellers from firestore
   * @returns
   */
  getSellers() {
    try {
      return firebase.firestore().collection('vendedoresPuntosDeVenta').get()
    } catch (error) {
      console.error(`Error getting sellers: ${error}`);
    }
  }

  /**
   * Change the global shipping method
   * @param shippingMethod
   */
  onSendGlobalShippingMethod(shippingMethod: ShippingMethod) {
    this.globalShippingMethod = shippingMethod;
    this.localStorage.setItem('shippingMethod', JSON.stringify(shippingMethod));
    this.sendShippingMethodGlobalSubject.next(shippingMethod);
    this.productsService.subscribeAllProducts()
  }

  /**
   * Initializes the shipping method data.
   * Retrieves the shipping method from local storage and sends it to the server.
   * If the shipping method is not found in local storage, a default shipping method is sent.
   */
  initShippingMethodData(): void {
    if (this.isBrowser) {
      const localShippingMethod = this.localStorage.getItem('shippingMethod');
      const shippingMethod = localShippingMethod ? JSON.parse(localShippingMethod) : { type: ShippingMethodType.SHIPPING };
      this.onSendGlobalShippingMethod(shippingMethod);
    }
  }
}
