<div class="banner">
  <div class="HomeMainComponent">
    <div class="row main-row">
      <div class="logo-calypso">
        <img src="../../../assets/icons/header/calypso-logo-renew.svg" class="img" />
      </div>
      <h1 class="text-1">Política de Tratamiento de Datos</h1>
    </div>
  </div>
</div>
<div class="container px-0 px-md-1">
  <div class="doc-container modal-body mt-1 mt-md-5">
    <h5>
      <p class="title">POLÍTICA DE PROTECCIÓN DE DATOS DE CALYPSO DEL CARIBE S. A.</p>
    </h5>
    <hr />
    <app-politicas-de-datos-copy></app-politicas-de-datos-copy>
  </div>
</div>
