<div class="container-cart">
  <div></div>
  <!-- MY cart -->
  <div class="column-card">
    <div class="container-info">

      <!-- Header shopping cart -->
      <div class="header-sopping-cart">
        <div class="text-header-sopping-cart txt-stl-primary">
          Carrito de compras
        </div>
        <img class="close-icon" src="./assets/icons/shopping-cart/close.svg" alt="close-icon"
          (click)="onCloseShoppingCart()" />
      </div>

      <!-- Empty shopping cart -->
      <div class="container-img-empty" *ngIf="products.length === 0">
        <div class="empty-cart-info">
          <img class="img-empty" src="./assets/icons/shopping-cart/cesta.png" />
          <div class="text-title-products-empty txt-stl-primary">
            Tu carrito está vacío
          </div>
          <div class="text-products-empty txt-stl-primary txt-stl-second">
            Contamos con una variedad de productos
            para ti para que elijas los que más te gustan.
          </div>
        </div>
        <div class="shopping-cart-empty-btn-container">
          <button class="empty-cart-btn txt txt-stl-primary txt-stl-second" (click)="onCloseShoppingCart()">
            Comenzar a comprar
          </button>
        </div>
      </div>

      <!-- Variable weight -->
      <div class="row h-100 info-variable" *ngIf="isVariableWeight">
        <div class="col-8 my-auto p-2">
          El precio total de este pedido es un aproximado que se confirma luego
          de pesar los productos de tu compra
        </div>
        <div class="col-4 my-auto more">
          <span class="more-pointer" data-toggle="modal" data-target="#info-variable-modal" data-dismiss="modal">Saber
            más</span>
        </div>
      </div>

      <!-- distribuidor -->
      <div class="row h-100 info-variable" *ngIf="isDistribuidor">
        <div class="col-8 my-auto p-2">
          Este es un pedido de distribuidor
        </div>
      </div>

      <!-- SHOPPING CART with products -->
      <div class="div-all-container scroll-visible" *ngIf="products.length > 0">
        <div id="cart" class="container-all-products">
          <div *ngFor="let product of products; let i = index" class="container-products-cart">
            <div class="img-prod">
              <img class="product-image" [src]="product.imagen" alt="product image" />
              <div class="variable-weight-tag txt-stl-primary txt-stl-second" *ngIf="product.pesoVariable">
                Peso variable
              </div>
              <div class="pound-tag" *ngIf="product.pesoVariable">
                {{ (product.pesoGramosVariable / 1000) * 2 }} Lib. aprox.
              </div>
            </div>

            <div class="info-product">
              <div class="title-product txt-stl-primary txt-stl-second">
                {{ product.nombre }}
                <div class="trash-product">
                  <img class="trash-icon" src="./assets/icons/shopping-cart/delete.svg" alt="close-icon"
                    (click)="deleteProduct(i)" />
                </div>
              </div>
              <div class="weight-product txt-stl-primary txt-stl-second" *ngIf="product.pesoVariable">
                {{ product.pesoGramosVariable }}g - $ {{ (product.precio / product.pesoGramos).toFixed(2) | number
                }}/g
              </div>
              <div class="weight-product txt-stl-primary txt-stl-second" *ngIf="!product.pesoVariable">
                {{ product.pesoGramos }}g - $ {{ (product.precio / product.pesoGramos).toFixed(2) | number }}/g
              </div>
              <span class="price-product txt-stl-primary txt-stl-second" *ngIf="product.pesoVariable">
                {{ pricePerPound(product.precio, product.pesoGramosVariable, product.pesoGramos) | customCurrency }} Libra
              </span>
              <div class="price-product txt-stl-primary txt-stl-second" *ngIf="!product.pesoVariable">
                {{ product.precio * product.quantity | customCurrency }}
              </div>

              <div class="quantity-product">
                <div class="container-add-remove col-12" *ngIf="!isNoStock(product)">
                  <div class="remove" (click)="onLessProduct(i)">
                    <i class="fa fa-minus btn-icon"></i>
                  </div>
                  <div class="quantity txt-stl-primary txt-stl-second">{{ product.quantity }}</div>
                  <div class="add" (click)="onMoreProduct(i)" *ngIf="product.inventarioBodega[warehouse] > product.quantity">
                    <i class="fas fa-plus btn-icon"></i>
                  </div>
                  <div class="add" *ngIf="!(product.inventarioBodega[warehouse] > product.quantity)">
                    <i class="fas fa-plus btn-icon"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-noStock" *ngIf="isNoStock(product)">
              <span class="text-noStock">
                Sin stock en tienda
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="resume-total" *ngIf="products.length > 0">
        <div class="title-resume txt-stl-primary">
          Total:
        </div>
        <div class="money-resume txt-stl-primary">
          {{ priceProducts | customCurrency }}
        </div>
      </div>

      <div class="pay" *ngIf="products.length > 0">
        <button class="pay-btn txt-stl-primary txt-stl-second" [disabled]="isDisabled()"
          [ngStyle]="{'background': (isDisabled())? '#afafaf':'#0e4fa8 0% 0% no-repeat padding-box;'}"
          (click)="navigateCheckout()" *ngIf="coverage === 'SI'">
          Pagar pedido
        </button>
      </div>

      <div class="clean-cart" *ngIf="products.length > 0">
        <button class="clean-cart-btn txt-stl-primary txt-stl-second" (click)="onEmptyShoppingCart()">
          Vaciar carrito
        </button>
      </div>
    </div>


    <div class="container-results">
      <div class="total">
        Total: {{ priceProducts | customCurrency }}
      </div>
      <button class="order-inactive" *ngIf="coverage === 'NO'" disabled>
        Sin cobertura
      </button>
      <ng-container *ngIf="!validateCheckout">
        <button class="order" (click)="navigateCheckout()" *ngIf="coverage === 'SI'" [disabled]="isDisabled()">
          Continuar pedido
        </button>
      </ng-container>
      <ng-container *ngIf="validateCheckout">
        <button class="order" (click)="onCloseShoppingCart()" *ngIf="coverage === 'SI'" [disabled]="isDisabled()">
          Continuar pedido
        </button>
      </ng-container>
    </div>
  </div>
</div>

<app-modal-info-variable-weight></app-modal-info-variable-weight>