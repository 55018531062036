import {
  Component,
  OnInit,
  PLATFORM_ID,
  Inject,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { UserService } from 'src/app/core/services/user/user.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { User } from 'src/app/interfaces/user.interface';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ProductsService } from 'src/app/core/services/products/products.service';
import $ from 'jquery';
import { Address, ShippingMethod, ShippingMethodType } from 'src/app/interfaces/salePoints.interface';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart/shopping-cart.service';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
declare var $: $;
@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent implements OnInit {
  /**Saved address activated*/
  activatedShippingMethod: ShippingMethod;
  /**Search param */
  search = new FormControl('');
  /**The user is initialized and store*/
  public user: User = {
    email: '',
    celular: '',
    createdAt: NaN,
    nombre: '',
    uid: '',
    tipo: '',
    aceptoTerminos: false,
    direccionIp: '',
  };

  public productsNumber: number = 0;
  /** Save the status of the user according coverage  */
  public coverage: string;

  constructor(
    public router: Router,
    @Inject(PLATFORM_ID) private platFormId,
    public authService: AuthService,
    private userService: UserService,
    private productService: ProductsService,
    private shoppingCartService: ShoppingCartService,
    private localStorage: LocalstorageService
  ) {
    this.shoppingCartService.cart$.subscribe((products) => {
      this.productsNumber = products.length;
      // this.asd()
    });
  }

  ngOnInit(): void {
    this.activatedShippingMethod = this.userService.globalShippingMethod;
    if (isPlatformBrowser(this.platFormId)) {
      this.coverage = this.localStorage.getItem('cobertura');
      this.userService.sendGlobalShippingMethodObservable.subscribe((shippingMethod) => {
        this.getActivatedShippingMethod(shippingMethod);
        this.coverage = this.localStorage.getItem('cobertura');
      });
    }
    this.search.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value: string) => {
        this.productService.onSendSearchParam(value.toLowerCase());
      });
  }

  /**
   * Function which sends the link that enters by parameters
   * @param option
   */
  public navigate(option) {
    this.router.navigate([option]);
  }

  /**
   * Navigate to specific category
   * @param category products category
   */
  public navigateCategory(category) {    
    this.productService.onSendCategoryParam(category.nombre);
    this.router.navigate(['/categories', category.slug]);
  }

  /**
   * Subscribe from searchParamsObservable
   */
  subscribeSearchParam() {
    this.productService.sendSearchParamObservable.subscribe((searchParam) => {
      this.search.setValue(searchParam);
    });
  }

  /**
   * Navigate to home and activate the address
   */
  async navigateToAddres() {
    this.router.navigate(['/']);

    /**Wait to navigate to home main*/
    setTimeout(() => {
      if (
        this.user.direcciones !== undefined &&
        this.user.direcciones.length > 0
      ) {
        $('#openAddressListModal')?.click();
      } else {
        $('#openCovarageFormModal')?.click();
      }
    }, 1000);
  }

  /**
   * Get activated shippingMethod
   * @param user User
   */
  getActivatedShippingMethod(shippingMethod: ShippingMethod): void {
    this.activatedShippingMethod = shippingMethod;
    if (shippingMethod) {
      if (shippingMethod.address && !shippingMethod.address.cobertura) {
        $('#popover').popover('show');
      }
    }
  }

  /**
   * Open the address modal
   */
  async openAddressModal() {
    if (isPlatformBrowser(this.platFormId)) {
      this.user = await JSON.parse(this.localStorage.getItem('user'));
      if (this.user.tipoCuenta === 'B2B') {
        return;
      } else {
        $('#openDeliverySelector').click();
      }
    }
  }

  /**
   * Deploy of the shopping cart
   * @memberof HeaderComponent
   */
  public onDeployShoppingCart() {
    this.shoppingCartService.changeDeployShoppingCart({
      data: { success: true },
    });
  }
}
