<div class="banner">
  <div class="HomeMainComponent">
    <div class="row main-row">
      <div class="logo-calypso">
        <img src="../../../assets/icons/header/calypso-logo-renew.svg" class="img" />
      </div>
      <h1 class="text-1">Términos y Condiciones de la Plataforma</h1>
    </div>
  </div>
</div>
<div class="container px-0 px-md-1">
  <div class="doc-container modal-body mt-1 mt-md-5">
    <h5>
      <p class="title">TÉRMINOS Y CONDICIONES GENERALES.</p>
    </h5>
    <hr />
    <app-terminos-y-condiciones-copy></app-terminos-y-condiciones-copy>
  </div>
</div>
