import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politicas-de-datos-copy',
  templateUrl: './politicas-de-datos-copy.component.html',
  styleUrls: ['./politicas-de-datos-copy.component.scss']
})
export class PoliticasDeDatosCopyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
