import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SalePoint } from 'src/app/interfaces/salePoints.interface';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  API_KEY = 'AIzaSyAUBkbUPJmbU13cSUqt0zMQwrHFeTYbCPk';
  constructor(private http: HttpClient) {}

  /**
   * Indicate if the pointGPS is inside of any polygon
   * @param point array of with lng and lat to a point
   * @param vs  array of numbers with lng and lat to a polygon
   */
  insidePolygon(point: number[], vs: number[]) {
    var x = point[0],
      y = point[1];
    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0],
        yi = vs[i][1];
      var xj = vs[j][0],
        yj = vs[j][1];
      var intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  }

  /**
   * Get the geolocation according to address.
   * @param address string with the user address
   */
  getGeolocation(address: string) {
    address = address.split(' ').join('%20');
    let str =
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      address +
      '&key=' +
      this.API_KEY;
    return this.http.get(str);
  }

  /**
   * Get geolocation according to addres and return coverage
   * @param searchAddres string with the user address
   */
  getGeolocationGPS(searchAddres: string, salePoints: SalePoint[]) {
    return new Promise((resolve, reject) => {
      let pointGPS: {
        lat: number;
        lng: number;
      } = {
        lat: 0,
        lng: 0,
      };
      this.getGeolocation(searchAddres).subscribe((resultados: any) => {
        if (resultados.status == 'OK') {
          // console.log('resultados');
          // console.log(resultados);
          // console.log('searchAddres');
          // console.log(searchAddres);
          pointGPS.lat = resultados.results[0].geometry.location.lat;
          pointGPS.lng = resultados.results[0].geometry.location.lng;
          // It is consulted if there is coverage
          let addressFormat = resultados.results[0].formatted_address;
          let match = resultados.results[0].partial_match;
          let precision = resultados.results[0].geometry.location_type;
          let coverage = false;
          for (let salePoint of salePoints) {
            let vs = [];
            for (let i = 0; i < salePoint.zonaCobertura.length; i++) {
              vs.push([
                salePoint.zonaCobertura[i].lat,
                salePoint.zonaCobertura[i].lng,
              ]);
            }
            if (this.insidePolygon([pointGPS.lat, pointGPS.lng], vs) == true) {
              coverage = true;
              break;
            }
          }
          resolve({ pointGPS, coverage, addressFormat, match, precision });
        } else {
          reject(
            'No encontramos la ubicación de tu dirección, por favor verifica que sea correcta.'
          );
        }
      });
    });
  }

  /**
   * Validte if a point from the map have coverage
   * @param point object with lang and lat
   */
  validateLocationMap(point: any, salePoints: SalePoint[]) {
    return new Promise((resolve, reject) => {
      let pointMap = [point.lat, point.lng];
      let coverage = false;
      let salePointInfo: any = {};
      for (let salePoint of salePoints) {
        let vs = [];
        for (let i = 0; i < salePoint.zonaCobertura?.length; i++) {
          vs.push([
            salePoint.zonaCobertura[i].lat,
            salePoint.zonaCobertura[i].lng,
          ]);
        }
        if (this.insidePolygon(pointMap, vs) == true) {
          coverage = true;
          salePointInfo = salePoint;
          break;
        }
      }
      if (coverage == true) {
        resolve({ coverage, salePointInfo });
      } else {
        reject({ coverage });
      }
    });
  }
}
