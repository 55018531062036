import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class RandomQuestionsService {

  constructor() { }


  getUserQuestions() {
    const preguntas = firebase.firestore().collection('preguntasUsuario');
    return preguntas;
  }

  getUserAnswersByIdOnce(id: string) {
    const respuestas = firebase.firestore().collection('respuestasUsuario');
    const document = respuestas.doc(id).get();
    return document;
  }

  updateUserAnswersById(id, data): Promise<void> {
    const respuestas = firebase.firestore().collection('respuestasUsuario');
    return respuestas.doc(id).update(data);
  }

  createUserAnswersById(id, data): Promise<void> {
    const respuestas = firebase.firestore().collection('respuestasUsuario');
    return respuestas.doc(id).set(data);
  }
}
