import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';

import { RecetaComponent } from './components/receta/receta.component';
import { RecetasAllComponent } from './components/recetas-all/recetas-all.component'

const routes: Routes = [
  {
    path: '',
    component: RecetasAllComponent,
  },
  {
    path: ':id',
    component: RecetaComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecetasRoutingModule { }
