<div class="container-fluid">
  <div class="HomeMainComponent">
    <div class="row">
      <div class="col-4 logo-calypso">
        <img src="../../../assets/icons/header/calypso-logo-renew.svg" (click)="navigate('/')" />
      </div>
      <div class="search-row">
        <div class="icon-in">
          <i class="fas fa-search"></i>
        </div>
        <input (focus)="navigateCategory({ nombre: 'busqueda', slug: 'busqueda' })" type="text" class="form-control" placeholder="Busca un producto..." [formControl]="search" />
      </div>
      <div class="col-8 d-flex justify-content-end buttons-container">
        <button class="header-btn" (click)="onDeployShoppingCart()">
          <img src="../../../assets/icons/header/shopping_cart.svg" alt="shopping_cart icon">
          <div *ngIf="productsNumber > 0" class="number-products"> {{ productsNumber }}</div>
        </button>
        <div class="d-flex align-items-center icon-hm-style">
          <app-side-menu (deployShoppingCartEvent)="onDeployShoppingCart()" (openAddressModalEvent)="openAddressModal()"
            (navigateEvent)="navigate($event)" (logoutEvent)="logout()"></app-side-menu>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Buttons which open modals -->
<button [hidden]="true" id="openDeliverySelector" data-toggle="modal" data-target="#deliverSelectorModal"></button>
<!-- Address -->
<app-address-main> </app-address-main>
