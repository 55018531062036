import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

interface options {
  name: string;
  active: boolean;
  logo?: string;
}


@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent implements OnInit {

  // Filter list controller - Show / hide
  public filterListController: boolean = false;
  // Options list
  @Input() options: options[] = [];
  // title to show
  @Input() title: string = '';
  // Options filter modal controller
  public optionsFilterModalController: boolean = false;
  // Options filter output emitter
  @Output() optionsFilterEmitter = new EventEmitter<options[]>();

  constructor() { }

  ngOnInit(): void {
  }

  onChangeFilter(option: options, event: any) {
    option.active = event.target.checked;
  }

  /**
   * Hide or shows options modal
   */
  onActiveOptionsModal() {
    this.optionsFilterModalController = !this.optionsFilterModalController;
  }

  onSubmitFilter() {
    this.optionsFilterEmitter.emit(this.options);
    this.onActiveOptionsModal();
  }

  onCleanFilters() {
    this.options.map((item) => (item.active = false));
  }

}
