import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardProfile implements CanActivateChild {
  constructor(private router: Router, @Inject(PLATFORM_ID) private platFormId) {}
  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    if (isPlatformBrowser(this.platFormId)) {
      let userLogged = JSON.parse(localStorage?.getItem('userLogged'));
      if (!userLogged) {
        this.router.navigate(['login']);
        return false;
      } else {
        return true;
      }
    }
  }
}
