<a href="/recetas/{{rec.id}}" class="prueba">
  <div class="col-md-12 receta-contianer"
    [ngStyle]="{ 'background-size': 'cover', 'background-position': 'center', 'background-image': rec.fotoSecundaria  ? 'url(' + rec.fotoSecundaria[0]  + ')' : 'url(assets/icons/header/logo-calypso.png)' }">
  </div>
  <div class="overlay-text">
    <div class="recipe-title">{{rec.nombre}}</div>
    <div class="row custom-row">
      <div class="col-6 card-info style-info custom-col">
        <img class="icon-img" [src]="'../../../../assets/icons/time.svg'" [alt]="'reloj'">
        <p class="recipe-subtitle time">Tiempo de preparación</p>
        <p class="recipe-subtitle">{{rec.tiempoPreparacion}} </p>
      </div>
      <div class="col-6 card-info style-info custom-col">
        <img class="icon-img" [src]="'../../../../assets/icons/signal.png'" [alt]="'dificultad'">
        <p class="recipe-subtitle level">Dificultad</p>
        <p class="recipe-subtitle">{{rec.nivel}}</p>
      </div>
    </div>
  </div>
</a>
