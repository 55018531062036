import {
  Component,
  Input,
  OnInit,
  Inject,
  PLATFORM_ID,
  OnChanges,
  DoCheck,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Address, SalePoint, ShippingMethodType } from 'src/app/interfaces/salePoints.interface';
import * as $ from 'jquery';
import { UserService } from 'src/app/core/services/user/user.service';
import { User } from 'src/app/interfaces/user.interface';
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-address-add',
  templateUrl: './address-add.component.html',
  styleUrls: ['./address-add.component.scss'],
})
export class AddressAddComponent implements OnInit {
  @Input() pointGPS: any;
  @Input() salePoint: SalePoint;
  @Input() coverage: boolean;
  @Input() address: Address;
  @Input() user: User;

  /**To block whit request */
  clicRequest: boolean = false;
  /**Show the address indicates in the html */
  indicationsView: string = '';
  /**Address add form */
  addAddressForm = new FormGroup({
    /**Address indications */
    indications: new FormControl(''),
    /**Address label */
    label: new FormControl('', [Validators.required]),
  });
  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    public router: Router,
    @Inject(PLATFORM_ID) private platFormId
  ) { }

  ngOnInit() {
    this.addAddressForm.get('indications').valueChanges.subscribe((value) => {
      this.changeIndications(value);
    });
  }

  /**
   * Saved the indications
   * @param value string with contained the indications address
   */
  changeIndications(value: string) {
    this.indicationsView = value;
  }

  /**
   * Saved the new addres at the localStorage o firebase according to if there is user or not.
   * @param addAddressForm object with information of indications and label address
   */
  addAddress(addAddressForm: object) {
    this.clicRequest = true;
    if (this.user) {
      if (this.salePoint !== undefined) {
        this.addNewAddressToUser(addAddressForm);
      } else {
        this.addNewAddressToUserNoCovarege(addAddressForm);
      }
    } else {
      this.addNewAddressToStorage(addAddressForm);
    }
  }

  /**
   * Saved new address at firebase
   * @param addAddressForm object with information of indications and label address
   */
  addNewAddressToUser(addAddressForm) {
    this.user.direccion =
      this.address.nomenclatura.replace(/ /g, '') +
      ' ' +
      this.address.numeroUno.replace(/ /g, '') +
      '#' +
      ' ' +
      this.address.numeroDos.replace(/ /g, '') +
      '-' +
      this.address.numeroTres.replace(/ /g, '');

    let address = {
      ciudad: this.address.ciudad,
      cobertura: this.coverage,
      etiqueta: addAddressForm.label,
      indicaciones: addAddressForm.indications,
      inventario: this.salePoint.bodega || '',
      lat: this.pointGPS.lat,
      lng: this.pointGPS.lng,
      municipioAledano: '',
      nomenclatura: this.address.nomenclatura,
      numeroUno: this.address.numeroUno,
      numeroDos: this.address.numeroDos,
      numeroTres: this.address.numeroTres,
      principal: true,
      salePoint: this.salePoint,
    };

    this.user.puntoDeVentaBarrio = this.salePoint.barrio;
    this.user.puntoDeVentaBodega = this.salePoint.bodega || '';
    this.user.puntoDeVentaCiudad = this.salePoint.nombreMunicipio;
    this.user.puntoDeVentaCodMun = this.salePoint.codigoMunicipio;
    this.user.puntoDeVentaDepto = this.salePoint.codigoDepartamento;
    this.user.puntoDeVentaId = this.salePoint.id;
    this.user.puntoDeVentaNombre = this.salePoint.nombre;
    this.user.puntoDeVentaTpv = this.salePoint.tpv;
    this.user.puntoDeVentaWhatsapp = this.salePoint.whatsapp;
    // Validations of user.direcciones
    if (
      this.user.direcciones === undefined ||
      this.user.direcciones.length === 0  ||
      (this.user.direcciones.length === 1 && this.user.direcciones[0] === undefined)
    ) {
      this.user.direcciones = [address];
    } else {

      this.user.direcciones.forEach((direccion) => {
        direccion.principal = false;
      });
      this.user.direcciones.push(address);
    }

    // Update user
    this.userService
      .updateUserData(this.user)
      .then(() => {
        this.clicRequest = false;
        this.coverage ? localStorage.setItem('cobertura', 'SI') : localStorage.setItem('cobertura', 'NO');
        this.userService.onSendGlobalShippingMethod({
          type: ShippingMethodType.SHIPPING,
          address: address
        });

        this.toastr.success('Tu dirección ha sido agregada con éxito.');
        this.router.navigate(['/']);
        $('#addAddressModal')?.click();
      })
      .catch((error) => {
        this.clicRequest = false;
        this.toastr.error('No hemos podido agregar tu dirección.');
        console.log(`Error adding address: ${error}`);
      });
  }

  /**
   * Saved new address at localStorage
   * @param addAddressForm object with information of indications and label address
   */
  addNewAddressToStorage(addAddressForm) {
    let address = {
      ciudad: this.address.ciudad,
      cobertura: this.coverage,
      etiqueta: addAddressForm.label,
      indicaciones: addAddressForm.indications,
      inventario: this.salePoint !== undefined ? this.salePoint.bodega : '',
      salePoint: this.salePoint,
      lat: this.pointGPS.lat,
      lng: this.pointGPS.lng,
      municipioAledano: '',
      nomenclatura: this.address.nomenclatura,
      numeroUno: this.address.numeroUno,
      numeroDos: this.address.numeroDos,
      numeroTres: this.address.numeroTres,
      principal: true,
      distribuidor: (this.salePoint && this.salePoint.distribuidor !== undefined) ? this.salePoint.distribuidor : '',
    };

    if (isPlatformBrowser(this.platFormId)) {
      let shippingMethod = {
        type: ShippingMethodType.SHIPPING,
        address: address
      };
      localStorage.setItem('shippingMethod', JSON.stringify(shippingMethod));
      this.coverage ? localStorage.setItem('cobertura', 'SI') : localStorage.setItem('cobertura', 'NO');
      this.userService.onSendGlobalShippingMethod(shippingMethod);
      this.router.navigate(['/']);
    }
    this.clicRequest = false;
    this.toastr.success('Tu dirección ha sido agregada con éxito.');
    $('#addAddressModal')?.click();
  }

  /**
   * Saved new address at firebase when there isn't covarege
   * @param addAddressForm object with information of indications and label address
   */
  addNewAddressToUserNoCovarege(addAddressForm) {
    this.user.direccion =
      this.address.nomenclatura.replace(/ /g, '') +
      ' ' +
      this.address.numeroUno.replace(/ /g, '') +
      '#' +
      ' ' +
      this.address.numeroDos.replace(/ /g, '') +
      '-' +
      this.address.numeroTres.replace(/ /g, '');

    let address = {
      ciudad: this.address.ciudad,
      cobertura: this.coverage,
      etiqueta: addAddressForm.label,
      indicaciones: addAddressForm.indications,
      inventario: '',
      lat: this.pointGPS.lat,
      lng: this.pointGPS.lng,
      municipioAledano: '',
      nomenclatura: this.address.nomenclatura,
      numeroUno: this.address.numeroUno,
      numeroDos: this.address.numeroDos,
      numeroTres: this.address.numeroTres,
      principal: true,
    };

    // Validations of user.direcciones
    if (
      this.user.direcciones == undefined ||
      this.user.direcciones.length == 0 ||
      this.user.direcciones [0] == undefined
    ) {
      this.user.direcciones = [address];
    } else {
      this.user.direcciones.forEach((direccion) => {
        direccion.principal = false;
      });
      this.user.direcciones.push(address);
    }

    this.user.tipoEntrega = "Domicilio";

    // Update user
    this.userService
      .updateUserData(this.user)
      .then(() => {
        this.clicRequest = false;
        this.coverage ? localStorage.setItem('cobertura', 'SI') : localStorage.setItem('cobertura', 'NO');
        this.userService.onSendGlobalShippingMethod({
          type: ShippingMethodType.SHIPPING,
          address: address
        });

        this.router.navigate(['/']);
        this.toastr.success('Tu dirección ha sido agregada con éxito.');
        $('#addAddressModal')?.click();
      })
      .catch((error) => {
        this.clicRequest = false;
        this.toastr.error('No hemos podido agregar tu dirección.');
        console.log(`Error adding address: ${error}`);
      });
  }
}
