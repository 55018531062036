import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Console, log } from 'console';
import { RandomQuestionsService } from 'src/app/core/services/radom-questions/random-questions.service';
import { UserService } from 'src/app/core/services/user/user.service';
import * as moment from 'moment';
declare var $: any;
import { Router } from '@angular/router';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-random-questions',
  templateUrl: './random-questions.component.html',
  styleUrls: ['./random-questions.component.scss'],
})
export class RandomQuestionsComponent implements OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input('questionId') questionId = 0;

  /**
   * Se almacena el ID del usuarios.
   */
  private usuarioId;

  /**
   * Se almacennan las preguntas.
   */
  public preguntas = [];

  /**almacena el momento en el que se muestra el popup */
  private timePopUp: number;

  constructor(
    private router: Router,
    private randomQuestionsService: RandomQuestionsService,
    private userService: UserService,
    private localStorage: LocalstorageService,
    @Inject(PLATFORM_ID) private platFormId
  ) {
    this.questionId = 0;
  }

  ngOnInit(): void {
    this.getUnansweredQuestion();
  }

  /**
   * Muestra el modal de la preguntas.
   */
  showModal( modaltoShow): void {
    $(modaltoShow).modal('show');
  }

  /**
   * Cierra el modal de la preguntas.
   */
  closeModal(modaltoClose): void {
    $(modaltoClose).modal('hide');
  }

  /**
   * Obtine las preguntas que el usuario no ha respeondido.
   */
  async getUnansweredQuestion(): Promise<void> {
    if (isPlatformBrowser(this.platFormId)) {
      const userLogged = JSON.parse(this.localStorage.getItem('userLogged'));
      if (userLogged) {
        // get sesion
        this.userService.getUserIdLogged().subscribe(async (userId) => {
          if (userId) {
            this.usuarioId = userId;
            const document = await this.randomQuestionsService.getUserAnswersByIdOnce(userId);
            const self = this;
            this.randomQuestionsService.getUserQuestions().onSnapshot(async (querySnapshot) => {
              querySnapshot.forEach((doc) => {
                self.preguntas.push(doc.data());
              });
              this.preguntas.sort((a, b) => (b.waitDays ?? 0) - (a.waitDays ?? 0));
              // alamacena la siguente pregunta que el user no ha respondido.
              let nextQuestion = {};
              //Si el usuario al menos una pregunta
              if (document.exists) {
                const userAnswers = document.data();
                const epochAnswered = userAnswers.lastAnswerTime;
                const lastAnswered = epochAnswered == null ? null : moment(epochAnswered).valueOf();
                let i = 0;
                for (const question of this.preguntas) {
                  const isAnswered = userAnswers[question.keyword] !== undefined ? true : false;
                  // console.log(isAnswered, question);
                  const fecha = new Date(lastAnswered);
                  // preguntar cada cuanto.
                  const readyToAsk =
                    lastAnswered == null ||
                    moment(lastAnswered)
                      .add(question.waitDays ?? 0, 'd')
                      .isBefore(moment.now());
                  if (!isAnswered && readyToAsk) {
                    self.questionId = i;
                    nextQuestion = question;
                    setTimeout(() => self.showModal('#questionsModal'), 3000);
                    break;
                  }
                  i = i + 1;
                }
                /**Do not delete because Calypso might require another popup in the future */
                if(i==5){
                  // this.displayPopup()
                }
              } else {
                await this.randomQuestionsService.createUserAnswersById(userId, {});
                self.questionId = 0;
                nextQuestion = this.preguntas[0];
              }
            });
          }
        });
      }
      /**Do not delete because Calypso might require another popup in the future */
      else{
        // this.displayPopup()
      }
    }
  }

  /**
   * Captura la respuesta es ususario.
   * @param question pregunta a realizar.
   * @param respuesta del usuario.
   */
  async answerQuestion(question, answer): Promise<void> {
    if (this.usuarioId !== null) {
      const document = await this.randomQuestionsService.getUserAnswersByIdOnce(this.usuarioId);
      const dT = new Date(0);
      const lastAnswerTime = dT.setMilliseconds(Date.now());
      if (document.exists) {
        const userAnswer = document.data();
        userAnswer.lastAnswerTime = lastAnswerTime;
        userAnswer[question.keyword] = answer;
        await this.randomQuestionsService.updateUserAnswersById(this.usuarioId, userAnswer);
      } else {
        const userAnswer: any = {};
        userAnswer.lastAnswerTime = lastAnswerTime;
        userAnswer[question.keyword] = answer;
        await this.randomQuestionsService.createUserAnswersById(this.usuarioId, userAnswer);
      }
    }
    this.closeModal('#questionsModal');
  }

  /**
   * Function which sends the link that enters by parameters
   * @param option link to navigate
   */
   public navigate(option: string) {
    this.closeModal('#raspaganaModal');
    this.router.navigate([option]);
  }

  /**
   * Close the modal to inform about coverage modal and open the modal to change sale point
   * @param modal
   */
  public openModal(modal){
    this.closeModal('#coverageChangeModal')
    if(modal=='store') $('#openSalePointSelector')?.click();
    else if(modal=='delivery') $('#openCovarageFormModal')?.click();
  }

  /**
   * Funtion to determinate if display the popup or not according to the time, every 2h the popup is displayed.
   */
  private displayPopup(){
    this.timePopUp = moment.now()
    let lastDisplayPopup = parseInt(this.localStorage.getItem('raspaGana'))
    let readyToDispalyPopup
    if(lastDisplayPopup){
      readyToDispalyPopup = moment(lastDisplayPopup).add(2, 'h').isBefore(this.timePopUp);
      if(readyToDispalyPopup){
        this.localStorage.setItem('raspaGana', this.timePopUp.toString())
        this.showModal('#raspaganaModal')
      }
    }else{
      this.localStorage.setItem('raspaGana', this.timePopUp.toString())
      this.showModal('#raspaganaModal')
    }
  }
}
