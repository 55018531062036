    <div class="modal-body">
      <p><strong>1. DISPOSICIONES GENERALES&nbsp;</strong></p>
      <p class="p-custom"><strong>1.1. MARCO NORMATIVO&nbsp;</strong></p>
      <p class="p-custom"><span class="span-custom">La presente Pol&iacute;tica se realiza teniendo en cuenta las disposiciones contempladas&nbsp; en el art&iacute;culo 15 de la Constituci&oacute;n Pol&iacute;tica de Colombia, seg&uacute;n el derecho de&nbsp; protecci&oacute;n de datos personales como </span><em><span class="span-custom"> el derecho de toda persona para conocer,&nbsp; actualizar, rectificar y/o cancelar la informaci&oacute;n y datos personales que de ella se&nbsp; hayan recolectado y/o se traten en bases de datos p&uacute;blicas o privadas</span></em><span class="span-custom">; La Ley 1581&nbsp; del 17 de octubre del 2012, en la cual, el Congreso de la Rep&uacute;blica reglament&oacute; el&nbsp; ya mencionado derecho al establecer las Disposiciones Generales para la&nbsp; Protecci&oacute;n de Datos Personales en Colombia, igualmente reglamentada por los&nbsp; Decretos 1377 de 2013 y 886 de 2014 (hoy incorporados en el Decreto &uacute;nico 1074&nbsp; de 2015); El Decreto Reglamentario 1074 de 2015, CE No. 2 de 2015, el cual&nbsp; reglamenta la informaci&oacute;n m&iacute;nima de que debe contener el RNBD y los t&eacute;rminos y&nbsp; condiciones sobre los cuales se deben inscribir las bases de datos sujetas a la&nbsp; aplicaci&oacute;n de la Ley 1581 de 2012, y dem&aacute;s disposiciones complementarias.</span></p>
      <p class="p-custom"><strong>1.2. &Aacute;MBITO DE APLICACI&Oacute;N&nbsp;</strong></p>
      <p class="p-custom"><span class="span-custom">Estas pol&iacute;ticas se aplican en el tratamiento de los datos de car&aacute;cter personal que&nbsp; pueda recoger o tratar la sociedad CALYPSO DEL CARIB&Eacute; S.A. Quien para efectos&nbsp; del presente documento se denominar&aacute; CALYPSO DEL CARIBE o &ldquo;LA COMPA&Ntilde;&Iacute;A&rdquo; en raz&oacute;n de la ejecuci&oacute;n de contratos comerciales y en el desarrollo de su objeto&nbsp; social.</span></p>
      <p class="p-custom-b"><strong>1.3. OBJETO&nbsp;</strong></p>
      <p class="p-custom-b"><span class="span-custom">Por medio de la presente pol&iacute;tica se da cumplimiento a lo previsto en la Ley 1581 de&nbsp; 2012, al regular los deberes que asisten a los responsables del tratamiento de datos&nbsp; personales, y el derecho que tienen todas las personas de conocer, actualizar y&nbsp; rectificar las informaciones que se hayan recolectado sobre ellas en bases de datos&nbsp; o archivos.&nbsp;</span></p>
      <p class="p-custom-b"><span class="span-custom">Dentro de los deberes se encuentra el de adoptar las pol&iacute;ticas y procedimientos para&nbsp;</span><span class="span-custom">garantizar el adecuado cumplimiento de la ley y en especial, para la atenci&oacute;n de&nbsp; consultas y reclamos.&nbsp;</span></p>
      <p class="p-custom-b"><span class="span-custom">As&iacute; mismo, tiene la finalidad de regular los procedimientos de recolecci&oacute;n, manejo y&nbsp; tratamiento de los datos de car&aacute;cter personal que realiza CALYPSO DEL CARIBE,&nbsp; a fin de garantizar y proteger el derecho fundamental de habeas data en el marco de&nbsp; lo establecido en la misma ley.</span></p>
      <p class="p-custom"><strong>1.4. DEFINICIONES&nbsp;&nbsp;</strong></p>
      <ul>
      <li class="li-custom">
      <ul class="ul-custom">
      <li><strong>Autorizaci&oacute;n</strong><span class="span-custom">: Consentimiento previo, expreso e informado del titular para&nbsp; llevar a cabo el tratamiento de datos personales.&nbsp;</span></li>
      <li><strong>Aviso de privacidad</strong><span class="span-custom">: Documento f&iacute;sico, electr&oacute;nico o en cualquier otro&nbsp; formato generado por el responsable que se pone a disposici&oacute;n del titular para&nbsp; el tratamiento de sus datos personales. En el Aviso de Privacidad se comunica&nbsp; al Titular la informaci&oacute;n relativa a la existencia de las pol&iacute;ticas de tratamiento&nbsp; de informaci&oacute;n que le ser&aacute;n aplicables, la forma de acceder a las mismas y la&nbsp; finalidad del tratamiento que se pretende dar a los datos personales.&nbsp;</span></li>
      <li><strong>Base de datos</strong><span class="span-custom">: Conjunto organizado de datos personales que sea objeto de&nbsp; tratamiento.&nbsp;</span></li>
      <li><strong>Dato personal</strong><span class="span-custom">: cualquier informaci&oacute;n vinculada o que pueda asociarse a una&nbsp; o varias personas naturales o jur&iacute;dicas determinadas o determinables.&nbsp;</span></li>
      <li><strong>Dato privado</strong><span class="span-custom">: Es el dato que por su naturaleza &iacute;ntima o reservada s&oacute;lo es&nbsp; relevante para el titular.&nbsp;</span></li>
      <li><strong>Dato p&uacute;blico</strong><span class="span-custom">: Es el dato calificado como tal seg&uacute;n los mandatos de la ley o&nbsp; de la Constituci&oacute;n Pol&iacute;tica y aquel que no sea semiprivado, privado o sensible.&nbsp; Son p&uacute;blicos, entre otros, los datos relativos al estado civil de las personas, a&nbsp; su profesi&oacute;n u oficio, a su calidad de comerciante o servidor p&uacute;blico y aquellos&nbsp; que puedan obtenerse sin reserva alguna. Por su naturaleza, los datos&nbsp; p&uacute;blicos pueden ser contenidos, entre otros, en registros p&uacute;blicos,&nbsp; documentos p&uacute;blicos, gacetas y boletines oficiales.</span></li>
      <li><strong>Datos sensibles</strong><span class="span-custom">: Aquellos que afectan la intimidad del titular o cuyo uso&nbsp; indebido puede generar su discriminaci&oacute;n. Ejemplo: origen racial, &eacute;tnico,&nbsp; orientaci&oacute;n pol&iacute;tica, convicciones religiosas o filos&oacute;ficas, pertenencia a&nbsp; sindicatos, organizaciones sociales, de derechos humanos o que promueva </span>el inter&eacute;s de cualquier partido pol&iacute;tico, datos relativos a la salud, vida sexual.&nbsp; La ley proh&iacute;be el tratamiento de datos sensibles excepto cuando el titular haya&nbsp; dado su autorizaci&oacute;n de manera expl&iacute;cita, o cuando su tratamiento sea&nbsp; necesario para el inter&eacute;s vital del titular, o cuando sean necesarios para la&nbsp; defensa en un proceso judicial o tenga una finalidad hist&oacute;rica, estad&iacute;stica o&nbsp; cient&iacute;fica.</li>
      <li><strong>Derechos de los ni&ntilde;os, ni&ntilde;as y adolescentes</strong><span class="span-custom">: Solo puede darse&nbsp; tratamiento a los datos p&uacute;blicos de ni&ntilde;os, ni&ntilde;as y adolescentes.</span></li>
      <li><strong>Encargado del tratamiento</strong><span class="span-custom">: persona natural o jur&iacute;dica que realice el&nbsp; tratamiento de datos&nbsp; personales por cuenta del responsable del tratamiento.&nbsp;</span></li>
      <li><strong>Titular</strong><span class="span-custom">: persona natural o jur&iacute;dica cuyos datos personales sean objeto de&nbsp; tratamiento.&nbsp;</span></li>
      <li><strong>Tratamiento</strong><span class="span-custom">: Cualquier operaci&oacute;n o conjunto de operaciones sobre datos&nbsp; personales, tales como la recolecci&oacute;n, almacenamiento, uso, circulaci&oacute;n o&nbsp; supresi&oacute;n.</span></li>
      <li><strong>Responsable del Tratamiento</strong><span class="span-custom">: &ldquo;Persona natural o jur&iacute;dica, p&uacute;blica o privada,&nbsp; que por s&iacute; misma o en asocio con otros, decida sobre la base de datos o el&nbsp; Tratamiento de los Datos&rdquo; CALYPSO DEL CARIBE, de acuerdo con la ley es&nbsp; Responsable del Tratamiento de datos personales contenidos en sus bases&nbsp; de datos.</span></li>
      <li><strong class="str-custom">Encargado del Tratamiento</strong><span class="span-custom">: &ldquo;Persona natural o jur&iacute;dica, p&uacute;blica o privada,&nbsp; que por s&iacute; misma o en asocio con otros, realice el Tratamiento de datos&nbsp; personales por cuenta del responsable del Tratamiento&rdquo;. CALYPSO DEL&nbsp; CARIBE podr&aacute; realizar el tratamiento de sus datos personales a trav&eacute;s de&nbsp; Encargados.</span></li>
      </ul>
      </li>
      </ul>
      <p class="p-custom"><strong>1.5. PRINCIPIOS&nbsp;</strong></p>
      <p class="p-custom"><span class="span-custom">Los principios que se establecen a continuaci&oacute;n constituyen los par&aacute;metros que&nbsp; regir&aacute;n los procesos de recolecci&oacute;n, uso y tratamiento de datos por parte de&nbsp; CALYPSO DEL CARIBE.&nbsp;</span></p>
      <ul>
      <li class="li-custom">
      <ul class="ul-custom">
      <li><strong>Libertad</strong><span class="span-custom">: El uso de los datos personales s&oacute;lo puede llevarse a cabo con el&nbsp; consentimiento, previo, expreso e informado del titular. Los datos personales&nbsp; no podr&aacute;n ser obtenidos o divulgados sin previa autorizaci&oacute;n, o en ausencia de&nbsp; un mandato legal o judicial que remplace el consentimiento.</span></li>
      <li><strong>Veracidad o calidad</strong>: La informaci&oacute;n debe ser veraz, completa, exacta,&nbsp; actualizada, comprobable y comprensible. Se proh&iacute;be el uso de datos parciales,&nbsp; incompletos, fraccionados o que induzcan a error.&nbsp;</li>
      <li><strong>Transparencia</strong><span class="span-custom">: Debe garantizarse al titular el derecho a obtener de CALYPSO&nbsp; DEL CARIBE en cualquier momento y sin restricciones, informaci&oacute;n acerca de&nbsp; la existencia de los datos personales que le conciernen.&nbsp;</span></li>
      <li><strong>Acceso restringido</strong><span class="span-custom">: Los datos personales, salvo la informaci&oacute;n p&uacute;blica, no&nbsp; podr&aacute;n estar disponibles en alg&uacute;n medio de divulgaci&oacute;n o comunicaci&oacute;n masiva, salvo que el acceso sea t&eacute;cnicamente controlable para brindar un&nbsp; conocimiento restringido a los titulares.&nbsp;</span></li>
      <li><strong>Seguridad</strong><span class="span-custom">: La informaci&oacute;n se deber&aacute; proteger mediante el uso de medidas&nbsp; t&eacute;cnicas, humanas o administrativas que sean necesarias para evitar la&nbsp; adulteraci&oacute;n, p&eacute;rdida, consulta, o acceso no autorizado de los datos manejados&nbsp; por CALYPSO DEL CARIBE.</span></li>
      <li><strong>Confidencialidad</strong>: Toda persona que interviene en el tratamiento de los datos&nbsp; personales est&aacute;n obligadas a mantener la reserva de la informaci&oacute;n.</li>
      </ul>
      </li>
      </ul>
        <p><strong>2. </strong><strong>TRATAMIENTO Y FINALIDAD AL CUAL SER&Aacute;N SOMETIDOS LOS&nbsp; DATOS PERSONALES&nbsp;</strong></p>
        <p class="p-custom"><strong>2.1. TRATAMIENTO DE LOS DATOS POR PARTE DE LA </strong><strong>COMPA&Ntilde;&Iacute;A</strong></p>
        <p class="p-custom"><strong><span class="span-custom">CALYPSO DEL CARIBE </span><span class="span-custom">podr&aacute; directamente o a trav&eacute;s de sus empleados,&nbsp; consultores y/o terceros encargados, recolectar, almacenar, administrar, procesar,&nbsp; suprimir, circular, transferir, transmitir, usar, conservar, ordenar, modificar,&nbsp; relacionar, evaluar, bloquear, destruir y, en general, procesar (el &ldquo;Tratamiento&rdquo;) los&nbsp; datos personales suministrados por parte de las personas naturales al momento de&nbsp; requerir nuestros productos o servicios, al momento de vincularse como cliente,&nbsp; proveedor, distribuidor, contratista, socio o al momento de vincularse como&nbsp; trabajador directo o indirecto de la Compa&ntilde;&iacute;a. As&iacute; mismo, la Compa&ntilde;&iacute;a podr&aacute;&nbsp; transferir o transmitir sus datos personales con las personas naturales o jur&iacute;dicas&nbsp; con los cuales CALYPSO DEL CARIBE celebre contratos, convenios y/o alianzas&nbsp; dentro del giro ordinario de sus negocios. La Compa&ntilde;&iacute;a &uacute;nicamente recolectar&aacute; los datos personales del Titular que sean pertinentes y adecuados, para la finalidad&nbsp; para la cual son requeridos. No se recoger&aacute;n datos personales sin la autorizaci&oacute;n&nbsp; previa, expresa e informada del Titular, a excepci&oacute;n de los casos previstos en la ley,&nbsp; en los que no es necesaria la obtenci&oacute;n de dicha autorizaci&oacute;n.</span></strong></p>
        <p class="p-custom"><strong>2.2. AUTORIZACI&Oacute;N&nbsp;</strong></p>
        <p class="p-custom"><span class="span-custom">Para la recolecci&oacute;n, almacenamiento, uso, circulaci&oacute;n de datos personales&nbsp; que se requieran por parte de CALYPSO DEL CARIBE se debe contar con&nbsp; el previo, libre, expreso e informado consentimiento del titular de los datos,&nbsp; siempre y cuando la calidad del dato personal lo amerite. CALYPSO DEL&nbsp; CARIBE como responsable del tratamiento de los datos personales&nbsp; dispondr&aacute; de los mecanismos necesarios para obtener la autorizaci&oacute;n de&nbsp; los titulares garantizando que sea de posible verificaci&oacute;n.&nbsp;</span></p>
        <p class="p-custom"><span class="span-custom">Todo dato personal obtenido sin que medie autorizaci&oacute;n previa deber&aacute;&nbsp; cumplir en todo caso con las disposiciones sobre tratamiento y acceso&nbsp; establecidas por la ley &ndash;art&iacute;culo 10 inciso segundo de la ley 1581 2012.-&nbsp;</span></p>
        <p class="p-custom"><span class="span-custom">Al momento de la solicitud de autorizaci&oacute;n de tratamiento de Datos&nbsp; Personales del Titular, LA COMPA&Ntilde;&Iacute;A proceder&aacute; a informarle de manera&nbsp; clara y expresa:&nbsp;</span></p>
        <ul>
        <li class="li-custom">
        <ul class="ul-custom">
        <li><span class="span-custom">El tratamiento y finalidad dada a sus datos personales&nbsp;</span></li>
        <li><span class="span-custom">Al tratarse de Datos Sensibles o de menores, se informar&aacute;&nbsp; claramente de esta situaci&oacute;n al titular y se le requerir&aacute; para que </span>manifieste su aceptaci&oacute;n o rechazo a la recolecci&oacute;n y tratamiento de estos.&nbsp;</li>
        <li><span class="span-custom">Los Derechos y Deberes que le asisten como Titular de los&nbsp; Datos Personales. </span></li>
        <li>La identificaci&oacute;n y forma de contacto del titular y encargado del&nbsp; tratamiento de los Datos Personales sujetos a su autorizaci&oacute;n.</li>
        </ul>
        </li>
        </ul>
        <p class="p-custom"><strong>2.3. MECANISMOS DE RECOLECCI&Oacute;N DE AUTORIZACI&Oacute;N&nbsp;</strong></p>
        <p class="p-custom"><span class="span-custom">La autorizaci&oacute;n puede ser otorgada por medio de un documento f&iacute;sico,&nbsp; electr&oacute;nico, o en cualquier otro formato que garantice su posterior consulta.&nbsp; La autorizaci&oacute;n ser&aacute; emitida por CALYPSO DEL CARIBE y ser&aacute; puesta a&nbsp; disposici&oacute;n del titular previo al tratamiento de sus datos personales, tal como&nbsp; lo establece la ley 1581 de 2012.&nbsp;</span></p>
        <p class="p-custom"><span class="span-custom">Con dicha autorizaci&oacute;n el titular de los datos personales es informado de:&nbsp; quien recopila los datos, que datos recopilan, para que se recopilan los datos,&nbsp; como puede ejercer los derechos de acceso, correcci&oacute;n, actualizaci&oacute;n o&nbsp; eliminaci&oacute;n de los datos personales suministrados.&nbsp;</span></p>
        <p class="p-custom"><span class="span-custom">Para la recolecci&oacute;n de datos se usar&aacute;n mecanismos tales como p&aacute;gina web,&nbsp; aplicaciones tecnol&oacute;gicas de la Compa&ntilde;&iacute;a, redes sociales, WhatsApp y&nbsp; dem&aacute;s medios de interacci&oacute;n que permitan sostener comunicaciones. Dichos&nbsp; procedimientos se realizar&aacute;n cumpliendo con todo lo previsto en esta Pol&iacute;tica&nbsp;</span><span class="span-custom">y en la ley vigente y aplicable.</span></p>
        <p class="p-custom"><strong>2.4. FINALIDAD DEL TRATAMIENTO DE LOS DATOS&nbsp;</strong><strong>PERSONALES&nbsp;</strong></p>
        <p class="p-custom"><span class="span-custom">El tratamiento de datos personales realizado por CALYPSO DEL CARIBE,&nbsp; tendr&aacute; una finalidad legal y se destinar&aacute; a las actividades que el titular&nbsp; exclusivamente autoriza. A continuaci&oacute;n, se se&ntilde;alan las principales&nbsp; finalidades del tratamiento de datos personales por parte de la Compa&ntilde;&iacute;a (sin&nbsp; limitarse):&nbsp;</span></p>
        <ul>
        <li class="li-custom">
        <ul class="ul-custom">
        <li><span class="span-custom">Ejecutar o dar cumplimiento a las obligaciones derivadas de la relaci&oacute;n&nbsp; contractual o comercial, realizar actividades comerciales y&nbsp; transaccionales con clientes, proveedores, contratistas, socios,&nbsp; trabajadores directos e indirectos y dem&aacute;s terceros con los que la&nbsp; Compa&ntilde;&iacute;a establece alg&uacute;n tipo de relaci&oacute;n en el giro ordinario de sus&nbsp; negocios.</span></li>
        <li>Proveer los servicios o productos requeridos por parte de los clientes&nbsp; de la Compa&ntilde;&iacute;a.&nbsp;</li>
        <li><span class="span-custom">Dar cumplimiento a las pol&iacute;ticas y procedimientos internos de&nbsp; conocimiento e identificaci&oacute;n de terceros.&nbsp;</span></li>
        <li><span class="span-custom">En los procesos de cotizaciones, propuestas comerciales, facturas,&nbsp; notas cr&eacute;dito, notas contables, pagos, giros, transferencias y dem&aacute;s&nbsp; documentos soporte de una transacci&oacute;n.&nbsp;</span></li>
        <li><span class="span-custom">Realizar campa&ntilde;as publicitarias y de difusi&oacute;n mediante env&iacute;os masivos&nbsp; de email marketing, mensajes de texto, redes sociales y dem&aacute;s medios&nbsp; y canales de comunicaci&oacute;n de la Compa&ntilde;&iacute;a.&nbsp;</span></li>
        <li><span class="span-custom">Promocionar los diferentes productos o servicios prestados por la&nbsp; Compa&ntilde;&iacute;a.&nbsp;</span></li>
        <li><span class="span-custom">Informar sobre aspectos comerciales y publicitarios, nuevos productos&nbsp; o servicios de la Compa&ntilde;&iacute;a, cambios sobre los mismos, informaci&oacute;n&nbsp; sobre ofertas, promociones, concursos, eventos, capacitaciones,&nbsp; contenidos y dem&aacute;s informaci&oacute;n de inter&eacute;s.&nbsp;&nbsp;</span></li>
        <li><span class="span-custom">Implementar estrategias de mercadeo y fidelizaci&oacute;n.&nbsp;</span></li>
        <li><span class="span-custom">Realizar actividades comerciales, de servicio al cliente y administraci&oacute;n&nbsp; de la cartera.&nbsp;</span></li>
        <li><span class="span-custom">Atender y ejecutar los tr&aacute;mites de garant&iacute;as y devoluci&oacute;n de productos,&nbsp; as&iacute; como en los procesos de recepci&oacute;n, resoluci&oacute;n y respuesta de los&nbsp; PQRS.&nbsp;</span></li>
        <li><span class="span-custom">Realizar las actividades y controles conducentes a prevenir el lavado&nbsp; de activos, la financiaci&oacute;n del terrorismo, la financiaci&oacute;n de la&nbsp; proliferaci&oacute;n de armas de destrucci&oacute;n masiva, el soborno&nbsp; transnacional, la corrupci&oacute;n y el fraude.&nbsp;</span></li>
        <li><span class="span-custom">Crear y administrar bases de datos con la informaci&oacute;n de los titulares. </span><span class="span-custom">&bull; </span><span class="span-custom">Desarrollo de estudios de seguridad y estudios financieros.&nbsp;</span></li>
        <li><span class="span-custom">Realizar labores de videovigilancia.&nbsp;</span></li>
        <li><span class="span-custom">Consultar y reportar datos, (cuando aplique) del titular del dato a </span><span class="span-custom">centrales de informaci&oacute;n financiera-crediticia, o a operadores de </span><span class="span-custom">bancos de datos de informaci&oacute;n financiera, crediticia, comercial y </span><span class="span-custom">&nbsp;</span><span class="span-custom">provenientes de terceros pa&iacute;ses a que se refiere la Ley 1266 de 2008 </span><span class="span-custom">&nbsp;</span><span class="span-custom">para los fines indicados en dicha ley y sus normas reglamentarias o </span><span class="span-custom">&nbsp;</span><span class="span-custom">modificatorias.</span><span class="span-custom">&nbsp;</span></li>
        <li><span class="span-custom">Evaluar la calidad del servicio o los productos de la Compa&ntilde;&iacute;a.&nbsp;</span></li>
        <li><span class="span-custom">Realizar estudios de mercado.</span></li>
        <li><p><span class="span-custom">Adelantar todas las gestiones requeridas para realizar la cobranza de </span><span class="span-custom">&nbsp;</span><span class="span-custom">las obligaciones y la recuperaci&oacute;n de cartera tanto judicial como </span><span class="span-custom">&nbsp;</span><span class="span-custom">extrajudicialmente, incluida la realizaci&oacute;n de la notificaci&oacute;n previa al </span><span class="span-custom">&nbsp;</span><span class="span-custom">reporte negativo que se debe hacer ante las centrales de informaci&oacute;n </span><span class="span-custom">&nbsp;</span><span class="span-custom">financiera y crediticia, para lo cual podr&aacute;n ser utilizados los datos de </span><span class="span-custom">&nbsp;</span><span class="span-custom">contacto: tel&eacute;fono fijo, celular -SMS-, e-mail y direcci&oacute;n f&iacute;sica, los </span><span class="span-custom">&nbsp;</span><span class="span-custom">cuales autoriz&oacute; el titular, quien tambi&eacute;n autoriz&oacute; para que sean </span><span class="span-custom">&nbsp;</span><span class="span-custom">actualizados peri&oacute;dicamente, bien sea directamente o mediante la </span><span class="span-custom">&nbsp;</span><span class="span-custom">contrataci&oacute;n de terceros que provean este servicio.</span></p></li>
        <li><span class="span-custom">Transferir o transmitir informaci&oacute;n a terceros con el fin de realizar la&nbsp; distribuci&oacute;n o comercializaci&oacute;n de productos y para efectos&nbsp; comerciales, de cartera o estad&iacute;sticos.&nbsp;</span></li>
        <li><span class="span-custom">Comunicaciones oficiales necesarias con los empleados por parte de la&nbsp; Compa&ntilde;&iacute;a, reconocimientos o actividades sociales y/o laborales&nbsp; establecidas por la Compa&ntilde;&iacute;a; aspectos de n&oacute;mina; fines probatorios&nbsp; dentro de procesos disciplinarios y/o judiciales; carnetizaci&oacute;n y dem&aacute;s&nbsp; documentos de identificaci&oacute;n o reconocimiento de personal vinculado&nbsp; a la Compa&ntilde;&iacute;a.&nbsp;</span></li>
        <li><span class="span-custom">Desarrollar el proceso de selecci&oacute;n, evaluaci&oacute;n, vinculaci&oacute;n laboral y&nbsp; registrar informaci&oacute;n de los trabajadores directos e indirectos para el&nbsp; cumplimiento de las obligaciones laborales se&ntilde;aladas por la ley.&nbsp;</span></li>
        <li><span class="span-custom">La(s) finalidad(es) indicadas en la autorizaci&oacute;n otorgada por el titular&nbsp; de los datos o descritos en el aviso de privacidad respectivo, seg&uacute;n sea&nbsp; el caso.</span>
        <p>&nbsp;</p>
        </li>
        </ul>
        </li>
        </ul>
        <p class="p-custom"><span class="span-custom">Los datos contenidos en las bases de datos de CALYPSO DEL CARIBE s&oacute;lo&nbsp; ser&aacute;n utilizados para los prop&oacute;sitos aqu&iacute; se&ntilde;alados (o para los que el titular&nbsp; autoriza expresamente) y por lo tanto LA COMPA&Ntilde;&Iacute;A no proceder&aacute; a vender,&nbsp; licenciar, transmitir, o divulgar las Bases de Datos Personales, salvo que: (i)&nbsp; exista autorizaci&oacute;n expresa para hacerlo; (ii) sea necesario para permitir a los&nbsp; contratistas o agentes prestar los servicios encomendados; (iii) sea necesaria&nbsp; con el fin de proveer nuestros servicios o productos; (iv) que sea requerida o&nbsp; permitido por la ley.</span></p>
        <p>&nbsp;</p>
        <p class="p-custom"><strong>2.5. DATOS SENSIBLES&nbsp;&nbsp;</strong></p>
        <p class="p-custom"><span class="span-custom">LA COMPA&Ntilde;&Iacute;A tratar&aacute; datos considerados como sensibles, &uacute;nicamente&nbsp; cuando el Titular de estos autorice dicho tratamiento o en los casos, en que&nbsp; la ley determina que no se requiere dicha autorizaci&oacute;n.&nbsp;</span></p>
        <p class="p-custom"><span class="span-custom">En todo caso, cuando se requiera o pretenda obtener autorizaci&oacute;n para el&nbsp; manejo de datos sensibles, se informar&aacute; cu&aacute;les de los datos que se&nbsp; recoger&aacute;n se consideran como sensibles y que el Titular de estos no est&aacute;&nbsp; obligado a autorizar su Tratamiento.&nbsp;</span></p>
        <p class="p-custom"><strong>2.6. AVISO DE PRIVACIDAD&nbsp;</strong></p>
        <p class="p-custom"><span class="span-custom">El Aviso de Privacidad es el documento f&iacute;sico, electr&oacute;nico o en cualquier otro&nbsp; formato, que es puesto a disposici&oacute;n del Titular para el tratamiento de sus datos&nbsp; personales. A trav&eacute;s de este documento o texto se informa al Titular la&nbsp; informaci&oacute;n relativa a la existencia de las pol&iacute;ticas de tratamiento de informaci&oacute;n&nbsp; que le ser&aacute;n aplicables, la forma de acceder a las mismas y las caracter&iacute;sticas&nbsp; del tratamiento que se pretende dar a los datos personales.&nbsp;</span></p>
        <p class="p-custom-d"><strong>2.6.1. Contenido del aviso de privacidad&nbsp;&nbsp;</strong></p>
        <p class="p-custom-d"><span class="span-custom">El aviso de privacidad contiene la siguiente informaci&oacute;n:&nbsp;</span></p>
        <ul>
        <li class="li-custom">
        <ul>
        <li class="li-custom">
        <ul class="ul-custom">
        <li><span class="span-custom">Datos de contacto del responsable del tratamiento.&nbsp;</span></li>
        <li><span class="span-custom">El tipo de tratamiento al que se someter&aacute;n los datos. </span></li>
        <li>Mecanismos de acceso o consulta de la pol&iacute;tica de procedimientos&nbsp; para el manejo de base de datos.</li>
        </ul>
        </li>
        </ul>
        </li>
        </ul>
        <p class="p-custom"><strong>2.7. ALMACENAMIENTO Y PROTECCI&Oacute;N DE LOS DATOS PERSONALES</strong></p>
        <p class="p-custom"><span class="span-custom">CALYPSO DEL CARIBE adopta las medidas necesarias para mantener&nbsp; registros de cu&aacute;ndo y c&oacute;mo se obtuvieron las autorizaciones por parte de los&nbsp; titulares de datos personales y el tratamiento de estos, de cara a conservar&nbsp; la informaci&oacute;n bajo las condiciones de seguridad necesarias para impedir su&nbsp; adulteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no autorizado o fraudulento.&nbsp; As&iacute; mismo, conservar&aacute; de manera f&iacute;sica o electr&oacute;nica, copia del cumplimiento&nbsp; del deber de informaci&oacute;n previamente mencionado, as&iacute; como de la&nbsp; autorizaci&oacute;n o rechazo por parte del titular sobre la recolecci&oacute;n y tratamiento&nbsp; de sus Datos Personales. </span></p>
        <p><strong>3. DERECHOS DE LOS TITULARES&nbsp;</strong></p>
        <p><span class="span-custom">De conformidad con lo establecido en el art&iacute;culo 8 de la Ley 1581 de 2012 el&nbsp; titular de los datos personales tiene los siguientes derechos:&nbsp;</span></p>
        <ul>
        <li><span class="span-custom">Conocer, actualizar y rectificar sus datos personales frente a CALYPSO DEL CARIBE, en su condici&oacute;n de responsable del&nbsp; tratamiento.&nbsp;</span></li>
        <li><span class="span-custom">Solicitar prueba de la autorizaci&oacute;n otorgada a CALYPSO DEL CARIBE,&nbsp; en su condici&oacute;n de responsable del Tratamiento.&nbsp;</span></li>
        <li><span class="span-custom">Ser informado por CALYPSO DEL CARIBE, previa solicitud, respecto&nbsp; del uso que le ha dado a sus datos personales.&nbsp;</span></li>
        <li><span class="span-custom">Revocar la autorizaci&oacute;n o solicitar la supresi&oacute;n del dato cuando en el Tratamiento no se respeten los principios, derechos y garant&iacute;as&nbsp; constitucionales y legales.&nbsp;</span></li>
        <li><span class="span-custom">Acceder en forma gratuita a sus datos personales que hayan sido&nbsp; objeto del tratamiento.&nbsp;</span></li>
        </ul>
        <p><span class="span-custom">Presentar ante la Superintendencia de Industria y Comercio quejas por&nbsp; infracciones a lo dispuesto en la Ley 1581 de 2012, una vez haya agotado el&nbsp; tr&aacute;mite de consulta o reclamo ante el Responsable del Tratamiento.</span></p>
        <p><strong>4. DERECHOS DE LOS NI&Ntilde;OS, NI&Ntilde;AS Y ADOLESCENTES&nbsp;</strong></p>
        <p><span class="span-custom">De conformidad con lo establecido en el art&iacute;culo 7 de la Ley 1581 de 2012 y&nbsp; el art&iacute;culo 12 del Decreto 1377 de 2013, La Compa&ntilde;&iacute;a s&oacute;lo realizar&aacute; el&nbsp; Tratamiento de datos personales correspondientes a ni&ntilde;os, ni&ntilde;as y&nbsp; adolescentes, siempre y cuando: i) Responda y respete el inter&eacute;s superior de&nbsp; los ni&ntilde;os, ni&ntilde;as y adolescentes, ii) Asegure el respeto de sus derechos&nbsp; fundamentales. Cumplidos los anteriores requisitos, La Compa&ntilde;&iacute;a deber&aacute;&nbsp; obtener la Autorizaci&oacute;n del representante legal del ni&ntilde;o, ni&ntilde;a o adolescente,&nbsp; previo ejercicio del menor de su derecho a ser escuchado, opini&oacute;n que ser&aacute;&nbsp; valorada teniendo en cuenta la madurez, autonom&iacute;a y capacidad para&nbsp; entender el asunto. </span></p>
        <p><strong>5. DEBERES DE CALYPSO DEL CARIBE&nbsp;</strong></p>
        <p><span class="span-custom">CALYPSO DEL CARIBE tiene presente, en todo momento, que los datos&nbsp; personales son propiedad de los titulares y que s&oacute;lo ellos pueden decidir sobre&nbsp; los mismos. En este sentido, har&aacute; uso de ellos solo para aquellas finalidades&nbsp; para las que se encuentra facultado debidamente, y respetando en todo caso&nbsp; la Ley 1581 de 2012 sobre protecci&oacute;n de datos personales, as&iacute; como se&nbsp; compromete a cumplir en forma permanente con los siguientes deberes en lo&nbsp; relacionado con el tratamiento de datos personales:&nbsp;</span></p>
        <ul>
        <li><span class="span-custom">Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de h&aacute;beas data.&nbsp;</span></li>
        <li><span class="span-custom">Solicitar y conservar, en las condiciones previstas en la ley, copia de la&nbsp; respectiva autorizaci&oacute;n otorgada por el Titular.&nbsp;</span></li>
        <li><span class="span-custom">Informar debidamente al Titular sobre la finalidad de la recolecci&oacute;n y los&nbsp; derechos que le asisten por virtud de la autorizaci&oacute;n otorgada.&nbsp;</span></li>
        <li><span class="span-custom">Conservar la informaci&oacute;n bajo las condiciones de seguridad necesarias&nbsp; para impedir su adulteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no&nbsp; autorizado o fraudulento.&nbsp;</span></li>
        <li><span class="span-custom">Garantizar que la informaci&oacute;n que se suministre al Encargado del </span><span class="span-custom">Tratamiento sea veraz, completa, exacta, actualizada, comprobable y </span><span class="span-custom">comprensible.</span></li>
        <li>Actualizar la informaci&oacute;n, comunicando de forma oportuna al Encargado del Tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las dem&aacute;s medidas necesarias para que la informaci&oacute;n suministrada a este se <span>&nbsp;</span><span>mantenga actualizada.</span><span>&nbsp;</span></li>
        <li><span class="span-custom">Rectificar la informaci&oacute;n cuando sea incorrecta y comunicar lo </span>pertinente al Encargado del Tratamiento.<span>&nbsp;</span></li>
        <li><span class="span-custom">Suministrar al Encargado del Tratamiento, seg&uacute;n el caso, &uacute;nicamente </span><span class="span-custom">datos cuyo Tratamiento est&eacute; previamente autorizado de conformidad </span><span class="span-custom">con lo previsto en la presente ley.</span><span class="span-custom">&nbsp;</span></li>
        <li><span class="span-custom">Exigir al Encargado del Tratamiento en todo momento, el respeto a </span><span class="span-custom">las condiciones de seguridad y privacidad de la informaci&oacute;n del </span>Titular.<span>&nbsp;</span></li>
        <li><span class="span-custom">Tramitar las consultas y reclamos formulados en los t&eacute;rminos se&ntilde;alados&nbsp; en la ley.&nbsp;</span></li>
        <li><span class="span-custom">Informar al Encargado del Tratamiento cuando determinada informaci&oacute;n&nbsp; se encuentra en discusi&oacute;n por parte del Titular, una vez se haya&nbsp; presentado la reclamaci&oacute;n y no haya finalizado el tr&aacute;mite respectivo. </span></li>
        <li><span class="span-custom">Informar a solicitud del Titular sobre el uso dado a sus datos.&nbsp;</span></li>
        <li><span class="span-custom">Informar a la autoridad de protecci&oacute;n de datos cuando se presenten&nbsp; violaciones a los c&oacute;digos de seguridad y existan riesgos en la&nbsp; administraci&oacute;n de la informaci&oacute;n de los Titulares. </span></li>
        <li>Cumplir las instrucciones y requerimientos que imparta la&nbsp; Superintendencia de Industria y Comercio.</li>
        </ul>
        <p><strong>6. RESPONSABLES DE LA ADMINISTRACI&Oacute;N Y EL TRATAMIENTO DE&nbsp; DATOS PERSONALES&nbsp;</strong></p>
        <p class="p-custom"><strong>Responsable: </strong><span class="span-custom">CALYPSO DEL CARIBE S.A.&nbsp;</span></p>
        <p class="p-custom"><strong>Contacto</strong><span class="span-custom">: </span><span class="span-custom-c"><em>datospersonales@calypso.la</em></span></p>
        <p class="p-custom"><strong>Direcci&oacute;n: </strong><span class="span-custom">Parque Industrial Tibitoc Bodega 44&nbsp;</span></p>
        <p class="p-custom"><strong>Tel&eacute;fono: </strong><span class="span-custom-b">8697799</span></p>
        <p><strong>7. AREA RESPONSABLE Y ATENCI&Oacute;N DE LAS PETICIONES, </strong><strong>CONSULTAS O RECLAMOS.&nbsp;</strong></p>
        <p><span class="span-custom">Las solicitudes de acceso, rectificaci&oacute;n, actualizaci&oacute;n, supresi&oacute;n de datos o&nbsp; revocatoria del consentimiento o autorizaci&oacute;n otorgada para el tratamiento&nbsp; de datos personales deber&aacute;n ser dirigidas a:&nbsp;</span></p>
        <p><span class="span-custom">CALYPSO DEL CARIBE S.A. al correo electr&oacute;nico </span></p>
        <p><span class="span-custom-c"><em><span class="span-custom">datospersonales@calypso.la</span></em></span><span class="span-custom">.</span></p>
        <p><span class="span-custom">Seg&uacute;n sea el caso, deber&aacute;n someterse al siguiente procedimiento: </span></p>
        <p class="p-custom"><strong>7.1. SOLICITUD DE ACCESO&nbsp;</strong></p>
        <p class="p-custom"><span class="span-custom">El poder de disposici&oacute;n o decisi&oacute;n que tiene el titular sobre la informaci&oacute;n&nbsp; que le concierne, conlleva el derecho de acceder y conocer si su informaci&oacute;n&nbsp; personal est&aacute; siendo objeto de tratamiento. As&iacute; CALYPSO DEL CARIBE debe garantizar al titular su derecho de acceso en tres v&iacute;as:&nbsp;</span></p>
        <ul>
        <li class="li-custom">
        <ul class="ul-custom">
        <li><span class="span-custom">El titular puede conocer la efectiva existencia del tratamiento a que son&nbsp; sometidos sus datos personales.&nbsp;</span></li>
        <li><span class="span-custom">El titular puede tener acceso a los datos personales que est&aacute;n en&nbsp; posesi&oacute;n del responsable.&nbsp;</span></li>
        <li><span class="span-custom">El titular cuenta con el derecho a conocer las circunstancias esenciales&nbsp; del tratamiento, lo que lleva al cumplimiento del deber de CALYPSO&nbsp; DEL CARIBE de informar al titular sobre el tipo de datos personales&nbsp; tratados y todas y cada una de las finalidades del tratamiento.&nbsp;</span></li>
        </ul>
        </li>
        </ul>
        <p class="p-custom"><strong>7.2. CONSULTAS&nbsp;</strong></p>
        <p class="p-custom"><span class="span-custom">De conformidad con lo establecido en el art&iacute;culo 14 de la Ley 1581 de 2012&nbsp; los titulares podr&aacute;n consultar su informaci&oacute;n personal que repose en&nbsp; cualquier base de datos. En consecuencia, CALYPSO DEL CARIBE garantiza el derecho de consulta, suministrando a los titulares, toda la&nbsp; informaci&oacute;n contenida en el registro individual o que est&eacute; vinculada con la identificaci&oacute;n del Titular. Para la atenci&oacute;n de solicitudes de consulta de&nbsp; datos personales CALYPSO DEL CARIBE garantiza:&nbsp;</span></p>
        <ul>
        <li class="li-custom">
        <ul class="ul-custom">
        <li><span class="span-custom">Habilitar medios de comunicaci&oacute;n electr&oacute;nica u otros que considere pertinentes.&nbsp;</span></li>
        <li><span class="span-custom">Establecer formularios, sistemas y otros m&eacute;todos simplificados, los&nbsp; mismos que deben ser Informados en el aviso de privacidad.&nbsp;</span></li>
        <li><span class="span-custom">Habilitar medios de comunicaci&oacute;n electr&oacute;nica u otros que considere&nbsp; pertinentes.&nbsp;</span></li>
        <li><span class="span-custom">Utilizar los servicios de atenci&oacute;n al cliente o de reclamaciones que&nbsp; tiene en operaci&oacute;n.&nbsp;</span></li>
        </ul>
        </li>
        </ul>
        <p class="p-custom"><span class="span-custom">En cualquier caso, independientemente del mecanismo implementado para&nbsp; la atenci&oacute;n de solicitudes de consulta, las mismas ser&aacute;n atendidas en un&nbsp; t&eacute;rmino m&aacute;ximo de diez (10) d&iacute;as h&aacute;biles contados a partir de la fecha de&nbsp; su recibo. Cuando no fuere posible atender la consulta dentro de dicho&nbsp; termino, se informar&aacute; al interesado antes del vencimiento, expresando los&nbsp; motivos de la demora y se&ntilde;alando la fecha en que se atender&aacute; su consulta,&nbsp; la cual en ning&uacute;n caso podr&aacute; superar los cinco (5) d&iacute;as h&aacute;biles siguientes al&nbsp; vencimiento del primer plazo.</span></p>
        <p class="p-custom"><strong>7.3. RECLAMOS&nbsp;</strong></p>
        <p class="p-custom"><span class="span-custom">De conformidad con lo establecido en el art&iacute;culo 15 de la Ley 1581 de 2012,&nbsp; el Titular que considere que la informaci&oacute;n contenida en una base de datos&nbsp; debe ser objeto de correcci&oacute;n, actualizaci&oacute;n o supresi&oacute;n, o cuando adviertan&nbsp; el presunto incumplimiento de cualquiera de los deberes contenidos en la&nbsp; Ley 1581 de 2012 o en este documento, podr&aacute;n presentar un reclamo ante&nbsp; el Responsable del Tratamiento, el cual ser&aacute; tramitado bajo las siguientes&nbsp; reglas:&nbsp;</span></p>
        <p class="p-custom-c"><span class="span-custom"><strong>a)</strong> El reclamo lo podr&aacute; presentar el Titular, teniendo en cuenta la&nbsp; informaci&oacute;n se&ntilde;alada en el art&iacute;culo 15 de la Ley 1581 de 2012, en &eacute;l, o los formatos, que al efecto disponga el responsable. Si el reclamo&nbsp; recibido no cuenta con informaci&oacute;n completa que permita darle tr&aacute;mite,&nbsp;</span><span class="span-custom">esto es, con la identificaci&oacute;n del Titular, la descripci&oacute;n de los hechos&nbsp; que dan lugar al reclamo, la direcci&oacute;n, y acompa&ntilde;ando los documentos&nbsp; que se quiera hacer valer, se requerir&aacute; al interesado dentro de los cinco&nbsp; (5) d&iacute;as siguientes a su recepci&oacute;n para que subsane las fallas.</span></p>
        <p class="p-custom-c"><span class="span-custom">Transcurridos dos (2) meses desde la fecha del requerimiento sin que&nbsp; el solicitante presente la informaci&oacute;n requerida, se entender&aacute; que ha&nbsp; desistido del reclamo. Si por alguna circunstancia se recibe un reclamo&nbsp; que en realidad no deber&iacute;a ir dirigido a CALYPSO DEL CARIBE, &eacute;sta&nbsp; dar&aacute; traslado, en la medida de sus posibilidades, a quien corresponda&nbsp; en un t&eacute;rmino m&aacute;ximo de dos (2) d&iacute;as h&aacute;biles, e informar&aacute; de la&nbsp; situaci&oacute;n al interesado.&nbsp;</span></p>
        <p class="p-custom-c"><span class="span-custom"><strong>b)</strong> Una vez recibido el reclamo completo, se incluir&aacute; en la base de datos&nbsp; que mantiene el responsable una leyenda que diga &ldquo;reclamo en tr&aacute;mite&rdquo;&nbsp; y el motivo de este, en un t&eacute;rmino no mayor a dos (2) d&iacute;as h&aacute;biles.&nbsp; Dicha leyenda deber&aacute; mantenerse hasta que el reclamo sea decidido.</span></p>
        <p class="p-custom-c"><span class="span-custom"><strong>c)</strong> El t&eacute;rmino m&aacute;ximo para atender el reclamo ser&aacute; de quince (15) d&iacute;as&nbsp; h&aacute;biles contados a partir del d&iacute;a siguiente a la fecha de su recibo.&nbsp; Cuando no fuere posible atenderlo dentro de dicho t&eacute;rmino se&nbsp; informar&aacute; al interesado antes del vencimiento del referido plazo los&nbsp; motivos de la demora y la fecha en que se atender&aacute; su reclamo, la cual&nbsp; en ning&uacute;n caso podr&aacute; superar los ocho (8) d&iacute;as h&aacute;biles siguientes al&nbsp; vencimiento del primer t&eacute;rmino.</span></p>
                <p class="p-custom-d"><strong>7.3.1. Procedimiento para la presentaci&oacute;n de reclamos&nbsp;&nbsp;</strong></p>
        <p class="p-custom-d"><span class="span-custom">En cualquier momento y de manera gratuita el titular podr&aacute; solicitar a&nbsp; CALYPSO DEL CARIBE la rectificaci&oacute;n, actualizaci&oacute;n o supresi&oacute;n de sus&nbsp; datos personales, previa acreditaci&oacute;n de su identidad.&nbsp;</span></p>
        <p class="p-custom-d"><span class="span-custom">Los derechos de rectificaci&oacute;n, actualizaci&oacute;n o supresi&oacute;n &uacute;nicamente se podr&aacute;n&nbsp; ejercer por el titular previa acreditaci&oacute;n de su identidad o su representante,&nbsp; previa acreditaci&oacute;n de la representaci&oacute;n.&nbsp;</span></p>
        <p class="p-custom-d"><span class="span-custom">Cuando la solicitud sea formulada por persona distinta del titular y no se&nbsp;</span><span class="span-custom">acredite que la misma act&uacute;a en representaci&oacute;n de aquel, se tendr&aacute; por no&nbsp; presentada.&nbsp;</span></p>
        <p class="p-custom-d"><span class="span-custom">La solicitud de rectificaci&oacute;n, actualizaci&oacute;n o supresi&oacute;n debe ser presentada a&nbsp; trav&eacute;s de los Medios habilitados por CALYPSO DEL CARIBE se&ntilde;alados en el&nbsp; aviso de privacidad y debe contener, como m&iacute;nimo, la siguiente informaci&oacute;n:&nbsp;</span></p>
        <ul>
        <li class="li-custom">
        <ul>
        <li class="li-custom">
        <ul class="ul-custom">
        <li><span class="span-custom">El nombre, correo electr&oacute;nico y domicilio del titular o cualquier otro&nbsp; medio para recibir la respuesta.&nbsp;</span></li>
        <li><span class="span-custom">Los documentos que acrediten la identidad o la personalidad de su&nbsp; representante.&nbsp;</span></li>
        <li><span class="span-custom">La descripci&oacute;n clara y precisa de los datos personales respecto de los&nbsp; cuales el titular busca ejercer alguno de los derechos.&nbsp;</span></li>
        <li><span class="span-custom">En caso dado, otros elementos o documentos que faciliten la&nbsp; localizaci&oacute;n de los datos personales.&nbsp;</span></li>
        </ul>
        </li>
        </ul>
        </li>
        </ul>
        <p class="p-custom-d"><span class="span-custom">CALYPSO DEL CARIBE tiene la obligaci&oacute;n de rectificar y actualizar a solicitud&nbsp; del titular, la informaci&oacute;n de &eacute;ste que resulte ser incompleta o inexacta, de&nbsp; conformidad con el procedimiento y los t&eacute;rminos se&ntilde;alados. Al respecto se&nbsp; tendr&aacute; en cuenta:&nbsp;</span></p>
        <p class="p-custom-d"><span class="span-custom">En las solicitudes de rectificaci&oacute;n y actualizaci&oacute;n de datos personales el titular&nbsp; debe indicar las correcciones a realizar y anexar la documentaci&oacute;n que avale&nbsp; su petici&oacute;n. CALYPSO DEL CARIBE tiene plena libertad de habilitar&nbsp; mecanismos que le faciliten el ejercicio de este derecho, siempre y cuando&nbsp; estos beneficien al titular.&nbsp;</span></p>
        <p class="p-custom-d"><span class="span-custom">CALYPSO DEL CARIBE podr&aacute; establecer formularios, sistemas y otros&nbsp; m&eacute;todos simplificados, mismos que deben ser informados por medio del aviso&nbsp; de privacidad y que se pondr&aacute;n a disposici&oacute;n de los interesados. Para estos&nbsp; efectos CALYPSO DEL CARIBE podr&aacute; utilizar los mismos servicios de&nbsp; atenci&oacute;n o servicio al cliente que tienen en operaci&oacute;n, siempre y cuando los&nbsp; plazos de respuesta no sean mayores a los se&ntilde;alados por el art&iacute;culo 15 de la&nbsp; ley 1581 de 2012.&nbsp;</span></p>
        <p class="p-custom-d"><span class="span-custom">El titular tiene el derecho, en todo momento, a solicitar a CALYPSO DEL&nbsp; CARIBE la eliminaci&oacute;n de sus datos personales cuando:&nbsp;</span></p>
        <ul>
        <li class="li-custom">
        <ul>
        <li class="li-custom">
        <ul class="ul-custom">
        <li><span class="span-custom">Considere que los mismos no est&aacute;n siendo tratados conforme a los principios, deberes y obligaciones previstas en la ley 1581 de 2012.&nbsp;</span></li>
        <li><span class="span-custom">Hayan dejado de ser necesarios o pertinentes para la finalidad para la&nbsp; cual fueron Recaudados.&nbsp;</span></li>
        <li><span class="span-custom">Se haya superado el periodo necesario para el cumplimiento de los fines&nbsp; para los cuales fueron recolectados.&nbsp;</span></li>
        </ul>
        </li>
        </ul>
        </li>
        </ul>
        <p class="p-custom-d"><span class="span-custom">Esto implica la eliminaci&oacute;n total o parcial de la informaci&oacute;n personal de&nbsp; acuerdo con lo solicitado por el titular en los registros, archivos, base de datos&nbsp; o tratamientos realizados por CALYPSO DEL CARIBE. Es importante resaltar&nbsp; que este derecho del titular no es absoluto y el responsable puede negar el&nbsp; ejercicio de este cuando el titular tenga un deber legal o contractual de&nbsp; permanecer en la base de datos, cuando, la eliminaci&oacute;n obstaculice&nbsp; actuaciones judiciales o administrativas vinculadas a obligaciones fiscales,&nbsp; cuando, la investigaci&oacute;n y persecuci&oacute;n de delitos o la actualizaci&oacute;n de&nbsp; sanciones administrativas y si los datos son necesarios para proteger los&nbsp; intereses jur&iacute;dicamente tutelados por el titular.&nbsp;</span></p>
        <p class="p-custom-d"><span class="span-custom">En caso de resultar procedente la cancelaci&oacute;n de los datos, CALYPSO DEL&nbsp; CARIBE deber&aacute; realizar la supresi&oacute;n de una manera operativa que no permita&nbsp; la recuperaci&oacute;n de esta informaci&oacute;n.</span></p>
        <p class="p-custom"><strong>7.4. REVOCATORIA DE AUTORIZACI&Oacute;N&nbsp;</strong></p>
        <p class="p-custom"><span class="span-custom">Los titulares de los datos personales pueden revocar el consentimiento al&nbsp; tratamiento de sus datos personales en cualquier momento, siempre y cuando&nbsp; no lo impida una disposici&oacute;n legal o contractual. Para ello, CALYPSO DEL&nbsp; CARIBE deber&aacute; establecer mecanismos sencillos y gratuitos que permitan al&nbsp; titular revocar su consentimiento, al menos por el mismo medio por el que lo&nbsp; otorg&oacute;. Se deber&aacute; tener en cuenta que existen dos modalidades en las que la&nbsp; revocaci&oacute;n del consentimiento puede darse. La primera, puede ser sobre la&nbsp; totalidad de las finalidades consentidas, esto es, que CALYPSO DEL CARIBE deba dejar de tratar por completo los datos del titular; la segunda, puede&nbsp; ocurrir sobre tipos de tratamiento determinados, como por ejemplo para fines&nbsp; publicitarios o de estudios de mercado. Con la segunda modalidad, esto es, la&nbsp; revocaci&oacute;n parcial del consentimiento, se mantienen a salvo otros fines del&nbsp; tratamiento que el responsable, de conformidad con la autorizaci&oacute;n otorgada&nbsp;</span><span class="span-custom">puede llevar a cabo y con los que el titular est&aacute; de acuerdo. Por lo anterior,&nbsp; ser&aacute; necesario que el titular al momento de elevar la solicitud de revocatoria&nbsp; indique en este si la revocaci&oacute;n que pretende realizar es total o parcial.&nbsp;</span></p>
        <p class="p-custom"><span class="span-custom">En la segunda hip&oacute;tesis se deber&aacute; indicar con cual tratamiento el titular no&nbsp; est&aacute; conforme. Habr&aacute; casos en que el consentimiento, por su car&aacute;cter&nbsp; necesario en la relaci&oacute;n entre titular y responsable por el cumplimiento de un&nbsp; contrato, por disposici&oacute;n legal no podr&aacute; ser revocado. Los mecanismos o&nbsp; procedimientos que CALYPSO DEL CARIBE establezca para atender las&nbsp; solicitudes de revocatoria del consentimiento no podr&aacute;n exceder los plazos&nbsp; previstos para atender las reclamaciones conforme se se&ntilde;ala en el art&iacute;culo 15&nbsp; de la Ley 1581 de 2012.&nbsp;</span></p>
        <p class="p-custom"><span class="span-custom">CALYPSO DEL CARIBE conservar&aacute; de manera f&iacute;sica o electr&oacute;nica prueba de&nbsp; la atenci&oacute;n efectiva a la consulta o reclamo realizada por el Titular de los Datos&nbsp; Personales.&nbsp;</span></p>
        <p class="p-custom"><span class="span-custom">CALYPSO DEL CARIBE suministrar&aacute; la informaci&oacute;n correspondiente a los&nbsp; Datos Personales del Titular a las siguientes personas:&nbsp;</span></p>
        <p class="p-custom-d"><span class="span-custom"><strong>a)</strong> Los titulares, sus causahabientes o sus representantes legales&nbsp;</span></p>
        <p class="p-custom-d"><span class="span-custom"><strong>b)</strong> Las entidades p&uacute;blicas o administrativas en ejercicio de sus funciones&nbsp; legales o por orden judicial.&nbsp;</span></p>
        <p class="p-custom-d"><span class="span-custom"><strong>c)</strong> A los terceros autorizados por el Titular o por la Ley.</span></p>
        <p class="p-custom"><span class="span-custom">CALYPSO DEL CARIBE implementar&aacute; las medidas necesarias para&nbsp; garantizar que el acceso a la informaci&oacute;n se permita despu&eacute;s de verificar la identidad del interesado. </span></p>
        <p><strong>8. TRANSFERENCIA Y REVELACI&Oacute;N DE DATOS PERSONALES</strong></p>
        <p><span class="span-custom">De acuerdo a lo establecido en la Ley 1581 de 2012, CALYPSO DEL&nbsp; CARIBE no realizar&aacute; transferencia de datos personales de los Titulares a un&nbsp; pa&iacute;s que no ofrezca un nivel adecuado de protecci&oacute;n de datos. Para&nbsp; determinar si un pa&iacute;s ofrece un nivel adecuado de protecci&oacute;n de datos, dicho&nbsp; pa&iacute;s deber&aacute; cumplir con los est&aacute;ndares indicados por la Superintendencia de&nbsp; Industria y Comercio sobre la materia, los cuales en ning&uacute;n caso podr&aacute;n ser inferiores a los que dicha ley exige a sus destinatarios. Esta prohibici&oacute;n no&nbsp; regir&aacute; cuando se trate de:&nbsp;</span></p>
        <ul class="ul-custom">
        <li><span class="span-custom">Informaci&oacute;n respecto de la cual el Titular haya otorgado su autorizaci&oacute;n&nbsp; expresa e inequ&iacute;voca para la transferencia;&nbsp;</span></li>
        <li><span class="span-custom">Intercambio de datos de car&aacute;cter m&eacute;dico, cuando as&iacute; lo exija el Tratamiento del Titular por razones de salud o higiene p&uacute;blica;&nbsp;</span></li>
        <li><span class="span-custom">Transferencias bancarias o burs&aacute;tiles, conforme a la legislaci&oacute;n que les&nbsp; resulte aplicable;&nbsp;</span></li>
        <li><span class="span-custom">Transferencias acordadas en el marco de tratados internacionales en&nbsp; los cuales la Rep&uacute;blica de Colombia sea parte, con fundamento en el&nbsp; principio de reciprocidad;&nbsp;</span></li>
        <li><span class="span-custom">Transferencias necesarias para la ejecuci&oacute;n de un contrato entre el&nbsp; Titular y el responsable del Tratamiento, o para la ejecuci&oacute;n de medidas&nbsp; precontractuales siempre y cuando se cuente con la autorizaci&oacute;n del&nbsp; Titular;&nbsp;</span></li>
        <li><span class="span-custom">Transferencias legalmente exigidas para la salvaguardia del inter&eacute;s&nbsp; p&uacute;blico, o para el reconocimiento, ejercicio o defensa de un derecho en&nbsp; un proceso judicial.&nbsp;</span></li>
        </ul>
        <p><strong>9. VIGENCIA</strong></p>
        <p><span class="span-custom">CALYPSO DEL CARIBE conservar&aacute; los datos personales de los titulares&nbsp; durante el tiempo en que dicha informaci&oacute;n se mantenga y se utilice para las&nbsp; finalidades previstas en este documento y mientras que no haya una solicitud&nbsp; de supresi&oacute;n por parte del Titular, salvo que exista una obligaci&oacute;n legal de&nbsp; conservar dicha informaci&oacute;n.&nbsp;</span></p>
        <p><span class="span-custom">CALYPSO DEL CARIBE se reserva la potestad de modificar el presente&nbsp; documento para adaptarlo a nuevos requerimientos legislativos,&nbsp; jurisprudenciales y t&eacute;cnicos.&nbsp;</span></p>
        <p><span class="span-custom">La presente pol&iacute;tica entra en vigencia a partir del 10 de octubre de 2022.</span></p>
</div>
