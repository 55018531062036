<app-secondary-header></app-secondary-header>
<app-mobile-header *ngIf="!isApp"></app-mobile-header>

<div class="container-fluid d-flex w-100 h-100 align-items-center justify-content-center">
    <div class="flex-1 my-5 d-flex flex-column h-100 align-items-center justify-content-center">
        <h2 class="display-4">
            Respuesta PSE
        </h2>
        <img src="https://www.homecenter.com.co/static/landing/PSE/img/PSE.png" alt="Logo PSE" class="m-4" width="140px" height="140px">
        <div class="payment-status">
            La transacción está {{payment?.x_transaction_state?.toUpperCase()}}
        </div>
        <div class="payment-info w-100 my-4 mb-5 px-2">
            <div class="row">
                <div class="col-6">Autorización:</div>
                <div class="col-6">{{payment?.x_approval_code}}</div>
            </div>
            <div class="row">
                <div class="col-6">Fecha:</div>
                <div class="col-6">{{payment?.x_transaction_date}}</div>
            </div>
            <div class="row">
                <div class="col-6">Banco:</div>
                <div class="col-6">{{payment?.x_bank_name}}</div>
            </div>
            <div class="row">
                <div class="col-6">Pedido:</div>
                <div class="col-6">{{payment?.x_id_invoice}}</div>
            </div>
            <div class="row">
                <div class="col-6">Valor:</div>
                <div class="col-6">${{payment?.x_amount}}</div>
            </div>
            <div class="row mt-2">
                <div class="col-12">* La transacción se puede demorar un tiempo en procesar. <br> Puedes recargar esta página para revisar el estado.</div>
            </div>
        </div>
        <div class="div-btns d-flex justify-content-between w-100">
            <button class="btn btn-outline-dark" [ngClass]="{'d-block m-auto': isApp}" (click)="goHome()">Inicio</button>
            <button class="btn btn-primary" (click)="goOrders()" *ngIf="!isApp">Ver mis órdenes</button>
        </div>
    </div>
</div>