import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddressModule } from '../address/address.module';
import { ProductInfoComponent } from './product-info/product-info.component';
import { SecondaryHeaderComponent } from './secondary-header/secondary-header.component';
import { AddCreditCardComponent } from './add-credit-card/add-credit-card.component';
import { CardsListComponent } from './cards-list/cards-list.component';
import { CardListItemComponent } from './card-list-item/card-list-item.component';
import { SplitPairsPipe } from './pipes/pairs.pipe';
import { WhatsappButtonComponent } from './whatsapp-button/whatsapp-button.component';
import { ProductInfoWithQuantityComponent } from './product-info-with-quantity/product-info-with-quantity.component';
import { OrderPriceInfoComponent } from './order-price-info/order-price-info.component';
import { RatingStarsComponent } from './rating-stars/rating-stars.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { MobileHeaderComponent } from './mobile-header/mobile-header.component';
import { BaseHeaderComponent } from './base-header/base-header.component';
import { TerminosYCondicionesComponent } from './terminos-ycondiciones/terminos-ycondiciones.component';
import { PoliticaDeDatosComponent } from './politica-de-datos/politica-de-datos.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ChangeShippingModalComponent } from './modals/change-shipping-modal/change-shipping-modal.component';
import { BrandsComponent } from './brands/brands.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TabsComponent } from './tabs/tabs.component';
import { TabComponent } from './tab/tab.component';
import { RespuestaPseComponent } from './respuesta-pse/respuesta-pse.component';
import { RandomQuestionsComponent } from './random-questions/random-questions.component';
import { ModalInfoVariableWeightComponent } from './modal-info-variable-weight/modal-info-variable-weight.component';
import { TerminosYCondicionesCopyComponent } from './terminos-y-condiciones-copy/terminos-y-condiciones-copy.component';
import { PoliticasDeDatosCopyComponent } from './politicas-de-datos-copy/politicas-de-datos-copy.component';
import { FilterButtonComponent } from './filter-button/filter-button.component';
import { OrderButtonComponent } from './order-button/order-button.component';
import { PoliticasGarantiasCambiosDevolucionesComponent } from './politicas-garantias-cambios-devoluciones/politicas-garantias-cambios-devoluciones.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SubHeaderComponent,
    ShoppingCartComponent,
    ProductInfoComponent,
    SecondaryHeaderComponent,
    AddCreditCardComponent,
    BrandsComponent,
    CardsListComponent,
    CardListItemComponent,
    WhatsappButtonComponent,
    SplitPairsPipe,
    ProductInfoWithQuantityComponent,
    OrderPriceInfoComponent,
    RatingStarsComponent,
    SideMenuComponent,
    MobileHeaderComponent,
    BaseHeaderComponent,
    TerminosYCondicionesComponent,
    PoliticaDeDatosComponent,
    NotFoundComponent,
    ChangeShippingModalComponent,
    TabsComponent,
    TabComponent,
    RespuestaPseComponent,
    RandomQuestionsComponent,
    ModalInfoVariableWeightComponent,
    TerminosYCondicionesCopyComponent,
    PoliticasDeDatosCopyComponent,
    FilterButtonComponent,
    OrderButtonComponent,
    PoliticasGarantiasCambiosDevolucionesComponent,
    CalculatorComponent,
    CustomCurrencyPipe,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SubHeaderComponent,
    ShoppingCartComponent,
    ProductInfoComponent,
    SecondaryHeaderComponent,
    AddCreditCardComponent,
    BrandsComponent,
    CardsListComponent,
    WhatsappButtonComponent,
    SplitPairsPipe,
    ProductInfoWithQuantityComponent,
    OrderPriceInfoComponent,
    RatingStarsComponent,
    MobileHeaderComponent,
    ChangeShippingModalComponent,
    TabsComponent,
    TabComponent,
    RandomQuestionsComponent,
    TerminosYCondicionesCopyComponent,
    PoliticasDeDatosCopyComponent,
    PoliticasGarantiasCambiosDevolucionesComponent,
    FilterButtonComponent,
    OrderButtonComponent,
    CalculatorComponent,
    CustomCurrencyPipe
  ],
  imports: [CommonModule, ReactiveFormsModule, AddressModule, FormsModule, MatSlideToggleModule],
})
export class SharedModule {}
