import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import $ from 'jquery';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart/shopping-cart.service';

declare var $: $;

@Component({
  selector: 'app-change-shipping-modal',
  templateUrl: './change-shipping-modal.component.html',
  styleUrls: ['./change-shipping-modal.component.scss']
})
export class ChangeShippingModalComponent implements OnInit {

  constructor(private shoppingCart: ShoppingCartService, public router: Router) { }

  ngOnInit(): void {
  }

  modificarModalidad(): void {
    // this.shoppingCart.cleanShoppingCart();
    window.open('/#deliverSelectorModal', "_self");
  }

}
