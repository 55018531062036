<div class="product-loader">
  <ngx-skeleton-loader
    appearance="circle"
    [theme]="{
      width: '150px',
      height: '170px',
      'background-color': 'lightgray',
      'border-radius': '10px',
      'margin-bottom': '25px'
    }"
  >
  </ngx-skeleton-loader>
  <ngx-skeleton-loader
        [theme]="{
          width: '80px',
          'border-radius': '5px',
          height: '20px',
          'background-color': 'lightgray',
          'margin-bottom': '10px'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{
          width: '150px',
          'border-radius': '5px',
          height: '15px',
          'background-color': 'lightgray',
          'margin-bottom': '10px'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{ width: '120px', 'border-radius': '5px', 'background-color': 'lightgray', height: '15px' }"
      >
      </ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{
          width: '100px',
          'border-radius': '5px',
          height: '40px',
          'background-color': 'lightgray',
          'margin-bottom': '10px'
        }"
      ></ngx-skeleton-loader>
</div>