<div class="modal fade-modal" id="deliverSelectorModal">
  <div class="modal-dialog show2">
    <div class="modal-content">
      <div class="modal-body modal-contenido">
        <a (click)="closeModal()" class="modal-close">
          <img src="../../../assets/icons/header/clear.png" alt="Icono de cerrar" class="icon-button icon-img" />
        </a>
        <div class="col-title">
          <p class="title h3 mb-5">¿Cómo deseas realizar tu pedido?</p>
        </div>
        <h4 class="subtitle">Antes de realizar tu compra, por favor, indícanos cómo deseas realizarla.</h4>
        <div class="form-options">
          <label *ngIf="isPlan3000()" class="deliver-option-label" for="radioInStore" (mouseenter)="activeInStore = true" (mouseleave)="activeInStore = false" (click)="goToInStore()">
            <img class="img-label" [hidden]="!activeInStore" src="/assets/imgs/img-address/recoger-on.png" alt="Icono de recoger en tienda">
            <img class="img-label" [hidden]="activeInStore" src="/assets/imgs/img-address/recoger-off.png" alt="Icono de recoger en tienda">
             Recoger en punto de venta
          </label>
          <label
            class="deliver-option-label"
            for="radioShipping"
            (mouseenter)="activeShipping = true"
            (mouseleave)="activeShipping = false"
            (click)="goToShipping()"
          >
            <img
              class="img-label"
              [hidden]="!activeShipping"
              src="/assets/imgs/img-address/domicilio-on.png"
              alt="Icono de recoger en tienda"
            />
            <img
              class="img-label"
              [hidden]="activeShipping"
              src="/assets/imgs/img-address/domicilio-off.png"
              alt="Icono de recoger en tienda"
            />
            Envío a domicilio
          </label>
        </div>
        <!-- <button class="btn btn-primary float-right mt-4" (click)='nextModal()'>Siguiente</button> -->
        <!-- Button which close this modal -->
        <button
          [hidden]="true"
          id="closeDeliverSelectorModal"
          data-dismiss="modal"
        ></button>
      </div>
    </div>
  </div>
</div>
