<div class="modal fade" id="changeShippingMethodModal">
    <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-body modal-contenido">
              <a data-dismiss="modal" class="modal-close">
                <i class="fas fa-times"></i>
              </a>
              <div class="col-title">
            <h3 class="title h3 mb-5">¿Quieres cambiar la modalidad de tu pedido?</h3>
          </div>
          <div class="mid-section">
              <div class="body-div">
                  <img class="body-img" src="/assets/imgs/img-address/ilustracion-1.png" alt="Ilustración de un carrito de compras">
                  <div class="text">
                      Debemos vaciar el carrito ya que la disponibilidad de algunos productos depende de la tienda. ¿Estás seguro de cambiar la modalidad de entrega?
                  </div>
              </div>
          </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary confirm-btn" (click)='modificarModalidad()'>Modificar modalidad</button>
        </div>
      </div>
    </div>
  </div>
  