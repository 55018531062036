import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/interfaces/products.interface';

@Component({
  selector: 'app-product-info-with-quantity',
  templateUrl: './product-info-with-quantity.component.html',
  styleUrls: ['./product-info-with-quantity.component.scss']
})
export class ProductInfoWithQuantityComponent implements OnInit {

  @Input() product: Product;
  @Input() state;
  @Input() public checkout: boolean;
  constructor() { }

  ngOnInit(): void {}

}
