import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { GeolocationService } from 'src/app/core/services/geolocation/geolocation.service';
import * as $ from 'jquery';
import { SalePoint } from 'src/app/interfaces/salePoints.interface';

@Component({
  selector: 'app-address-map',
  templateUrl: './address-map.component.html',
  styleUrls: ['./address-map.component.scss'],
})
export class AddressMapComponent implements OnInit {
  @Input() salePoints: SalePoint[];
  @Input() pointGPS: any;
  @Input() address: any;
  @Output() salePointData = new EventEmitter();
  marker = {
    options: {
      icon: '../../../../assets/imgs/img-address/casa-mapa.png',
    },
  };
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  constructor(private geolocationService: GeolocationService) { }

  ngOnInit(): void { }

  /**
   * Update the location of point according to the map
   */
  changeCenter() {
    let coordinateCenter = JSON.parse(JSON.stringify(this.map.getCenter()));
    this.pointGPS = coordinateCenter;
  }

  /**Validate the position in the map */
  async validatePosition() {
    let res: any;
    if (this.address.ciudad && this.address.ciudad.includes('Rodadero')) {
      let rodaderoSalePoint = this.salePoints.filter(sp => sp.nombre.includes('Rodadero'))[0];
      res = { coverage: true, salePointInfo: rodaderoSalePoint }
    } else {
      console.log("puntos GPS", this.pointGPS)
      console.log("puntos GPS", this.salePoints)
      res = await this.geolocationService
        .validateLocationMap(this.pointGPS, this.salePoints)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error;
        });
    }
    res.pointGPS = this.pointGPS;
    console.log("Coverage", res);
    await this.salePointData.emit(res);
    $('#closeMapCovarage')?.click();
  }
}
