import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ver-recetas',
  templateUrl: './ver-recetas.component.html',
  styleUrls: ['./ver-recetas.component.scss']
})
export class VerRecetasComponent implements OnInit {

  @Input() rec: any;

  constructor() { }

  ngOnInit(): void {
    /** */
  }

  goToRececta(): void {
    /** */
  }


}
