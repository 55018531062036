<ul class="nav nav-tabs">
  <li
    *ngFor="let tab of tabs"
    (click)="selectTab(tab)"
    class="tab-title"
    [class.active]="tab.active"
  >
  {{tab.title}}
  </li>
</ul>
<div class="content">
<ng-content></ng-content>
</div>
