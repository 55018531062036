
  <div class="our-store-main-container">
    <app-header></app-header>
    <app-mobile-header></app-mobile-header>
    <div class="dashboard-body">
      <app-top-banner></app-top-banner>
      <div *ngIf="!mainLoading && renderList.length > 0">
        <div class="bogota-salepoints" *ngFor="let item of renderList">
          <h2>{{ item.ciudad }}</h2>
          <div class="grid-container-bogota">
            <app-products *ngFor="let salepoint of item.puntosDeVenta" [product]="salepoint"></app-products>
          </div>
        </div>
      </div>
      <div *ngIf="mainLoading || (!mainLoading && renderList.length <= 0)">
        <app-product-container-loader ></app-product-container-loader>
      </div>
      <app-pagination [itemsPerPage]="itemsPerPage" [totalItems]="totalItems" [currentPage]="currentPage" (redirectToPageEmitter)="onGoToPage($event)" (nextPageEmitter)="nextPage()" (previousPageEmitter)="previousPage()"></app-pagination>
    </div>
  </div>

