import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import $ from 'jquery';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
import { User } from 'src/app/interfaces/user.interface';

declare var $: $;

@Component({
  selector: 'app-deliver-selector',
  templateUrl: './deliver-selector.component.html',
  styleUrls: ['./deliver-selector.component.scss'],
})
export class DeliverSelectorComponent implements OnInit {
  @Output('openModal') modalEmmitter = new EventEmitter();

  @Input('user') user;

  activeInStore: boolean = false;
  activeShipping: boolean = false;
  shippingMethod: any;
  citySelected: any;
  salePoint: any;

  constructor( private localStorage: LocalstorageService) {}

  ngOnInit(): void {
    this.shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod'));
    this.citySelected = JSON.parse(this.localStorage.getItem('citySelected'));
  }

  goToInStore(): void {
    this.modalEmmitter.emit('#openSalePointSelector');
    this.closeModal();
  }

  goToShipping(): void {
    console.log(this.user);
    if (
      this.user &&
      this.user.direcciones &&
      this.user.direcciones.length > 0
    ) {
      console.log('1');
      this.modalEmmitter.emit('#openAddressListModal');
    } else {
      this.modalEmmitter.emit('#openCovarageFormModal');
      console.log('2');
    }
    this.closeModal();
  }

  closeModal(): void {
    $('#closeDeliverSelectorModal')?.click();
  }

  /**
   * Verify if the actual account is Plan 3000
   * @returns if the account is plan 3000
   */
  public isPlan3000(){
    var isPlan3000 = true
    var user = JSON.parse(this.localStorage.getItem('user')) as User;
      if(user){
        if(user.idListaPrecios && user.idPlan3000){
          isPlan3000 = false
        }
      }
    return isPlan3000
  }
}
