import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface options {
  value: string;
  name: boolean;
}

@Component({
  selector: 'app-order-button',
  templateUrl: './order-button.component.html',
  styleUrls: ['./order-button.component.scss']
})
export class OrderButtonComponent implements OnInit {

  public orderFilterVisible: boolean = false;

  // Options list
  @Input() options: string[] = [];
  // Option selected emitter
  @Output() optionEmitter = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

}
