<div class="navbar-container">
  <div class="container-fluid">
    <div class="logo-calypso">
      <img alt="logo-calypso" src="../../../assets/icons/header/calypso-logo-renew.svg" class="img"
        (click)="navigate('/')" />
    </div>
    <div class="row w-100 h-100 align-items-center">
      <div class="col-6">
        <div class="form-group">
          <div class="icon-in">
            <i class="fas fa-search"></i>
          </div>
          <input (focus)="navigateCategory({ nombre: 'busqueda', slug: 'busqueda' })" type="text" class="form-control"
            placeholder="Busca un producto" />
        </div>
      </div>
      <div class="col-6 d-flex buttons-header">
        <div class="col-address d-flex align-items-center justify-content-center">
          <p class="address m-0" (click)="openAddressModal()" *ngIf="
                  activatedShippingMethod &&
                  activatedShippingMethod.type === 'Domicilio' &&
                  activatedShippingMethod.address
                ">
            <i class="fas fa-map-marker-alt"></i>
            {{ activatedShippingMethod.address?.nomenclatura }}
            {{ activatedShippingMethod.address?.numeroUno }} #{{ activatedShippingMethod.address?.numeroDos }}
            -
            {{ activatedShippingMethod.address?.numeroTres }}
            <img src="../../../assets/icons/header/keyboard_arrow_down.svg" alt="flecha indicadora">
            <span *ngIf="coverage === 'NO'">Sin cobertura</span>
          </p>
          <p class="address m-0" (click)="openAddressModal()"
            *ngIf="activatedShippingMethod && activatedShippingMethod.type === 'Recogida'">
            <i class="fas fa-map-marker-alt"></i>
            <span *ngIf="!activatedShippingMethod.salePoint.distribuidor"> Recoger en:</span>
            {{activatedShippingMethod.salePoint.nombre}}
            <img src="../../../assets/icons/header/keyboard_arrow_down.svg" alt="flecha indicadora">
          </p>
          <p class="address m-0" (click)="openAddressModal()" *ngIf="
                  !activatedShippingMethod ||
                  (activatedShippingMethod.type === 'Domicilio' && !activatedShippingMethod.address)
                ">
            <i class="fas fa-map-marker-alt"></i>
            Método de entrega
            <img src="../../../assets/icons/header/keyboard_arrow_down.svg" alt="flecha indicadora">
          </p>
        </div>
        <div class="cart">
          <button type="button" class="btn btn-cart" (click)="onDeployShoppingCart()">
            <!-- <i class="fas fa-shopping-basket icon-button"></i> -->
            <img src="../../../assets/icons/header/shopping_cart.svg" alt="logo carrito">
            Carrito
            <div *ngIf="productsNumber > 0" class="number-products">
              {{ productsNumber }}
            </div>
          </button>
          <button type="button" class="btn btn-account" (click)="navigate('/login')">
            <i class="fas fa-user-alt icon-button"></i>
            Cuenta
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="header-tabs">
    <button class="btn btn-tabs" [ngClass]="{ 'active-tab': tiendaTab }" type="button" data-toggle="collapse"
      data-target="#navbarToggleStoreContent" aria-controls="navbarToggleStoreContent" aria-expanded="false"
      (click)="collapseTab('tienda')">
      <img src="../../../assets/icons/header/chicken_leg.svg" alt="icono tiendas">
      Tienda
      <img class="indicator-arrow" src="../../../assets/icons/header/keyboard_arrow_down.svg" alt="flecha indicadora">
    </button>
    <button class="btn btn-tabs" [ngClass]="{ 'active-tab': recetasTab }" type="button" data-toggle="collapse"
      data-target="#navbarToggleRecipesContent" aria-controls="navbarToggleRecipesContent" aria-expanded="false"
      (click)="collapseTab('recetas')">
      <img src="../../../assets/icons/header/heating_pot.svg" alt="icono recetas">
      Recetas
      <img class="indicator-arrow" src="../../../assets/icons/header/keyboard_arrow_down.svg" alt="flecha indicadora">
    </button>
    <button class="btn btn-tabs" [ngClass]="{ 'active-tab': conocenosTab }" type="button" data-toggle="collapse"
      data-target="#navbarToggleConocenosContent" aria-controls="navbarToggleConocenosContent" aria-expanded="false"
      (click)="collapseTab('conocenos')">
      <img src="../../../assets/icons/header/calypso_header_icon.svg" alt="icono conocenos">
      Conócenos
      <img class="indicator-arrow" src="../../../assets/icons/header/keyboard_arrow_down.svg" alt="flecha indicadora">
    </button>
    <button (click)="navigate('/nuestras-tiendas')" class="btn btn-tabs">
      <img src="../../../assets/icons/header/store_icon.svg" alt="icono nuestas tiendas">
      Nuestras tiendas
    </button>
    <button class="btn btn-tabs" (click)="redirectTo('/company-information/nuestras-marcas')">
      <img src="../../../assets/icons/header/brand_icon.svg" alt="icono marcas">
      Nuestras marcas
    </button>
  </div>

  <div class="collapse" id="navbarToggleStoreContent">
    <div class="storeContent-container">
      <h2>Selecciona una categoría para continuar</h2>
      <div id="multiColumn">
        <ul>
          <ng-container *ngFor="let category of categories">
            <li>
              <div class="storeCard-container" (click)="redirectStore(category.slug)">
                <img [src]="category.img" [alt]="category.nombre">
                <p>{{ category.nombre }}</p>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>

  <div class="collapse" id="navbarToggleRecipesContent">
    <div class="storeContent-container">
      <h2>Selecciona una categoría para continuar</h2>
      <div id="multiColumn">
        <ul>
          <div *ngFor="let recipe of recipeCategories; let i = index" (click)="updateSelectedRecipe(recipe.nombre)">
            <li>
              <div class="storeCard-container" (click)="redirectRecipe(recipe.nombre)">
                <img [src]="recipe.url" [alt]="recipe.nombre">
                <p>{{ recipe.nombre }}</p>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>

  <div class="collapse" id="navbarToggleConocenosContent">
    <div class="storeContent-container">
      <h2>Selecciona una categoría para continuar</h2>
      <div class="row">
        <ng-container *ngFor="let item of conocenosContent">
          <div class="col-4">
            <div class="conocenosCard-container" (click)="navigate('/company-information/' + item.slug)">
              <img [src]="item.image" [alt]="item.nombre">
              <p>{{ item.nombre }}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
