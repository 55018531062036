import { Component, OnInit, PLATFORM_ID, Inject, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceRecetasService } from 'src/app/core/services/recetas/service-recetas.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart/shopping-cart.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Product } from 'src/app/interfaces/products.interface';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-receta',
  templateUrl: './receta.component.html',
  styleUrls: ['./receta.component.scss']
})
export class RecetaComponent implements OnInit {
  /** Id inicial del producto */
  idProd = 0;
  /** Modal inicial */
  modal = false;
  /** Get the brand width */
  receta: any;
  recetasRecomendadas: any[] = [];
  cantidadComensales = 1;
  idposImg = 0;

   /** Get width year button */
   public containerWidth: number;
   /** Saved scroll left position */
   public scrollLeft = 0;
   /** Get body container width */
   public bodyWidth: number;
   /** Get year button container width */
   public itemWidth: number;


  @ViewChild('scrollable') scrollable!: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platFormId,
    private activatedRoute: ActivatedRoute,
    private recetasService: ServiceRecetasService,
    private cartService: ShoppingCartService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private productsService: ProductsService,
    private cd: ChangeDetectorRef,
    protected serviceRecetasService: ServiceRecetasService,
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platFormId)) {
      setTimeout(() => {
        this.containerWidth = 400;
        this.bodyWidth = document.getElementById('sugestedRecipes' + 'i')?.offsetWidth;
      }, 3000);
    }
    const snapshot = this.activatedRoute.snapshot;
    this.recetasService.getReceta(snapshot.paramMap.get('id')).then(res => {
      res.forEach(element => {
        this.receta = element.data();
        this.getRecomendations(this.receta);
        const pd = [];
        this.receta.productos.forEach(elementPro => {
          let p: Product;
          this.productsService.getProductBySlug(elementPro.slug).subscribe(resPro => {
            p = resPro;
            pd.push(p);
          });
        });
        this.receta.productos = pd;
      });
    });
  }

  getRecomendations(categorie) {
    this.recetasService.getRecomendadas(categorie.categoria).then(res => {
      const tempArr = [];
      res.forEach(element => {
        if (element.data().estado !== 'Inactiva') {
          tempArr.push(element.data());
        }
      });
      this.recetasRecomendadas = tempArr.filter(receta => receta.id !== categorie.id);
    });
  }
  /**
   * Metodo para ir atras las flechas
   */
  atras(): void {
    console.log(this.idProd);
    if (this.idProd - 1 < 0) {
      this.idProd = 0;

    } else {
      this.idProd = this.idProd - 1;
    }
  }
  /**
   * Metodo para adelantar las flechas
   */
  adelante(): void {
    this.idProd = this.idProd + 1;
  }
  /**
   * Metodo para seleccionar imagen
   * @param id id de la posicion
   */
  seleccionarImg(id): void {
    this.idposImg = id;
  }
  /**
   * Metodo para restar imagen
   * @param id id de la posicion
   */
  restarImg(id): void {
    if (id === 0) {
      this.idposImg = 0;
    } else {
      this.idposImg = id - 1;
    }
  }
  /**
   * Metodo para sumar imagen
   * @param id id de la posicion
   */
  sumarImg(id): void {
    if (id >= this.receta.fotoSecundaria.length - 1) {
      this.idposImg = id;
    } else {
      this.idposImg = id + 1;
    }
  }
  /**
   * Metodo para volver
   */
  backRecetas(): void {
    this.router.navigate(['/recetas']);
  }
  /**
   * Metodo para sumar cantidades Comensales
   */
  sumar(): void {
    this.cantidadComensales++;
  }
  /**
   * Metodo para restar cantidades Comensales
   */
  restar(): void {
    if (this.cantidadComensales > 1) {
      this.cantidadComensales--;
    }
  }
  /**
   * Metodo para agregar al carrito
   */
  placeProductsInCart(): void {
    this.modal = false;
    console.log(this.receta.productos);
    const producto = [];
    for (const item of this.receta.productos) {
      item.quantity = 1;
      item.add = true;
      producto.push(item);
    }
    console.log(producto);

    this.cartService.cleanAndAddProducts(producto);
    this.cartService.changeDeployShoppingCart({
      data: { success: true },
    });
  }

  /**
   * Metodo para cargar video
   * @param url ruta del viedeo.
   */
  getVideoIframe(url): SafeResourceUrl {
    let video: string;
    let results: any[];
    if (url === null) {
      return '';
    }
    results = url.match('[\\?&]v=([^&#]*)');
    video = (results === null) ? url : results[1];
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video);
  }

  redirectRecipe(categorie): void {
    this.router.navigate(['/recetas']);
    this.serviceRecetasService.setRecipe(categorie);
  }

  /**
 * Scrolling to category container. Scroll to right
 * @param id DOM Element id
 */
  onScrollRight(id: string) {
    if (isPlatformBrowser(this.platFormId)) {
      const element = document.getElementById(id);
      element.scrollLeft += this.containerWidth;
      this.scrollLeft = element.scrollLeft;
    }
  }

  /**
   * Scrolling to category container. Scroll to left
   * @param id DOM Element id
  */
  onScrollLeft(id: string) {
    if (isPlatformBrowser(this.platFormId)) {
      const element = document.getElementById(id);
      element.scrollLeft -= this.containerWidth;
      this.scrollLeft = element.scrollLeft;
    }
  }
}
