<!-- Modal -->
<div class="modal fade" id="questionsModal" tabindex="-1" role="dialog" aria-labelledby="questionsTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="close-div">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
            <img src="../../../assets/icons/home-main/pregunta.png" alt="Signo de pregunta" class="question-icon">
            <h2 class="modal-title" id="questionsTitle">Queremos saber más de ti</h2>
            <p class="question">{{preguntas[questionId]?.question}}</p>
            <div class="row answers">
                <div *ngFor="let answer of preguntas[questionId]?.answers">
                    <button  class="ans-button" (click)="answerQuestion(preguntas[questionId], answer)">{{ answer }}</button>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Raspa-Gana Mundialista -->
  <div class="modal fade" id="raspaganaModal" tabindex="-1" role="dialog" aria-labelledby="raspaganaTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content modal-image">
          <div class="close-div">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <em class="fas fa-times"></em>
            </button>
          </div>
          <div class="modal-body" (click)="navigate('/categories/todos')">
            <img src="../../../assets/imgs/img-about/pauta-manizales.png" alt="">
          </div>
      </div>
    </div>
  </div>

  <!-- Modal cambio de cobertura -->
  <div class="modal fade" id="coverageChangeModal" aria-labelledby="raspaganaTitle" >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="close-div">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <em class="fas fa-times"></em>
          </button>
        </div>
        <div class="modal-body">
            <img src="../../../../assets/icons/alarm.png" alt="Signo de pregunta" class="question-icon">
            <h3 class="modal-title-raspagana" id="raspaganaTitle" style="font-size: 24px; font-weight: 700;">¡Te informamos que se ha actualizado la cobertura de nuestro punto de venta!</h3>
            <p class="question" style="padding: 5px;">Pero espera... Tenemos otras alternativas para que recibas nuestros productos.</p>
            <div class="btns-alternatives">
              <button  class="ans-button-coverage" (click)="openModal('store')">
                <img src="../../../../assets/icons/business.png" alt="Signo de pregunta" class="question-icon">
                <p class="question-answer">Recoger en tienda.</p>
              </button>
              <button  class="ans-button-coverage" (click)="openModal('delivery')">
                <img src="../../../../assets/icons/real-estate.png" alt="Signo de pregunta" class="question-icon">
                <p class="question-answer">Cambiar dirección.</p>
              </button>
              <button  class="ans-button-coverage" (click)="openModal('#wpp-info-segmentation')" data-toggle="modal" data-target="#wpp-info-segmentation">
                <img src="../../../../assets/icons/home-main/icon-WP.png" alt="Signo de pregunta" class="question-icon">
                <p class="question-answer">Canal de ayuda.</p>
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>