import {
  Component,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { Product } from 'src/app/interfaces/products.interface';
import { CurrencyPipe, isPlatformBrowser } from '@angular/common';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart/shopping-cart.service';
import $ from 'jquery';
import { Title } from '@angular/platform-browser';
import { SeoService } from 'src/app/core/services/seo.service';
import { ShippingMethodType } from 'src/app/interfaces/salePoints.interface';
import { User } from 'src/app/interfaces/user.interface';
import { getPricePerPound } from 'src/app/global/utils';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
declare var $: $;
@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit, OnDestroy {
  /**Category */
  category: string;
  /**Saved cost by gram */
  costForGram: number;
  /**Nutri info exists */
  nutriInfo: boolean = false
  /**Params observable */
  paramsObservable: Subscription;
  /**Product */
  product: Product;
  /**Logged user */
  user: User;
  /**Quantity of product (B2B clients)*/
  public quantityProduct: number;

  public checkProductIcon: boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private productService: ProductsService,
    private router: Router,
    private shoppingCartService: ShoppingCartService,
    private title: Title,
    private seo: SeoService,
    private currencyPipe: CurrencyPipe,
    private localStorage: LocalstorageService,
  ) {
    this.title.setTitle('Productos | Calypso del Caribe');
    this.product = this.activatedRoute.snapshot.data.productdata;
    this.seo.setSEO({
      title: this.product.nombre,
      description: this.product.descripcion || `Descripción: \n \t ${this.product.nombre} \n\t\t Peso: ${this.product.pesoGramos} \n\t\t Precio: ${this.currencyPipe.transform(this.product.precio, "USD", "symbol", "1.0-0")}`,
      image: this.product.imagen,
      url: this.product.slug,
      main: false
    });
    this.seo.setProductInfo(this.product);
    this.getProduct();
  }

  ngOnInit(): void {
    this.user = JSON.parse(this.localStorage.getItem('user')) as User;
    // Initialize the value of the control with the value of product.quantity if greater than 0
    this.quantityProduct = this.product.quantity > 0 ? this.product.quantity : null;
  }

  ngOnDestroy(): void {
    this.seo.clearProductInfo();
    this.paramsObservable?.unsubscribe();
  }

  /**
   * Get product according to name from firebase database
   */
  async getProduct() {
    this.activatedRoute.paramMap.subscribe((data: any) => this.category = data.params.category);
    let cobertura = '';
    let user: User
    cobertura = this.localStorage.getItem('cobertura');
    user = JSON.parse(this.localStorage.getItem('user')) as User;
    if (cobertura === 'SI' || cobertura === 'SIN-SESION') this.product.cobertura = true;

    this.product = this.productService.aplicaCambioPrecio(this.product);

    if (user) {
      if (user.idListaPrecios && this.product.listaPrecios) {
        if (user.idListaPrecios in this.product.listaPrecios) {
          this.product.precio = this.product.listaPrecios[user.idListaPrecios]
        }
      }
    }

    this.product.add = false;
    this.product.quantity = 0;
    // check if it is the shopping cart
    this.checkCart();

    this.getCostForGram(this.product);
    this.productService.onSendCategoryParam(this.product.categoria);
    if (this.product.infoNutricional !== undefined && this.product.infoNutricional !== null && this.product.infoNutricional.length >= 1) {
      this.nutriInfo = true;
    }
  }

  /**
   * Calculated the cost by gram for a product
   * @param product Order product
   */
  getCostForGram(product: Product) {
    this.costForGram = parseFloat((product.precio / product.pesoGramos).toFixed(2));
  }

  /**
   * Returned to the category
   * @param category Products category
   */
  backCategory(categoryParam: string) {
    this.router.navigate(['/categories', categoryParam]);
  }

  /**
   * Check products of the shooping cart
   * if match update the product in the services for the ref.
   * @memberof ProductComponent
   */
  public checkCart() {
    let cartLocalStorage = JSON.parse(this.localStorage.getItem('cart'));
    if (cartLocalStorage && cartLocalStorage.length > 0) {
      for (let i = 0; i < cartLocalStorage.length; i++) {
        const productCart = cartLocalStorage[i];
        if (productCart.id === this.product.id) {
          this.product.add = true;
          this.product.quantity = productCart.quantity;
          // update product in services
          this.shoppingCartService.addCartAgain(this.product);
        }
      }
    }
  }

  /**
   * Add unit product and update product in the services.
   * @memberof ProductComponent
   */
  public addProduct() {
    let shippingMethod = null;
    let cobertura = '';
    shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod'));
    cobertura = this.localStorage.getItem('cobertura');
    if (cobertura === 'SIN-SESION') {
      this.openAddressModal();
      return;
    }

    if (cobertura === 'SI') {
      let bodega;
      switch (shippingMethod.type) {
        case ShippingMethodType.IN_STORE:
          bodega = shippingMethod.salePoint.bodega;
          break;
        case ShippingMethodType.SHIPPING:
          bodega = shippingMethod.address.inventario;
          break;
        default:
          break;
      }
      if (this.product.inventarioBodega[bodega] >= this.product.quantity + 1) {
        this.product.quantity++;
        this.product.add = true;
        this.shoppingCartService.addProductsCart(this.product);
      } else {
        alert('No tenemos disponible este producto');
      }
    }
  }

  /**
   * Add one more of the product
   * @memberof ProductComponent
   */
  public async moreOneProduct() {
    let shippingMethod = null;
    let cobertura = '';
    shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod'));
    cobertura = this.localStorage.getItem('cobertura');
    if (cobertura === 'SIN-SESION') {
      this.openAddressModal();
      return;
    }

    if (cobertura === 'SI') {
      let bodega;
      switch (shippingMethod.type) {
        case ShippingMethodType.IN_STORE:
          bodega = shippingMethod.salePoint.bodega;
          break;
        case ShippingMethodType.SHIPPING:
          bodega = shippingMethod.address.inventario;
          break;
        default:
          break;
      }
      if (this.product.inventarioBodega[bodega] >= this.product.quantity + 1) {
        this.product.quantity++;
        this.shoppingCartService.addProductsCart(this.product);
      } else {
        alert('Inventario no disponible');
      }
    }
  }

  /**
   * Remove one unit the product
   * If quantity is 0 put add false for remove + -
   * @memberof ProductComponent
   */
  public removeProduct(): void {
    this.product.quantity--;
    if (this.product.quantity === 0) {
      this.product.add = false;
    }
    this.shoppingCartService.addProductsCart(this.product);
  }

  addQuantityProduct() {
    if (this.quantityProduct > 0) {
      this.product.quantity = this.quantityProduct;
      this.product.add = true;
      this.shoppingCartService.addProductsCart(this.product);
      this.checkProductIcon = true;
    }
  }

  removeQuantityProduct() {
    this.product.quantity = 0;
    this.product.add = false;
    this.shoppingCartService.addProductsCart(this.product);
  }

  /**
   * Open the address modal
   */
  openAddressModal() {
    $('#openDeliverySelector')?.click();
  }

  pricePerPound(price: number, weightGramsVariable: number, weightGrams: number) {
    return getPricePerPound(price, weightGramsVariable, weightGrams);
  }
  changeQuantityProduct() {
    this.checkProductIcon = false;
  }

  validateInput(event: any) {
    const input = event.target.value;
    if (input.length > 3) {
      event.target.value = input.slice(0, 3);
    }
    this.quantityProduct = Number(event.target.value);
    this.changeQuantityProduct();
  }
}
