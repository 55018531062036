<div
  class="modal fade"
  id="addAddressModal"
  aria-labelledby="addAddressModalLabel"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="col- col-title">
          <p class="title h3">Confirma tu dirección e indicaciones</p>
        </div>
        <div class="row row-address">
          <div class="col-1">
            <i class="fas fa-map-marker-alt"></i>
          </div>
          <div
            class="col-11"
            *ngIf="address !== undefined && indicationsView !== undefined"
          >
            <p class="text h5">Dirección seleccionada</p>
            <p class="text">
              {{ address.nomenclatura }} {{ address.numeroUno }} #{{
                address.numeroDos
              }}
              - {{ address.numeroTres }}
            </p>
            <p class="text">{{ indicationsView }}</p>
          </div>
        </div>
        <form
          [formGroup]="addAddressForm"
          (ngSubmit)="addAddress(addAddressForm.value)"
        >
          <div class="row col-form">
            <div class="col-12">
              <label for="name" class="labels h6"
                >Indicaciones adicionales</label
              >
              <input
                type="text"
                class="form-control"
                id="indications"
                placeholder="Eje: Apto 402, casa 10"
                formControlName="indications"
              />
            </div>
            <div class="col-12">
              <label class="labels h6">Etiqueta</label>
              <select
                class="form-control city"
                id="label"
                formControlName="label"
              >
                <option selected>Selecciona</option>
                <option>Casa</option>
                <option>Oficina</option>
                <option>Madre ó Padre</option>
                <option>Hijo ó Hija</option>
                <option>Pareja</option>
                <option>Otro</option>
              </select>
              <div class="icon-down-div">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
          <div class="row row-btn">
            <div class="col-4 col-before">
              <p
                class="before h5"
                data-toggle="modal"
                data-target="#mapCoverageModal"
                data-dismiss="modal"
              >
                <i class="fa fa-chevron-left"></i>

                Volver
              </p>
            </div>
            <div class="col-5 col-btn">
              <button
                type="submit"
                class="btn btn-primary btn-continue h5"
                [disabled]="!addAddressForm.valid || clicRequest"
              >
                Agregar dirección
              </button>
              <!-- Button which close this modal -->
              <button
                [hidden]="true"
                id="closeMapCovarage"
                data-dismiss="modal"
              ></button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
