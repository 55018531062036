import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuardProfile } from './core/guards/profile/profile.guard';
import { AuthGuardLogin } from './core/guards/login/login.guard';
import { PoliticaDeDatosComponent } from './shared/politica-de-datos/politica-de-datos.component';
import { TerminosYCondicionesComponent } from './shared/terminos-ycondiciones/terminos-ycondiciones.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { RespuestaPseComponent } from './shared/respuesta-pse/respuesta-pse.component';
import { PoliticasGarantiasCambiosDevolucionesComponent } from './shared/politicas-garantias-cambios-devoluciones/politicas-garantias-cambios-devoluciones.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () =>
          import('./home-main/home-main.module').then((m) => m.HomeMainModule),
      },
      {
        path: 'company-information',
        loadChildren: () =>
          import('./company-information/company-information.module').then(
            (m) => m.CompanyInformationModule
          ),
      },
      {
        path: 'vacantes',
        loadChildren: () =>
          import('./jobs/jobs.module').then((m) => m.JobsModule),
      },
      {
        path: 'nuestras-tiendas',
        loadChildren: () =>
          import('./our-stores/our-stores.module').then((m) => m.OurStoresModule),
      },
      {
        path: 'tienda',
        loadChildren: () =>
          import('./categories-detailed/categories-detailed.module').then((m) => m.CategoriesDetailedModule),
      },
      {
        path: 'recetas',
        loadChildren: () =>
          import('./recetas/recetas.module').then((m) => m.RecetasModule),
      },
      {
        path: 'calypso-mundialista',
        loadChildren: () =>
          import('./raspa-gana/raspa-gana.module').then(
            (m) => m.RaspaGanaModule
          ),
      },
      {
        path: 'categories',
        loadChildren: () =>
          import('./categories/categories.module').then(
            (m) => m.CategoriesModule
          ),
      },
      {
        path: 'login',
        canActivateChild: [AuthGuardLogin],
        loadChildren: () =>
          import('./login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'signin',
        canActivateChild: [AuthGuardLogin],
        loadChildren: () =>
          import('./signin/signin.module').then((m) => m.SigninModule),
      },
      {
        path: 'user-profile',
        canActivateChild: [AuthGuardProfile],
        loadChildren: () =>
          import('./user-profile/user-profile.module').then(
            (m) => m.UserProfileModule
          ),
      },
      {
        path: 'forgot-password',
        canActivateChild: [AuthGuardLogin],
        loadChildren: () =>
          import('./forgot-password/forgot-password.module').then(
            (m) => m.ForgotPasswordModule
          ),
      },
      {
        path: 'change-password',
        // canActivateChild: [AuthGuardLogin],
        loadChildren: () =>
          import('./change-password/change-password.module').then(
            (m) => m.ChangePasswordModule
          ),
      },
      {
        path: 'login-phone',
        //canActivateChild: [AuthGuardLogin],
        loadChildren: () =>
          import('./login-phone/login-phone.module').then(
            (m) => m.LoginPhoneModule
          ),
      },
      {
        path: 'checkout',
        canActivateChild: [AuthGuardProfile],
        loadChildren: () =>
          import('./checkout/checkout.module').then((m) => m.CheckoutModule),
      },
      {
        path: 'order',
        canActivateChild: [AuthGuardProfile],
        loadChildren: () =>
          import('./orders/orders.module').then((m) => m.OrdersModule),
      },
      {
        path: 'respuesta-pse',
        component: RespuestaPseComponent,
      },
      {
        path: 'politica-de-datos',
        component: PoliticaDeDatosComponent,
      },
      {
        path: 'terminos-y-condiciones',
        component: TerminosYCondicionesComponent,
      },
      {
        path: 'politicas-garantias',
        component: PoliticasGarantiasCambiosDevolucionesComponent,
      },
      {
        path: '404',
        component: NotFoundComponent,
      },
      {
        path: '**',
        redirectTo: '/404',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
