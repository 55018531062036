import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { from, Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import * as firebase from 'firebase';
import { User } from '../../../interfaces/user.interface';
import { Product, Category } from '../../../interfaces/products.interface';
import { ShippingMethod, ShippingMethodType } from 'src/app/interfaces/salePoints.interface';
import { environment } from 'src/environments/environment.prod';
import * as _ from 'lodash';
import { LocalstorageService } from '../localstorage.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  /**
   * Almacena el usuario
   */
  user: User;

  /**
   * Almacena las categorías que van llegando de Firebase
   */
  categories: Category[] = [];

  /**
   * Almacena las categorías filtradas por Warehouse
   */
  categoriesFiltered: Category[] = [];

  /**
   * Almacena las categorías con todos sus productos que van llegando de BD
   */
  categoriesWithProducts: Category[] = [];

  /**
   * Almacena una lista de todos los productos que van llegando de Firebase
   */
  allProducts: Product[] = [];

  /**
   * Almacena una lista de los productos filtrados por Warehouse
   */
  allProductsFiltered: Product[] = [];

  private subscriber

  /**
   * Observable del que se escuchan los cambios en las categorías
   */
  categoriesObservable: Observable<any>;

  /**
   * Observable del que se escuchan los cambios en los productos
   */
  productsObservable: Observable<any>;

  /**
   * Observable del que se escuchan los cambios en las llaves impositivas
   */
  llavesImpositivasObservable: Observable<any> = this.afdb.object('/config/llavesImpositivas').valueChanges();

  /**
   * Saved the category selectd
   */
  categoryParam: string;
  /**
   * Category Selected subject
   */
  private sendCategoryParamSubject = new Subject<string>();
  /**
   * Category Selected observable
   */
  sendCategoryParamObservable = this.sendCategoryParamSubject.asObservable();
  /**
   * Saved the categor search param
   */
  searchParam: string;
  /**
   * Search param subject
   */
  private sendSearchParamSubject = new Subject<string>();
  /**
   * Search param observable
   */
  sendSearchParamObservable = this.sendSearchParamSubject.asObservable();

  /**
   * Variable para guardar el id de la lista precios para promociones
   */
  private idListaPrecios: string;

  /**
   * Variale para guardar la lista de los TPVS de los lugares en los que aplica una promocion
   */
  private listaTpvs: string[];

  /**
   * Variable para guardar el valor del iva asociado a cada llave impositiva.
   */
  private llavesImpositivas;

  /**
   * Variable para acceder a los productos
   */
  public todosLosProductos = new BehaviorSubject([]);
  todosLosProductos$ = this.todosLosProductos.asObservable();

  /**
   * Se crean inicializan los observables con sus rutas de Realtime Database.
   * Se inician las subscripciones para mantener las categorías y productos actualizados.
   */

  private _appDistribuidor: firebase.app.App

  private productos$ = new Subject<Product[]>();
  private producto$ = new Subject<Product>();
  private isBrowser: boolean;

  // * Nuevas variables para almacenar categorias y productos
  public allProductsResponse: Product[];
  public allCategoriesResponse: Category[];
  public categoriesWithProductsResponse: Category[];

  /** Stores the type of shipment */
  public shippingMethod;
  /** Stores the selected city */
  public citySelected;
  /** Valid if the salepoint is distributor */
  public isDistribuidor;
  /** Almacena el punto de venta asignado al usuario */
  public tpv;

  public cityGroups = [];
  /** Almacena la cobertura de los productos */
  public cobertura = new BehaviorSubject(this.coverageChecked);
  set coverageChecked(value) {
    this.cobertura.next(value);
  }

  get coverageChecked(): string | null {
    if (this.isBrowser) {
      return this.localStorage.getItem('cobertura');
    } else {
      return null;
    }
  }

  constructor(
    private http: HttpClient,
    private afdb: AngularFireDatabase,
    private localStorage: LocalstorageService,
    @Inject(PLATFORM_ID) private platFormId: Object
  ) {
    this.subscribeLlavesImpositivas();
    this.getProductsLists();
    this.isBrowser = isPlatformBrowser(this.platFormId);
    /** 
     * * Prueba definicion de la base de datos para distribuidores
     */

    const storedData = {
      user: this.localStorage.getItem('user'),
      shippingMethod: this.localStorage.getItem('shippingMethod'),
      citySelected: this.localStorage.getItem('citySelected'),
    };

    this.user = storedData.user ? JSON.parse(storedData.user) as User : null;
    this.shippingMethod = storedData.shippingMethod ? JSON.parse(storedData.shippingMethod) : null;
    this.citySelected = storedData.citySelected ? JSON.parse(storedData.citySelected) : null;

    this.isDistribuidor = this.verifyDistribuidor(this.shippingMethod, this.citySelected);

    if (isPlatformBrowser(this.platFormId)) {
      try {
        this._appDistribuidor = this.isDistribuidor
          ? firebase.app()
          : (firebase.apps.find(app => app.name === 'distribuidor') ||
            firebase.initializeApp(
              { databaseURL: "https://qmenu-distribuidores.firebaseio.com/" },
              'distribuidor'
            ));
      } catch (error) {
        console.error("Error initializing Firebase app:", error);
      }
    }
  }

  /**
   * Se subscribe al observable de llaves impositivas para mantener actualizadas las categorías.
   */
  private subscribeLlavesImpositivas(): void {
    this.llavesImpositivasObservable.subscribe((data) => {
      this.llavesImpositivas = data;
      this.subscribeAllProducts();
    });
  }

  /**
   * Asignar las ciudades de las listas de precios
   * @param ciudadesObj
   */
  private assignCities(ciudadesObj: any): void {
    // Asignar las ciudades
    for (const key in ciudadesObj) {
      this.cityGroups.push(ciudadesObj[key]);
    }
  }

  /**
   * Función para inicializar idListaPrecios y listaTpvs para revisar los precios
   */
  private getProductsLists() {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      this.shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod'));
    }
    try {
      // Se trae el TPV del punto de venta
      if (this.shippingMethod?.type === 'Recogida') {
        this.tpv = this.shippingMethod?.salePoint?.tpv;
      } else if (this.shippingMethod?.type === 'Domicilio') {
        let user = JSON.parse(this.localStorage.getItem('user'));
        this.tpv = user?.puntoDeVentaTpv;
      }

      this.afdb.object('/config/GrupoCiudades').valueChanges().subscribe((ciudades) => {
        this.assignCities(ciudades);

        let idListaAsignada: string | undefined;

        if (this.cityGroups.some((ciudad) => ciudad.listaTpvs.includes(this.tpv))) {
          idListaAsignada = this.cityGroups.find((ciudad) => ciudad.listaTpvs.includes(this.tpv))?.idListaPrecios;
        }

        if (idListaAsignada) {
          this.idListaPrecios = idListaAsignada;
          this.listaTpvs = [this.tpv];
        }
      }, (error) => {
        console.error('Error al obtener la lista de ciudades:', error);
      });
    } catch (error) {
      console.error('Error al obtener el TPV:', error);
    }
  }

  /**
   * Función que verifica se han existido cambios en cobertura de puntos de venta
   * @returns
   */
  public getCoverageChanges() {
    return this.afdb
      .list('/config/changeCoverage')
  }

  /**
   * Se subscribe al observable de productos para mantener actualizados los productos.
   * * Prueba definicion de la base de datos para distribuidores
   */
  public async subscribeAllProducts() {
    if (this.isDistribuidor) {
      if (this.subscriber) this.subscriber.unsubscribe()
      //subscriber for distribuidores
      firebase.database(this._appDistribuidor).ref('/productos').on('value', (snapShot) => {
        let prDistri: Product[] = []
        let dataPivot: Product[] = []
        const dataUno = snapShot.val()
        for (let i in dataUno) {
          prDistri.push(dataUno[i] as Product)
        }
        for (let i in prDistri) {
          if (prDistri[i].inventarioBodega[this.citySelected.salePoint.bodega] > 0) {
            dataPivot.push(prDistri[i])
          }
        }
        this.allProducts = dataPivot
        this.todosLosProductos.next(this.allProducts)
      })
    } else {
      firebase.database(this._appDistribuidor).ref('/productos').off();

      const getProductsPromise = this.user && this.user.tipoCuenta === 'B2B'
        ? this.getProductsB2B()
        : this.getAllProductsAPI();

      const apiProducts = await getProductsPromise.then((products) =>
        products.filter((product) => product.listaPrecios !== null).map((product) => this.aplicaCambioPrecio(product))
      );

      let data = apiProducts.filter((dis) => dis.listaPrecios !== null).map((act) => this.aplicaCambioPrecio(act));
      let dataPivot = [];
      if (this.user) {
        data.forEach((el) => {
          if (this.user.idListaPrecios && el.listaPrecios) {
            if (this.user.idListaPrecios in el.listaPrecios) {
              el.precio = el.listaPrecios[this.user.idListaPrecios];
              el = this.modifyValues(el);
            }
          }
        });
        dataPivot = data.filter((el) => {
          if (this.user.idListaPrecios && el.listaPrecios) {
            if (this.user.idListaPrecios in el.listaPrecios) {
              return el;
            }
          }
        });
      }
      if (dataPivot.length == 0) {
        this.allProductsResponse = data;
      } else {
        this.allProductsResponse = dataPivot;
      }
      this.subscriber = data;
    }
  }

  /**
   * Modifica iva y el subtotal de un producto.
   * @param product Producto a modificar
   * @returns producto modificado
   */
  private modifyValues(product: Product): Product {
    if (product.llaveImpositiva && this.llavesImpositivas) {
      const llaveImpositiva = product.llaveImpositiva;
      const iva = this.llavesImpositivas[llaveImpositiva];
      product.subtotal = product.precio / (iva + 1);
      product.iva = product.subtotal * iva;
    }

    return product;
  }
  /**
   * Retorna la promesa de categorías
   */
  public getCategoriesPromise(): Promise<Category[]> {
    return this.categoriesObservable.pipe(first()).toPromise();
  }

  /**
   * Function to verify if the salepoint is distributor
   * @param shippingMethod
   * @param citySelected
   * @returns boolean that indicates if the salepoint is distributor or not
   */
  verifyDistribuidor(shippingMethod, citySelected) {
    var isDistribuidor = false;
    if (shippingMethod) {
      if (
        (shippingMethod.type == 'Recogida' && shippingMethod.salePoint?.distribuidor) ||
        (shippingMethod.type == 'Domicilio' && shippingMethod.address?.cobertura && shippingMethod.address?.inventario.startsWith('DIST'))
      ) {
        isDistribuidor = true
      }
    } else if (citySelected) {
      if (citySelected.salePoint.distribuidor) {
        isDistribuidor = true
      }
    }
    return isDistribuidor
  }

  /**
   * Retorna el observable de la lista de productos de una categoría.
   * @param category El nombre de la categoría de los productos a buscar.
   */
  public getProductsByCategory(category: string): Observable<Product[]> {
    var shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod',));
    var citySelected = JSON.parse(this.localStorage.getItem('citySelected'));
    var isDistribuidor = this.verifyDistribuidor(shippingMethod, citySelected);
    if (isDistribuidor) {
      if (category === 'Destacados') {
        firebase.database(this._appDistribuidor).ref('/productos').orderByChild('destacado').startAt(true).endAt(true).once('value').then(
          (res) => {
            let data = [];
            res.forEach((p) => {
              data.push(p.val() as Product);
            });
            this.productos$.next(data as Product[])
          }
        )
      } else {
        firebase.database(this._appDistribuidor).ref('/productos').orderByChild('categoria').startAt(category).endAt(category).once('value').then(
          (res) => {
            let data = [];
            res.forEach((p) => {
              data.push(p.val() as Product);
            });
            this.productos$.next(data as Product[])
          }
        )
      }
      return this.productos$.asObservable();
    } else {
      if (category === 'Destacados') {
        this.allProductsResponse = this.allProductsResponse.filter((product: any) => product.destacado === true);
      } else {
        this.allProductsResponse = this.allProductsResponse.filter((product: any) => product.categoria === category);
      }
    }
  }
  public getProductsByCategoryAPI(category: string, products: Product[]): Product[] {
    var shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod',));
    var citySelected = JSON.parse(this.localStorage.getItem('citySelected'));
    var isDistribuidor = this.verifyDistribuidor(shippingMethod, citySelected);
    if (isDistribuidor) {
      if (category === 'Destacados') {
        firebase.database(this._appDistribuidor).ref('/productos').orderByChild('destacado').startAt(true).endAt(true).once('value').then(
          (res) => {
            let data = [];
            res.forEach((p) => {
              data.push(p.val() as Product);
            });
            this.productos$.next(data as Product[])
          }
        )
      } else {
        firebase.database(this._appDistribuidor).ref('/productos').orderByChild('categoria').startAt(category).endAt(category).once('value').then(
          (res) => {
            let data = [];
            res.forEach((p) => {
              data.push(p.val() as Product);
            });
            this.productos$.next(data as Product[])
          }
        )
      }
      return this.productos$ as unknown as Product[];
    } else {
      if (category === 'Destacados') {
        const resultCategory = products.filter((product: any) => product.destacado === true);
        return resultCategory;
      } else {
        const resultCategory = products.filter((product: any) => product.categoria === category);
        return resultCategory;
      }
    }

  }

  /**
   * Return the observable for product
   * @param category id product to search.
   */
  public getProductsById(id: string): Observable<Product[]> {
    return this.afdb
      .list<Product>('/productos', (ref) => ref.orderByChild('id').startAt(id).endAt(id))
      .valueChanges();
  }

  /**
   * Filtra los productos para el plan 3000
   * @param products lista de productos
   * @returns
   */
  private plan3000Filter(products) {
    let dataPivot: Product[] = [];
    dataPivot = products.filter((element) => {
      if (this.user.idListaPrecios && element.listaPrecios) {
        if (this.user.idListaPrecios in element.listaPrecios) {
          if (this.user.idListaPrecios in element.listaPrecios) {
            element.precio = element.listaPrecios[this.user.idListaPrecios];
            element = this.modifyValues(element);
          }
          return element;
        }
      }
    });
    return dataPivot;
  }

  public async getCategoriesAndProductsAPI(categories: Category[], products: Product[]): Promise<Category[]> {
    // console.log('getCategoriesAndProductsAPI', categories);
    for (const category of categories) {
      category.productos = this.getProductsByCategoryAPI(category.nombre, products);

      category.productos = category.productos.filter((dis) => dis.listaPrecios !== null).map((act) => this.aplicaCambioPrecio(act));
      let dataPivot: Product[];
      if (this.user && this.user.idListaPrecios) {
        dataPivot = this.plan3000Filter(category.productos);
        category.productos = dataPivot;
      }
    }
    return categories;
  }

  /**
   * Obtiene una categoría según su slug
   * @param slug El slug de la categoría a buscar.
   */
  public getProductBySlug(slug: string): Observable<Product> {
    var shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod',));
    var citySelected = JSON.parse(this.localStorage.getItem('citySelected'));
    var isDistribuidor = this.verifyDistribuidor(shippingMethod, citySelected);
    if (isDistribuidor) {
      firebase.database(this._appDistribuidor).ref('/productos').orderByChild('slug').equalTo(slug).once('value').then(
        (res) => {
          let data;
          res.forEach((p) => {
            data = (p.val() as Product);
          });
          this.producto$.next(data)
        }
      )
      return this.producto$.asObservable().pipe(first());
    } else {
      return this.afdb
        .list<Product>('/productos', (ref) => ref.orderByChild('slug').equalTo(slug))
        .valueChanges()
        .pipe(first())
        .pipe(map((products) => products.pop()));
    }
  }

  /**
   * Retorna el observable de productos aprovechando que ya existen productos almacenados.
   */
  public getAllProducts(): Observable<Product[]> {
    var shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod',));
    var citySelected = JSON.parse(this.localStorage.getItem('citySelected'));
    var isDistribuidor = this.verifyDistribuidor(shippingMethod, citySelected);
    if (isDistribuidor) {
      firebase.database(this._appDistribuidor).ref('/productos').once('value').then(
        (res) => {
          let data = [];
          res.forEach((p) => {
            data.push(p.val() as Product);
          });
          this.productos$.next(data as Product[])
        }
      );
      return this.productos$.asObservable();
    } else {
      return this.todosLosProductos;
    }
  }

  /**
   * Retorna el observable de productos aprovechando que ya existen productos almacenados (API Dapta).
   */
  async getAllProductsAPI(slug?: boolean): Promise<Product[]> {
    const products = this.http.get(`${environment.apiDaptaURL}products_filter`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.apiKey
      }),
    }).pipe(
      map((products: any) => {
        return products.productos;
      })
    );
    if (!slug) {
      this.allProductsResponse = await products.toPromise().then((products) => (products));
    }
    return await products.toPromise().then((products) => (products));
  }

  async getProductsB2B(): Promise<Product[]> {
    const products = this.http.get(`${environment.apiDaptaURL}products_b2b`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.apiKey
      }),
      params: new HttpParams().set('priceList', this.user.idListaPrecios)
    }).pipe(
      map((products: any) => {
        return products;
      })
    );
    return await products.toPromise().then((products) => (products.products));
  }

  getproductBySlugAPI(slug: string): Observable<Product> {
    return from(this.getAllProductsAPI(true)).pipe(
      map(products => products.find(product => product.slug === slug))
    );
  }

  // * CATEGORIAS

  /**
   * Retorna el observable de categorias (API Dapta).
   */
  async getAllCategoriesAPI(): Promise<Category[]> {
    const categories = this.http.get(`${environment.apiDaptaURL}categories`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.apiKey
      }),
    }).pipe(
      map((categories: any) => {
        return categories.categorias;
      })
    );
    this.allCategoriesResponse = await categories.toPromise().then((categories) => (categories));
    return await categories.toPromise().then((categories) => (categories));
  }

  /**
   * Obtiene una categoría según su slug
   * @param slug El slug de la categoría a buscar.
   */
  public getCategoryBySlug(slug: string): Observable<Category> {
    const observable = new Observable<Category>((observer) => {
      const catWithSlug = this.categoriesWithProducts.find((cat) => cat.slug === slug);
      if (catWithSlug) {
        observer.next(catWithSlug);
        observer.complete();
      } else {
        this.afdb
          .list<Category>('/categorias', (ref) => ref.orderByChild('slug').equalTo(slug))
          .valueChanges()
          .pipe(first())
          .subscribe((data) => {
            observer.next(data.pop());
            observer.complete();
          });
      }
    });
    return observable;
  }

  /**
   * Updated product
   * @param product Product
   */
  updateProduct(product): Promise<void> {
    try {
      return this.afdb.object('/productos/' + product.id).update(product);
    } catch (error) {
      console.error(`Error to update user: ${error}`);
    }
  }

  /**
   * Gets the categories filtered by warehouse and inventory
   * @param categories categories WITH products
   * @param shippingMethod Shipping Method
   */
  public filterCategoriesByWarehouse(categories: Category[], shippingMethod: ShippingMethod): Category[] {
    const categoriesRes: Category[] = [];
    let warehouse: string;
    if (!shippingMethod) {
      this.localStorage.setItem('cobertura', 'SIN-SESION');
    } else if (shippingMethod.type === ShippingMethodType.SHIPPING) {
      const address = shippingMethod.address;
      if (address && address.cobertura && address.inventario !== '') {
        warehouse = address.inventario;
        this.localStorage.setItem('cobertura', 'SI');
      } else if (address) {
        this.localStorage.setItem('cobertura', 'NO');
      } else {
        this.localStorage.setItem('cobertura', 'SIN-SESION');
      }
    } else if (shippingMethod.type === ShippingMethodType.IN_STORE) {
      const salePoint = shippingMethod.salePoint;
      if (salePoint && salePoint.bodega !== '') {
        warehouse = salePoint.bodega;
        this.localStorage.setItem('cobertura', 'SI');
      } else {
        this.localStorage.setItem('cobertura', 'SIN-SESION');
      }
    }
    for (const category of categories) {
      let productsByCategory = category.productos;
      let prods = [];
      if (warehouse) {
        productsByCategory = category.productos.filter(
          (product) => product.inventarioBodega !== undefined && product.inventarioBodega[warehouse] > 0
        );

        productsByCategory = productsByCategory.filter((dis) => dis.listaPrecios !== null).map((act) => this.aplicaCambioPrecio(act));
        let dataPivot: Product[];
        if (this.user && this.user.idListaPrecios) {
          dataPivot = this.plan3000Filter(productsByCategory);
          productsByCategory = dataPivot;
        }
      } else {
        let citySelected;
        citySelected = JSON.parse(this.localStorage.getItem('citySelected'));
        prods = [];
        if (citySelected) {
          prods.push(
            ...category.productos.filter((product) => product.inventarioBodega[citySelected.salePoint.bodega])
          )
        } else {
          prods = category.productos;
        }
        productsByCategory = prods.filter((item, index) => {
          return prods.indexOf(item) === index;
        });
      }
      // Creating categories and products
      if (productsByCategory.length > 0) {
        // The categories are created with products
        categoriesRes.push({
          id: category.id,
          index: category.index,
          banner: category.banner,
          img: category.img,
          nombre: category.nombre,
          productos: productsByCategory,
          slug: category.slug,
        });
      }
    }
    this.categoriesFiltered = categoriesRes;
    return categoriesRes;
  }

  /**
   * Gets the products filtered by warehouse and inventory
   * @param products Products to filter
   * @param shippingMethod Shipping Method
   */
  public filterProductsByWarehouse(products: Product[], shippingMethod: ShippingMethod): Product[] {
    let productsRes: Product[] = [];
    let warehouse: string;
    if (!shippingMethod) {
      this.localStorage.setItem('cobertura', 'SIN-SESION');
    } else if (shippingMethod.type === ShippingMethodType.SHIPPING) {
      const address = shippingMethod.address;
      if (address && address.cobertura && address.inventario !== '') {
        warehouse = address.inventario;
        this.localStorage.setItem('cobertura', 'SI');
      } else if (address) {
        this.localStorage.setItem('cobertura', 'NO');
      } else {
        this.localStorage.setItem('cobertura', 'SIN-SESION');
      }
    } else if (shippingMethod.type === ShippingMethodType.IN_STORE) {
      const salePoint = shippingMethod.salePoint;
      if (salePoint && salePoint.bodega !== '') {
        warehouse = salePoint.bodega;
        this.localStorage.setItem('cobertura', 'SI');
      } else {
        this.localStorage.setItem('cobertura', 'SIN-SESION');
      }
    }
    /** se apaga productos distribuidores desde el front mientras esta
     * lista la funcionalidad de apagar distribuidores
     */
    if (warehouse) {
      productsRes = products.filter((product) => {
        let resp = false;
        let inventarioBodega = product.inventarioBodega;
        if (inventarioBodega) {
          resp = inventarioBodega[warehouse] && inventarioBodega[warehouse] > 0;
        }
        return resp;
      });
    } else {
      productsRes = products;
    }
    this.allProductsFiltered = productsRes;
    let dataPivot: Product[];
    productsRes = productsRes.filter((dis) => dis.listaPrecios !== null).map((act) => this.aplicaCambioPrecio(act));
    if (this.user && this.user.idListaPrecios) {
      dataPivot = this.plan3000Filter(productsRes);
      productsRes = dataPivot;
    }
    return productsRes;
  }

  /**
   * Change the search parameter to found product.
   */
  onSendSearchParam(searchParam: string): void {
    this.searchParam = searchParam;
    this.sendSearchParamSubject.next(searchParam);
  }

  /**
   * Change the search parameter to found product.
   */
  onSendCategoryParam(categoryParam: string): void {
    this.categoryParam = categoryParam;
    this.sendCategoryParamSubject.next(categoryParam);
  }

  /**
   * Checks if the order exceeds a certain amount of weight
   * @param products A list containing the products of the order
   * @param weight The weight that wants to be verified
   * @returns true if the order reaches or exceeds the weight, false if not
   */
  public maxWeight = (products: Product[], weight: number) =>
    products.reduce(
      (prev, act) =>
        act.pesoVariable ? prev + act.pesoGramosVariable * act.cantidad : prev + act.pesoGramos * act.cantidad,
      0
    ) >= weight;

  /**
   * Checks if the order exceeds a certain price
   * @param products A list containing the products of the order
   * @param price The price  that wants to be verified
   * @returns true if the order reaches or exceeds the price, false if not
   */
  public maxPrice = (products: Product[], price: number) =>
    products.reduce(
      (prev, act) =>
        act.pesoVariable
          ? prev + (act.pesoGramosVariable * act.precio * act.cantidad) / 1000
          : prev + act.precio * act.cantidad,
      0
    ) >= price;

  /**
   * Funcion para actualizar el precio del producto en caso de que haga parte de alguna promoción
   * @param pProduct producto a revisar
   * @returns el precio del producto original o actualizado
   */
  public aplicaCambioPrecio(pProduct) {
    if (pProduct.distribuidor !== null && pProduct.distribuidor !== undefined) {
      return pProduct;
    }
    if (this.tpv) {
      //Se revisa si el TPV del punto de venta está en la lista que se trae de firebase
      let incluyeTPV = this.listaTpvs?.includes(this.tpv);
      //Se revisa si el producto tiene el atributo listaPrecios
      let tieneListaPrecios = incluyeTPV && 'listaPrecios' in pProduct;
      //Se revisa si dentro de listaPrecios está el atributo que se está buscando, i.e 030
      let tieneIdListaPrecios = tieneListaPrecios && this.idListaPrecios in pProduct.listaPrecios;
      //Se revisa si dicho atributo es tipo number
      let cumpleTodo = tieneIdListaPrecios && typeof pProduct.listaPrecios[this.idListaPrecios] === 'number';
      //Si cumple todas las condiciones, actualiza el precio, si no, deja el precio original
      if (cumpleTodo) {
        let ivaActual = this.llavesImpositivas[pProduct.llaveImpositiva];
        pProduct.subtotal = pProduct.listaPrecios[this.idListaPrecios];
        pProduct.iva = pProduct.subtotal * ivaActual;
        pProduct.precio = pProduct.subtotal + pProduct.iva;
      }
    }
    return pProduct;
  }
}
