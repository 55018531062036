import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

interface cities {
  name: string;
  active: boolean;
}
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnChanges {
  // Filter list controller - Show / hide
  public filterListController: boolean = false;
  // many items
  @Input() totalItems: number = 0;
  // Items per page
  @Input() itemsPerPage: number = 0;
  // Current page
  @Input() currentPage: number = 0;
  // Cities list
  @Input() cities: cities[] = [];
  // Cities filter modal controller
  public citiesFilterModalController: boolean = false;
  // Cities filter output emitter
  @Output() citiesFilterEmitter = new EventEmitter<cities[]>();
  // Inform text
  public informText: string = 'Mostrando 0-0 de 0 resultados'

  constructor() {}

  ngOnInit(): void {    
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.totalItems && this.itemsPerPage && this.currentPage) {
      // Build string to show many items
      const startItem = (this.currentPage - 1) * this.itemsPerPage + 1;
      const endItem = Math.min(this.currentPage * this.itemsPerPage, this.totalItems);

      // Building string
      this.informText = "Mostrando " + startItem + "-" + endItem + " de " + this.totalItems + " resultados";
    }
  }

  onChangeFilter(city: cities, event: any) {
    city.active = event.target.checked;
  }

  /**
   * Hide or shows cities modal
   */
  onActiveCitiesModal() {
    this.citiesFilterModalController = !this.citiesFilterModalController;
  }

  onSubmitFilter() {
    this.citiesFilterEmitter.emit(this.cities);
    this.onActiveCitiesModal();
  }

  onCleanFilters() {
    this.cities.map((item) => (item.active = false));
  }
}
