import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OurStoresRoutingModule } from './our-stores-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { SharedModule } from '../shared/shared.module';
import { TopBannerComponent } from './components/top-banner/top-banner.component';
import { FilterComponent } from './components/filter/filter.component';
import { ProductsComponent } from './components/products/products.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ProductsModule } from '../products/products.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment.prod';

@NgModule({
  declarations: [LayoutComponent, TopBannerComponent, FilterComponent, ProductsComponent, PaginationComponent],
  imports: [
    CommonModule,
    OurStoresRoutingModule,
    SharedModule,
    ProductsModule,
    NgxSpinnerModule,
    NgxSkeletonLoaderModule,
    AgmCoreModule.forRoot({
      apiKey: environment.firebaseConfig.apiKey,
      libraries: ['places', 'drawing', 'geometry'],
    }),
  ],
  exports: [
    PaginationComponent
  ]
})
export class OurStoresModule { }
