import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  // Total items
  @Input() totalItems: number = 0;
  // Total items wants to show per page
  @Input() itemsPerPage: number = 0;
  // Current page
  @Input() currentPage: number = 0;
  // Total pages calculated base in total items and items per page
  public totalPages: number = 0;
  // Array to display pages into pagination
  public displayedPages: number[] = [];
  // Nex page emitter to parent component
  @Output() nextPageEmitter = new EventEmitter();
  // Previous page to parent component
  @Output() previousPageEmitter = new EventEmitter();
  // Exact page to parent component
  @Output() redirectToPageEmitter = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.totalItems) {
      this.totalPages = this.calculateTotalPages(this.totalItems, this.itemsPerPage);
      this.updateDisplayedPages();
    }
  }

  // Calculates total pages
  calculateTotalPages(totalItems: number, itemsPerPage: number): number {
    return Math.ceil(totalItems / itemsPerPage);
  }

  // Updates the array displayed in paginator
  /**
   * Creates the array base in total pages to show paginator
   *
   */
  updateDisplayedPages() {
    // Max pages
    const maxDisplayedPages = 4;
    const halfMax = Math.floor(maxDisplayedPages / 2);
    let startPage = Math.max(1, this.currentPage - halfMax);
    let endPage = Math.min(startPage + maxDisplayedPages - 1, this.totalPages);

    if (endPage - startPage < maxDisplayedPages - 1) {
      startPage = Math.max(1, endPage - maxDisplayedPages + 1);
    }
    // Creates the array base in total pages
    this.displayedPages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  }

  scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  // Goes to next page
  onNextPage() {
    this.nextPageEmitter.emit();
    this.updateDisplayedPages();
    this.scrollToTop();
  }

  // Goes to previous page
  onPreviousPage() {
    this.previousPageEmitter.emit();
    this.updateDisplayedPages();
    this.scrollToTop();
  }

  // Redirects to an specific page
  onRedirectToPage(page: number) {
    if (page === this.currentPage) return
    this.redirectToPageEmitter.emit(page);
    this.updateDisplayedPages();
    this.scrollToTop();
  }
}
