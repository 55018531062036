import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Product } from 'src/app/interfaces/products.interface';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart/shopping-cart.service';
import $ from 'jquery';
import { isPlatformBrowser } from '@angular/common';
import { ShippingMethodType } from 'src/app/interfaces/salePoints.interface';
import { ToastrService } from 'ngx-toastr';
import { getPricePerPound } from 'src/app/global/utils';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
declare var $: $;
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, OnChanges {
  @Input() public product: Product;
  costForGram: number;
  images: string[] = [];
  @Input() public spSelected: any;
  @Input() public home: any;
  @Input() public isB2B: boolean;

  public checkCobertura: boolean;
  public quantityProduct: number;
  public checkProductIcon: boolean = false;

  constructor(
    private router: Router,
    private productService: ProductsService,
    private shoppingCartService: ShoppingCartService,
    private toastr: ToastrService,
    private localStorage: LocalstorageService,
    @Inject(PLATFORM_ID) private platFormId
  ) {
    this.checkCobertura = (this.productService.coverageChecked === 'SI');
  }

  async ngOnInit() {
    if (isPlatformBrowser(this.platFormId)) {
      if (this.productService.coverageChecked === 'SI' || this.productService.coverageChecked === 'SIN-SESION') {
        this.product.cobertura = true;
      } else {
        this.product.cobertura = false;
      }
    }
    // this.product.add = false;
    this.product.quantity = 0;
    this.shoppingCartService.cart.subscribe((productos) => {
      let prodIndex = productos.findIndex((act) => act.id === this.product.id);
      if (prodIndex > -1) {
        this.product.quantity = productos[prodIndex].quantity;
      } else {
        this.product.quantity = 0;
      }
    });
    this.checkCart();
    // Initialize the value of the control with the value of product.quantity if greater than 0
    this.quantityProduct = this.product.quantity > 0 ? this.product.quantity : null;
  }

  /**
   * Returns the url for the product
   */
  public getProductUrl() {
    if (!this.checkCobertura || this.productService.coverageChecked === 'SIN-SESION') {
      this.openAddressModal();
    } else {
      let slugCategoria = this.product.categoria.toLocaleLowerCase();
      slugCategoria = slugCategoria.replace(/ /g, '-');
      this.router.navigate(['/categories', slugCategoria, this.product.slug]);
    }
  }

  /**
   * Check products of the shooping cart
   * if match update the product in the services for the ref.
   * @memberof ProductComponent
   */
  public checkCart() {
    if (isPlatformBrowser(this.platFormId)) {
      let cartLocalStorage = JSON.parse(this.localStorage.getItem('cart'));
      if (cartLocalStorage && cartLocalStorage.length > 0) {
        for (let i = 0; i < cartLocalStorage.length; i++) {
          const productCart = cartLocalStorage[i];
          if (productCart.id === this.product.id) {
            this.product.add = true;
            this.product.quantity = productCart.quantity;
            // update product in services
            this.shoppingCartService.addCartAgain(this.product);
          }
        }
      }
    }
  }

  ngOnChanges(): void {
    this.getCostForGram(this.product);
  }

  /**
   * Calculated the cost by gram for a product
   * @param product Order product
   */
  getCostForGram(product: Product) {
    this.costForGram = parseFloat(
      (product.precio / product.pesoGramos).toFixed(2)
    );
  }

  /**
   * Function which sends the link that enters by parameters
   * @param option
   */
  public navigate(product: Product) {
    let slugCategoria = product.categoria.toLocaleLowerCase();
    slugCategoria = slugCategoria.replace(/ /g, '-');
    this.productService.onSendSearchParam('');
    this.productService.onSendCategoryParam(product.categoria);
    this.router.navigate(['/categories', slugCategoria, product.slug]);
  }

  /**
   * Add unit product and update product in the services.
   * @memberof ProductComponent
   */
  public addProduct() {
    let shippingMethod = null;
    let cobertura = '';
    shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod'));
    cobertura = this.localStorage.getItem('cobertura');
    if (cobertura === 'SIN-SESION') {
      this.openAddressModal();
      return;
    }
    if (cobertura === 'SI') {
      let bodega;
      switch (shippingMethod.type) {
        case ShippingMethodType.IN_STORE:
          bodega = shippingMethod.salePoint.bodega;
          break;
        case ShippingMethodType.SHIPPING:
          bodega = shippingMethod.address.inventario;
          break;
        default:
          break;
      }
      if (this.product.inventarioBodega[bodega] >= this.product.quantity + 1) {
        this.product.quantity++;
        this.product.add = true;
        this.shoppingCartService.addProductsCart(this.product);
      } else {
        this.toastr.error('No tenemos disponible este producto');
      }
    }
  }

  addQuantityProduct() {
    if (this.quantityProduct > 0) {
      this.product.quantity = this.quantityProduct;
      this.product.add = true;
      this.shoppingCartService.addProductsCart(this.product);
      this.checkProductIcon = true;
    }
  }

  removeQuantityProduct() {
    this.product.quantity = 0;
    this.product.add = false;
    this.shoppingCartService.addProductsCart(this.product);
  }

  /**
   * Add one more of the product
   * @memberof ProductComponent
   */
  public async moreOneProduct() {
    let shippingMethod = null;
    let cobertura = '';
    shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod'));
    cobertura = this.localStorage.getItem('cobertura');
    //User without sesion
    if (cobertura === 'SIN-SESION') {
      this.openAddressModal();
      return;
    }
    //User without sesion and coverage
    if (cobertura === 'SI') {
      let bodega;
      switch (shippingMethod.type) {
        case ShippingMethodType.IN_STORE:
          bodega = shippingMethod.salePoint.bodega;
          break;
        case ShippingMethodType.SHIPPING:
          bodega = shippingMethod.address.inventario;
          break;
        default:
          break;
      }
      if (this.product.inventarioBodega[bodega] >= this.product.quantity + 1) {
        this.product.quantity++;
        this.shoppingCartService.addProductsCart(this.product);
      } else {
        alert('Inventario no disponible');
      }
    }
  }

  /**
   * Open the address modal
   */
  openAddressModal() {
    // $('#openAddressListModal').click();
    $('#openDeliverySelector')?.click();
  }

  /**
   * Remove one unit the product
   * If quantity is 0 put add false for remove + -
   * @memberof ProductComponent
   */
  public removeProduct(): void {
    this.product.quantity--;
    if (this.product.quantity === 0) {
      this.product.add = false;
    }
    this.shoppingCartService.addProductsCart(this.product);
  }

  pricePerPound(price: number, weightGramsVariable: number, weightGrams: number) {
    return getPricePerPound(price, weightGramsVariable, weightGrams);
  }

  changeQuantityProduct() {
    this.checkProductIcon = false;
  }

  validateInput(event: any) {
    const input = event.target.value;
    if (input.length > 3) {
      event.target.value = input.slice(0, 3);
    }
    this.quantityProduct = Number(event.target.value);
    this.changeQuantityProduct();
  }
}
