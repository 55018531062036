export const environment = {
  production: true,
  backendUrl: 'https://calypso-admin.imagineapps.co/',
  apiDaptaURL: 'https://api.dapta.ai/api/calypso-del-caribe-169-817-8/',
  apiKey:'h6Y4J-12631411-eb41-4e62-958b-3dc5804ccffb-f',
  firebaseConfig: {
    apiKey: 'AIzaSyCXQykrbcRcIeHivanZ9l9C4reIpFUzo9Q',
    authDomain: 'qmenu-de96b.firebaseapp.com',
    databaseURL: 'https://qmenu-de96b.firebaseio.com',
    projectId: 'qmenu-de96b',
    storageBucket: 'qmenu-de96b.appspot.com',
    messagingSenderId: '185587452123',
    appId: '1:185587452123:web:f7db06b3279f4a8d7864fe',
    measurementId: 'G-ZPLYWP6WV3',
  },
};
