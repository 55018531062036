import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-list-item',
  templateUrl: './card-list-item.component.html',
  styleUrls: ['./card-list-item.component.scss']
})
export class CardListItemComponent implements OnInit {

  @Input() card;
  @Input() cardSelected;
  @Output() selectedCard = new EventEmitter<{}>();

  VISA_CARD_TYPE = 'visa';
  MASTER_CARD_TYPE = 'master-card';

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Function that emit the selected card to the parent component
   */
  onCardClick(): void {
    this.selectedCard.emit(this.card);
  }

}
