import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-info-variable-weight',
  templateUrl: './modal-info-variable-weight.component.html',
  styleUrls: ['./modal-info-variable-weight.component.scss']
})
export class ModalInfoVariableWeightComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
