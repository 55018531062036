<div class="row ml-0">
  <div class="col-1 custom-col-1">
    <div class="circle-badge">
      {{ product.cantidad }}
    </div>
  </div>
  <div class="col-10">
    <app-product-info [product]="product" [checkout]="checkout"></app-product-info>
  </div>

 <!-- Se deja comentado pues puede ser utilizado para terminar la funcionalidad -->
  <!-- <div class="col-4">
    <div class="d-flex justify-content-end">
      <strong>
        {{ product?.quantity }} unidades
      </strong>
    </div>
    <div
      class="d-flex justify-content-end product-total"
      *ngIf="!product?.pesoVariable"
    >
      {{
        product?.quantity * product?.precio | currency: "USD":"symbol":"1.0-0"
      }}
    </div>

    <div class="d-flex justify-content-end" *ngIf="!product?.pesoVariable">
      total
    </div>
    <div class="d-flex justify-content-end" *ngIf="product?.pesoVariable">
      <p>
        total
        <span
          *ngIf="state === 'Pendiente confirmar pesos' || state === undefined"
          >aprox.</span
        ><span
          *ngIf="state !== 'Pendiente confirmar pesos' && state !== undefined"
          >final.</span
        >
      </p>
    </div>
    <div
      class="d-flex justify-content-start product-total"
      *ngIf="product?.pesoVariable"
    >
      <span *ngIf="state === 'Pendiente'">
        {{ product?.precioPesoVariable | currency: "USD":"symbol":"1.0-0" }}
      </span>
      <span *ngIf="state !== 'Pendiente'">
        {{
          (product.precio / product.pesoGramos) *
            product.pesoGramosVariable *
            product.quantity | currency: "USD":"symbol":"1.0-0"
        }}
      </span>
    </div>
  </div> -->
</div>
