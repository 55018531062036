import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terminos-y-condiciones-copy',
  templateUrl: './terminos-y-condiciones-copy.component.html',
  styleUrls: ['./terminos-y-condiciones-copy.component.scss']
})
export class TerminosYCondicionesCopyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
