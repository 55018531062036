<div id="menuToggle">
  <input type="checkbox" #sidemenucheckbox id="menuToggleInput" />
  <span id="span-up"></span>
  <span id="span-down"></span>
  <span></span>
  <div id="blockwall"></div>
    <ul id="menu">
      <li>
        <img src="../../../assets/icons/mobile-menu/dirección.svg" alt="Icono acerca"/>
        <a (click)="openAddressModal()">
          Método de entrega
        </a>
      </li>
      <li>
        <img src="../../../assets/icons/header/chicken_leg.svg" alt="Icono acerca"/>
        <a (click)="collapseTab('tienda')">
          Tienda
        </a>
      </li>
      <li>
        <img src="../../../assets/icons/header/heating_pot.svg" alt="Icono acerca"/>
        <a class="custom-a" (click)="collapseTab('recetas')">
          Recetas
        </a>
      </li>

      <li>
        <img src="../../../assets/icons/header/calypso_header_icon.svg" alt="Icono acerca"/>
        <a (click)="collapseTab('conocenos')">
          Conócenos
        </a>
      </li>
      <li>
        <img src="../../../assets/icons/header/store_icon.svg" alt="Icono acerca"/>
        <a (click)="navigate('/nuestras-tiendas')">
          Tiendas
        </a>
      </li>
      <li>
        <img src="../../../assets/icons/header/brand_icon.svg" alt="Icono acerca"/>
        <a (click)="navigate('/company-information/nuestras-marcas')">
          Marcas
        </a>
      </li>
      <li>
        <img src="../../../assets/icons/mobile-menu/cuenta.svg" alt="Icono acerca"/>
        <a (click)="navigate('/login')">
          Cuenta
        </a>
      </li>
    </ul>

  <ng-container *ngIf="tiendasTab">
    <ul class="tab-menu">
      <div class="tab-header">
        <div class="left-header">
          <img class="header-icon" src="../../../assets/icons/header/chicken_leg.svg" alt="icono tienda">
          <p class="header-title">Tienda</p>
        </div>
        <button class="right-header" (click)="collapseTab('tienda')">
          <img class="arrow-icon" src="../../../assets/icons/header/arrow_right.png" alt="icono flecha derecha">
        </button>
      </div>
      <div class="storeContent-container">
        <p class="header-title">Selecciona una categoría para continuar</p>
        <div class="cards-container">
          <ng-container *ngFor="let item of categories">
            <div class="conocenos-card" (click)="redirectStore(item.slug)">
              <div class="image-container">
                <img class="conocenos-img" [src]="item.img" [alt]="item.nombre">
              </div>
              <p>{{ item.nombre }}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </ul>
  </ng-container>

  <ng-container *ngIf="conocenosTab">
    <ul class="tab-menu">
      <div class="tab-header">
        <div class="left-header">
          <img class="header-icon" src="../../../assets/icons/header/calypso_header_icon.svg" alt="icono conocenos">
          <p class="header-title">Conócenos</p>
        </div>
        <button class="right-header" (click)="collapseTab('conocenos')">
          <img class="arrow-icon" src="../../../assets/icons/header/arrow_right.png" alt="icono flecha derecha">
        </button>
      </div>
      <div class="storeContent-container">
        <p class="header-title">Selecciona una categoría para continuar</p>
        <div class="cards-container">
          <ng-container *ngFor="let item of conocenosContent">
            <div class="conocenos-card" (click)="navigate('/company-information/' + item.slug)">
              <div class="image-container">
                <img class="conocenos-img" [src]="item.image" [alt]="item.nombre">
              </div>
              <p>{{ item.nombre }}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </ul>
  </ng-container>

  <ng-container *ngIf="recetasTab">
    <ul class="tab-menu">
      <div class="tab-header">
        <div class="left-header">
          <img class="header-icon" src="../../../assets/icons/header/heating_pot.svg" alt="icono recetas">
          <p class="header-title">Recetas</p>
        </div>
        <button class="right-header" (click)="collapseTab('recetas')">
          <img class="arrow-icon" src="../../../assets/icons/header/arrow_right.png" alt="icono flecha derecha">
        </button>
      </div>
      <div class="storeContent-container">
        <p class="header-title">Selecciona una categoría para continuar</p>
        <div class="cards-container">
          <ng-container *ngFor="let item of recipeCategories let i = index" (click)="updateSelectedRecipe(item.nombre)">
            <div class="conocenos-card" (click)="redirectRecipe(item.nombre)">
              <div class="image-container">
                <img class="conocenos-img" [src]="item.url" [alt]="item.nombre">
              </div>
              <p>{{ item.nombre }}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </ul>
  </ng-container>
</div>
