import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductComponent } from './components/product/product.component';
import { ProductsContainerComponent } from './components/products-container/products-container.component';
import { ProductsSearchContainerComponent } from './components/products-search-container/products-search-container.component';
import { ProductSearchPipePipe } from './pipes/product-search-pipe.pipe';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { ProductLoaderComponent } from './components/product-loader/product-loader.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProductContainerLoaderComponent } from './components/product-container-loader/product-container-loader.component';
import { BrandsComponent } from '../shared/brands/brands.component';
import { SharedModule } from '../shared/shared.module';
import { RelatedProductsComponent } from './components/related-products/related-products.component';
import { VideoProductComponent } from './components/video-product/video-product.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ProductComponent,
    ProductsContainerComponent,
    ProductsSearchContainerComponent,
    ProductSearchPipePipe,
    ProductDetailComponent,
    ProductLoaderComponent,
    ProductContainerLoaderComponent,
    RelatedProductsComponent,
    VideoProductComponent,
  ],
  exports: [
    ProductComponent,
    ProductsContainerComponent,
    ProductLoaderComponent,
    ProductsSearchContainerComponent,
    ProductContainerLoaderComponent,
  ],
  imports: [CommonModule, SharedModule, NgxSkeletonLoaderModule, FormsModule],
})
export class ProductsModule {}
