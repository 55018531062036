import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SalePointsService } from 'src/app/core/services/sale-points/sale-points.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { Address, SalePoint, ShippingMethodType } from 'src/app/interfaces/salePoints.interface';
import { User } from 'src/app/interfaces/user.interface';
import $ from 'jquery';
import { GeolocationService } from 'src/app/core/services/geolocation/geolocation.service';
import { Router } from '@angular/router';
declare var $: $;
@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss'],
})
export class AddressListComponent implements OnInit {
  @Input() addressList: Address[];
  @Input() salePoints: SalePoint[];
  @Input() user: User;

  /**To block whit request */
  clicRequest: boolean = false;

  /** Saved address selected */
  addressSelect: any = '';

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private geolocationService: GeolocationService,
    public router: Router,
  ) { }

  ngOnInit(): void {}
  ngOnChanges() {
    this.getMainAddress();
  }

  /**
   * Change Address when the user selected an address different to address main
   */
  changeAddress() {
    let position = this.addressList.indexOf(this.addressSelect);
    let salePoint = this.salePoints.filter(
      (salePoint) => salePoint.bodega == this.addressSelect.inventario
    )[0];
    for (let address of this.addressList) {
      address.principal = false;
    }
    this.addressList[position].principal = true;
    if (salePoint) {
      $('#popover')?.popover('hide');
      this.updateUserAddressWithCovarege(
        this.addressList,
        salePoint,
        this.addressSelect
      );
    } else {
      $('#popover')?.popover('show');
      this.updateUserAddressWithoutCovarege(
        this.addressList,
        this.addressSelect
      );
    }
  }

  /**
   * Get the main user address
   */
  getMainAddress() {
    if (this.addressList !== undefined) {
      let address = this.addressList.filter(
        (address) => address.principal == true
      );
      if(address.length !== 0) {
        this.addressSelect = address[0];
      } else {
        this.addressSelect = this.addressList[0];
      }
      let index = this.addressList.indexOf(this.addressSelect);
      this.addressList.splice(index, 1);
      this.addressList.unshift(this.addressSelect);
    }
  }

/**
 * Function to update user after verify coverage
 * @param addressSelect
 */
updateUserAfterVerify(addressSelect){
  // Update user
  this.userService
    .updateUserData(this.user)
    .then(() => {
      addressSelect.cobertura ? localStorage.setItem('cobertura', 'SI') : localStorage.setItem('cobertura', 'NO');
      this.userService.onSendGlobalShippingMethod({
        type: ShippingMethodType.SHIPPING,
        address: addressSelect
      });
      this.toastr.success('Tu dirección ha sido cambiada con éxito.');

      this.router.navigate(['/']);
      $('#closeAddressListModal')?.click();
    })
    .catch((error) => {
      this.toastr.error('No hemos podido cambiar tu dirección.');
      console.log(`Error adding address: ${error}`);
    });
}

  /**
   * Update address information in firebase
   * @param addressList Address aray
   * @param salePoint Address sale point
   * @param addressSelect Address select by user
   */
  async updateUserAddressWithCovarege(addressList, salePoint, addressSelect) {
    let { nomenclatura, numeroUno, numeroDos, numeroTres } = addressSelect;
    let address =
      nomenclatura.replace(/ /g, '') +
      ' ' +
      numeroUno.replace(/ /g, '') +
      '#' +
      ' ' +
      numeroDos.replace(/ /g, '') +
      '-' +
      numeroTres.replace(/ /g, '');

    (this.user.puntoDeVentaBarrio = salePoint.barrio),
    (this.user.puntoDeVentaBodega = salePoint.bodega),
    (this.user.puntoDeVentaCiudad = salePoint.nombreMunicipio),
    (this.user.puntoDeVentaCodMun = salePoint.codigoMunicipio),
    (this.user.puntoDeVentaDepto = salePoint.codigoDepartamento),
    (this.user.puntoDeVentaId = salePoint.id),
    (this.user.puntoDeVentaNombre = salePoint.nombre),
    (this.user.puntoDeVentaTpv = salePoint.tpv),
    (this.user.puntoDeVentaWhatsapp = salePoint.whatsapp);
    this.user.direccion = address;
    this.user.direcciones = addressList;
    this.user.tipoEntrega = "Domicilio";
    const pointGPS = {
      lat: addressSelect.lat,
      lng: addressSelect.lng
    }
    let res: any;
    res = await this.geolocationService.validateLocationMap(pointGPS, this.salePoints)
    if (res.coverage && !addressSelect.cobertura){
      addressSelect.cobertura = true;
    }
    // Update user
    this.userService
      .updateUserData(this.user)
      .then(() => {
        if (window.innerWidth < 490) {
          // Tamaño de pantalla pequeño: alineación al centro
          this.toastr.success('Tu dirección ha sido cambiada con éxito.',null,{
            positionClass: 'toast-top-center',
          });
        } else {
          // Tamaño de pantalla grande: alineación a la derecha
          this.toastr.success('Tu dirección ha sido cambiada con éxito.',null,{
            positionClass: 'toast-top-right',
          });
        }
        this.userService.onSendGlobalShippingMethod({
          type: ShippingMethodType.SHIPPING,
          address: addressSelect
        });

        addressSelect.cobertura ? localStorage.setItem('cobertura', 'SI') : localStorage.setItem('cobertura', 'NO');

        this.router.navigate(['/']);
        $('#closeAddressListModal')?.click();
        //location.reload();
      })
      .catch((error) => {
        this.toastr.error('No hemos podido cambiar tu dirección.');
        console.log(`Error adding address: ${error}`);
      });
  }

  updateUserAddressWithoutCovarege(addressList, addressSelect) {
    this.clicRequest = true;
    let { nomenclatura, numeroUno, numeroDos, numeroTres } = addressSelect;
    let address =
      nomenclatura.replace(/ /g, '') +
      ' ' +
      numeroUno.replace(/ /g, '') +
      '#' +
      ' ' +
      numeroDos.replace(/ /g, '') +
      '-' +
      numeroTres.replace(/ /g, '');
    this.user.direccion = address;
    this.user.direcciones = addressList;
    // Update user
    this.userService
      .updateUserData(this.user)
      .then(() => {
        this.clicRequest = false;
        if (window.innerWidth < 490) {
          // Tamaño de pantalla pequeño: alineación al centro
          this.toastr.success('Tu dirección ha sido cambiada con éxito.',null,{
            positionClass: 'toast-top-center',
          });
        } else {
          // Tamaño de pantalla grande: alineación a la derecha
          this.toastr.success('Tu dirección ha sido cambiada con éxito.',null,{
            positionClass: 'toast-top-right',
          });
        }
        this.userService.onSendGlobalShippingMethod({
          type: ShippingMethodType.SHIPPING,
          address: addressSelect
        });
        $('#closeAddressListModal')?.click();
      })
      .catch((error) => {
        this.clicRequest = false;
        this.toastr.error('No hemos podido cambiar tu dirección.');
        console.log(`Error adding address: ${error}`);
      });
  }

  openModal(event): void {
    $(event)?.click();
    this.closeAddressListModal();
  }

  closeAddressListModal(): void {
    $('#closeAddressListModal')?.click();
  }
}
