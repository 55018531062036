import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  /** var to show or not footer on main layout*/
  private _shouldShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() { }

  /**
   *  Function to update _shouldShow value
   * @param shouldShow new value to this.shouldShow
   */
  updateShouldShow(shouldShow): void {
    this._shouldShow.next(shouldShow);
  }

  /**
   *  Function to get _shouldShow value
   */
  get shoulShow(): Observable<boolean>{
    return this._shouldShow.asObservable();
  }

}
