import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
import { OrdersService } from 'src/app/core/services/orders/orders.service';
import { TransactionService } from 'src/app/core/services/transaction/transaction.service';

@Component({
  selector: 'app-respuesta-pse',
  templateUrl: './respuesta-pse.component.html',
  styleUrls: ['./respuesta-pse.component.scss']
})
export class RespuestaPseComponent implements OnInit {

  public payment;

  public isApp = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private route: ActivatedRoute,
    private tranService: TransactionService,
    private orderService: OrdersService,
    private localStorage: LocalstorageService
  ) { }

  ngOnInit(): void {
    let transactionId = this.route.snapshot.queryParamMap.get('transactionId');
    const appParam = this.route.snapshot.queryParamMap.get('mobile');
    if (appParam == '1') {
      this.isApp = true;
    }

    if (!transactionId) {
      if (isPlatformBrowser(this.platformId)) {
        transactionId = this.localStorage.getItem('pse-transaction-id');
      }
    }
    if (!transactionId) {
      if (this.isApp) {
        window.location.href = '/';
      } else {
        this.router.navigateByUrl('/');
      }
    } else {
      this.tranService.getPSETransaction(transactionId).subscribe(data => {
        this.payment = data?.body;
        if (this.payment) {
          if (this.payment.x_transaction_state !== 'Pendiente' && this.payment.x_transaction_state !== 'Aceptada') {
            const cancelledOrder = {
              id: this.payment.x_id_invoice,
              pseData: this.payment,
              estado: 'Cancelado'
            };
            this.orderService.updateOrder(cancelledOrder);
          } else if (this.payment.x_transaction_state === 'Aceptada') {
            const approvedOrder = {
              id: this.payment.x_id_invoice,
              pseData: this.payment,
              estado: 'Pendiente'
            };
            this.orderService.payAndUpdateOrder(approvedOrder);
          }
        }
      });
    }
  }

  goHome(): void {
    if (this.isApp) {
      window.location.href = '/';
    } else {
      this.router.navigateByUrl('/');
    }
  }

  goOrders(): void {
    this.router.navigateByUrl('/user-profile/orders-info');
  }

}
