import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-rating-stars',
  templateUrl: './rating-stars.component.html',
  styleUrls: ['./rating-stars.component.scss']
})
export class RatingStarsComponent implements OnInit {
  @Input() selectedStars = 0;
  @Output() selectedStar = new EventEmitter<number>();
  MAX_STARS = 5;
  maxStartsToShow = Array(this.MAX_STARS)
    .fill(0)
    .map((x, i) => i + 1);

  constructor() { }

  ngOnInit(): void {
  }

  onSelectedStar(index: number): void {
    this.selectedStars = index;
    this.selectedStar.emit(this.selectedStars);
  }

}
