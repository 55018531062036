import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { createPrinterTemplate } from 'src/app/global/utils';


@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {

  public showOutput: boolean = false;
  public calculatorInputForm: FormGroup;
  public calculatorOutputForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) {

    this.calculatorInputForm = this.formBuilder.group({
      consumoMesKg: ['', Validators.required],
      PDV: ['', Validators.required],
      frecuenciaSemanal: ['', Validators.required],
      valorCentralizado: [''],
      valorDescentralizado: [''],
      precioKgEnPesos: ['', Validators.required],

    });

    this.calculatorOutputForm = this.formBuilder.group({
      semanal: [''],
      entregaFrecuencia: [''],
      porPDV: [''],
      costoEntrega: [''],
      costoMensual: [''],
      costoAnual: [''],
    });
  }

  ngOnInit(): void {
  }

  calculate(formValue, event) {
    const weeklyConsumption = formValue.consumoMesKg / 4.3;
    const deliveryFrequency = weeklyConsumption / 2;
    const perPDV = deliveryFrequency / formValue.PDV;
    const costPerDelivery = formValue.precioKgEnPesos * perPDV;
    const monthlyCost = costPerDelivery * 2 * 4.3;
    const annualCost = monthlyCost * 12;

    this.calculatorOutputForm.patchValue({
      semanal: Math.round(weeklyConsumption),
      entregaFrecuencia: Math.round(deliveryFrequency),
      porPDV: Math.round(perPDV),
      costoEntrega: Math.round(costPerDelivery),
      costoMensual: Math.round(monthlyCost),
      costoAnual: Math.round(annualCost)
    });

    if (event && this.calculatorInputForm.valid) this.showOutput = true;
  }

  /**
   * Creates a new window to print the quotation with two different layouts
   * @param inputValue Calculator input form
   * @param outputValue Calculator output form (quotation results)
   */
  printForm(inputValue, outputValue) {
    // Open a new window
    const printWindow = window.open('', '', 'height=500,width=800');

    // Creates the visualization of the quotation (print format)
    (window.screen.width < 1024) ?
      printWindow.document.write(createPrinterTemplate(inputValue, outputValue, true)) :
      printWindow.document.write(createPrinterTemplate(inputValue, outputValue, false));

    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
    }, 700);
  }

  resetForms() {
    this.calculatorInputForm.reset();
    this.calculatorOutputForm.reset();
    this.showOutput = false;
  }
}
