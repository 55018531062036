import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class AbandonedShoppingCartService {
  constructor() {}

  getAbandonedShoppingCart(id) {
    return firebase.firestore().collection('carritoAbandonado').doc(id).get();
  }

  getAllAbandonedCarts() {
    return firebase.firestore().collection('carritoAbandonado').get();
  }

  createAbandonedCart(abandonedCart) {
    return firebase
      .firestore()
      .collection('carritoAbandonado')
      .add(abandonedCart);
  }

  updateAbandonedCart(id, abandonedCart) {
    return firebase
      .firestore()
      .collection('carritoAbandonado')
      .doc(id)
      .update(abandonedCart);
  }

  deleteAbandonedCart(id) {
    return firebase
      .firestore()
      .collection('carritoAbandonado')
      .doc(id)
      .delete();
  }
}
