<div
  class="modal fade-modal"
  id="coverageFormModal"
  aria-labelledby="coverageFormModalLabel"
>
  <div class="modal-dialog show2">
    <div class="modal-content">
      <form [formGroup]="addressForm">
        <div class="modal-body modal-contenido">
          <a class="back-btn title h5" (click)="openModal('#openDeliverySelector')">
          <img src="../../../assets/icons/header/arrow_left2.png" alt="icon-back" class="icon-img" />
          </a>
        <div class="col-title">
          <p class="title h3 mb-2">Indica la dirección de entrega</p>
        </div>
        <a (click)="closeModal()" class="modal-close">
          <img src="../../../assets/icons/header/clear.png" alt="icon-close" class="icon-button icon-img" />
        </a>
        <div class="form-check">
          <div class="row col-form">
            <div class="col-12">
              <label class="labels">Ciudad</label>
              <select
                class="form-control city {{ customCity === false ? 'custom-opt' : null}}"
                id="ciudad"
                formControlName="ciudad"
                (change)="checkSelectOption()"
              >
                <option selected>Selecciona una opción</option>
                <option *ngFor="let city of cities">{{ city }}</option>
              </select>
              <div class="icon-down-div">
                <img src="../../../assets/icons/header/arrow_down2.png" alt="arrow" class="icon-button icon-img-arrow" />
              </div>
            </div>
          </div>
          <!--  -->

          <label class="label-address" for="validationTooltip01">
            Dirección</label
          >
          <div class="form-row form-address">
            <div class="col-sm-5 col-xs-12 col-address">
              <select
                class="form-control {{ customNom === false ? 'custom-opt' : null}}"
                formControlName="nomenclatura"
                id="nomenclatura"
                (change)="checkSelectOption()"
              >
                <option selected>Selecciona una opción</option>
                <option *ngFor="let nomenclatura of nomenclaturas">
                  {{ nomenclatura }}
                </option>
              </select>
              <div class="icon-down-div">
                <img src="../../../assets/icons/header/arrow_down2.png" alt="arrow" class="icon-button icon-img-arrow" />
              </div>
            </div>
            <div class="col-2 col-address">
              <input
                type="text"
                class="form-control custom-opt"
                id="numeroUno"
                placeholder="00"
                formControlName="numeroUno"
                (input)="checkFormData()"
                maxlength="10"
              />
              <div class="icon-hash-div">
              </div>
            </div>

            <div class="col-2 col-address col-2-custom">
              <input
                type="text"
                class="form-control middle2 custom-opt"
                id="numeroDos"
                placeholder="00"
                formControlName="numeroDos"
                (input)="checkFormData()"
                maxlength="10"
              />
              <div class="icon-hash-div">
                <img src="../../../assets/icons/header/hashtag.png" alt="hashtag" class="icon-button fas-hashtag"/>
              </div>
            </div>

            <div class="col-1 col-address">
              <p class="p-custom">
                -
              </p>
            </div>
            <div class="col-2 col-address">
              <input
                type="text"
                class="form-control custom-opt"
                id="numeroTres"
                placeholder="00"
                formControlName="numeroTres"
                (input)="checkFormData()"
                maxlength="10"
              />
            </div>
          </div>
          <label *ngIf="coverage === false || validCoverage === false" class="mss-coverage">No se encontró cobertura, por favor valide la dirección nuevamente</label>
          <label class="label-address lb-a">
            Confirmaciones adicionales</label
          >
          <div class="form-row form-address">
            <div class="col-sm-5 col-xs-12 col-address">
              <select
                class="form-control {{ customLabel === false ? 'custom-opt' : null}}"
                formControlName="etiqueta"
                id="etiqueta"
                (change)="checkSelectLabel()"
              >
                <option selected>Selecciona una opción</option>
                <option *ngFor="let etiqueta of etiquetas">
                  {{ etiqueta }}
                </option>
              </select>
              <div class="icon-down-div">
                <img src="../../../assets/icons/header/arrow_down2.png" alt="arrow" class="icon-button icon-img-arrow" />
              </div>
            </div>
            <div class="col-6 col-address col-6-custom">
              <input
                type="text"
                class="form-control custom-opt"
                id="indicaciones"
                placeholder="Ejm: Apto 402, torre 5"
                formControlName="indicaciones"
                maxlength="20"
              />
              <div class="icon-hash-div">
              </div>
            </div>
          </div>
          <label class="label-address label-address2">Por favor, verifica si tu posición es la correcta. De no serlo, arrastra la posición correcta en el mapa.</label>
          <div class="col-12 col-map">
            <google-map
            width="100%"
            height="300px"
            [center]="center"
            [zoom]="zoomInit"
            [options]="mapOptions"
              (mapDragend)="changeCenter()"
            >
              <map-marker [position]="center" [options]="marker.options">
              </map-marker>
            </google-map>
          </div>
            <div class="col-5 col-close">
              <p class="close h5" data-dismiss="modal" id="closeCovarageForm">
              </p>
            </div>
          </div>
        </div>
      </form>
      <div class="modal-footer">
        <button
        *ngIf="confirmAdd === false"
          class="btn btn-primary confirm-btn custom-btn"
          data-toggle="modal"
          [disabled]="
            !addressForm.valid ||
            addressForm.get('ciudad').value == 'Selecciona' ||
            addressForm.get('nomenclatura').value == 'Selecciona' ||
            addressForm.get('etiqueta').value == 'Selecciona' ||
            addressForm.get('ciudad').value === 'Selecciona una opción' ||
            addressForm.get('nomenclatura').value === 'Selecciona una opción' ||
            addressForm.get('etiqueta').value === 'Selecciona una opción'
          "
          (click)="submitForm()"
        >
          {{ (addressForm.get('ciudad').value !== 'Selecciona una opción' &&
          addressForm.get('nomenclatura').value !== 'Selecciona una opción' &&
          addressForm.get('ciudad').value !== '' && addressForm.get('nomenclatura').value)
            ? 'Confirmar punto de venta' : 'Confirmar dirección' }}
        </button>
        <button
          *ngIf="confirmAdd"
          class="btn btn-primary confirm-btn custom-btn"
          data-toggle="modal"
          [disabled]="
          !addressForm.valid ||
          addressForm.get('ciudad').value == 'Selecciona' ||
          addressForm.get('nomenclatura').value == 'Selecciona' ||
          addressForm.get('etiqueta').value == 'Selecciona' ||
          addressForm.get('ciudad').value === 'Selecciona una opción' ||
          addressForm.get('nomenclatura').value === 'Selecciona una opción' ||
          addressForm.get('etiqueta').value === 'Selecciona una opción' ||
          coverage === false || validCoverage === false
          "
          (click)="validatePosition()"
        >
        Confirmar y validar cobertura
        </button>
      </div>
    </div>
  </div>
</div>
