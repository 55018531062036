import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursFormat'
})
export class HoursFormatPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) return null;
    const hours = Math.floor(value/60);
    const minutesLeft = value % 60;
    return tConvert(`${hours < 10 ? '0' : ''}${hours}:${minutesLeft < 10 ? '0': ''}${minutesLeft}`);
  }
}

function tConvert (time: any) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time];
  if (time.length > 1) { // If time format correct
    time = time.slice (1);  // Remove full string match value
    time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}
