import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { truncateSync } from 'fs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  baseUrl = environment.backendUrl;
  // baseUrl = 'http://localhost:8080/';
  constructor(private httpClient: HttpClient, private angularFireDatabase: AngularFireDatabase) {}

  storeCard(data): any {
    return this.httpClient.post(`${this.baseUrl}guardarTarjeta`, data, this.httpOptions);
  }

  processPayment(paymentData: object): any {
    const req = this.httpClient.post(`${this.baseUrl}realizarPago`, JSON.stringify(paymentData), this.httpOptions);
    return req;
  }

  processPSEPayment(paymentData: object): any {
    const req = this.httpClient.post(
      `${this.baseUrl}crearTransaccionPse`,
      JSON.stringify(paymentData),
      this.httpOptions
    );
    return req;
  }

  createSiesaOrder(data: any): any {
    const response = this.httpClient.post(`${this.baseUrl}insertPedidoMaster`, JSON.stringify(data), this.httpOptions);
    return response;
  }

  getTransactionSiesa(guidObj: any): any {
    return this.httpClient.post(`${this.baseUrl}getTransaccion`, JSON.stringify(guidObj), this.httpOptions);
  }

  getPSEBanks(): any {
    return this.httpClient.get(`${this.baseUrl}bancosPse`);
  }

  getPSETransaction(transactionId: string): any {
    return this.httpClient.get(`${this.baseUrl}getTransaccionPse/${transactionId}`);
  }

  push(clase): any {
    return this.angularFireDatabase.list('transacciones/').push(clase);
  }

  addTransaction(transaction): any {
    return this.angularFireDatabase.object('/transacciones/' + transaction.id).set(transaction);
  }
}
