import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  ShippingMethod,
  ShippingMethodType,
} from 'src/app/interfaces/salePoints.interface';
import { Product } from '../../../interfaces/products.interface';
import { UserService } from '../user/user.service';
import * as firebase from 'firebase';
import { AbandonedShoppingCartService } from '../abandoned-shopping-cart/abandoned-shopping-cart.service';
import { LocalstorageService } from '../localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  private products: Product[] = [];
  public deployShoppingCart = new BehaviorSubject([]);

  public cart = new BehaviorSubject([]);
  cart$ = this.cart.asObservable();
  private shippingMethod: ShippingMethod;
  deliveryTax = 4900;

  constructor(
    private userService: UserService,
    private abandonedCartService: AbandonedShoppingCartService,
    private localStorage: LocalstorageService,
  ) {
    this.shippingMethod = userService.globalShippingMethod;
    userService.sendGlobalShippingMethodObservable.subscribe(
      (shipMethod) => (this.shippingMethod = shipMethod)
    );
    this.initShoppingCart();
  }

  /**
   *  We communicate with layout for deploy component shopping-card
   * @param data inf for deploy modal
   */
  public changeDeployShoppingCart({ data }): void {
    this.deployShoppingCart.next(data);
  }

  /**
   * Change delivery price
   * @param deliver price
   */
  setDeliveryTax(price: number): void {
    this.deliveryTax = price;
  }

  takeProductsCartSnapshot() {
    let listProduct = [...this.products].map((act) => {
      let obj = {
        idProduct: act.id,
        cantidad: act.quantity,
      };
      return obj;
    });
    let userToSave = JSON.parse(this.localStorage.getItem('user'));
    if (userToSave) {
      let dataAbandonedShoppingCart = {
        abandonado: true,
        fechaAgregado: Date.now().valueOf(),
        fechaPedido: null,
        idPedido: null,
        idUsuario: firebase.firestore().doc(`usuarios/${userToSave.uid}`),
        productos: listProduct,
      };
      let abandonedCart = this.localStorage.getItem('abandonedCart');
      if (abandonedCart === null || abandonedCart === undefined) {
        this.abandonedCartService
          .createAbandonedCart(dataAbandonedShoppingCart)
          .then((res) => {
            let newAbandonedCart = JSON.stringify(res.id);
            this.localStorage.setItem('abandonedCart', newAbandonedCart);
          });
      } else {
        let cartId = JSON.parse(abandonedCart);
        this.abandonedCartService.updateAbandonedCart(
          cartId,
          dataAbandonedShoppingCart
        );
      }
    }
  }

  deleteAbandonedCart() {
    let abandonedCartId = JSON.parse(this.localStorage.getItem('abandonedCart'));
    if (abandonedCartId !== null && abandonedCartId !== undefined) {
      this.abandonedCartService.deleteAbandonedCart(abandonedCartId);
      this.localStorage.removeItem('abandonedCart');
    }
  }

  finishAbandonedCart(pIdPedido, pFechaPedido) {
    let abandonedCartId = JSON.parse(this.localStorage.getItem('abandonedCart'));
    if (abandonedCartId !== null && abandonedCartId !== undefined) {
      this.abandonedCartService.updateAbandonedCart(abandonedCartId, {
        abandonado: false,
        idPedido: pIdPedido,
        fechaPedido: pFechaPedido,
      });
      this.localStorage.removeItem('abandonedCart');
    }
  }

  /**
   * Add product of the shopping cart
   * Check if it is added or not.
   *
   * @param product Producto a agregar
   */
  public addProductsCart(product: Product): void {
    // this.getAbandonedCarts();
    const findProduct = this.products.findIndex(
      (element) => element.id === product.id
    );
    if (findProduct !== -1) {
      if (product.quantity === 0) {
        this.products.splice(findProduct, 1);
        if (this.products.length > 0) {
          this.takeProductsCartSnapshot();
        } else {
          this.deleteAbandonedCart();
        }
      } else {
        this.products[findProduct] = product;
        this.takeProductsCartSnapshot();
      }
      this.cart.next(this.products);
      this.localStorage.setItem('cart', JSON.stringify(this.products));
    } else {
      this.products = [...this.products, product];
      this.takeProductsCartSnapshot();
      this.cart.next(this.products);
      this.localStorage.setItem('cart', JSON.stringify(this.products));
    }
  }

  /**
   * Add us the product with the new reference.
   * If it does not exist, we add it.
   *
   * @param product Producto para agregar de nuevo
   */
  public addCartAgain(product: Product): void {
    const findProduct = this.products.findIndex(
      (element) => element.id === product.id
    );

    if (findProduct !== -1) {
      this.products.splice(findProduct, 1);
    }

    this.products = [...this.products, product];
    this.cart.next(this.products);
  }

  /**
   * Clean shopping cart.
   */
  public cleanShoppingCart(): void {
    for (let i = 0; i < this.products.length; i++) {
      this.products[i].quantity = 0;
      this.products[i].add = false;
    }
    this.products = [];
    this.cart.next(this.products);
    this.localStorage.setItem('cart', JSON.stringify([]));
  }

  public emptyShoppingCart(): void {
    for (let i = 0; i < this.products.length; i++) {
      this.products[i].quantity = 0;
      this.products[i].add = false;
    }
    this.products = [];
    this.deleteAbandonedCart();
    this.cart.next(this.products);
    this.localStorage.setItem('cart', JSON.stringify([]));
  }

  public cleanShoppingCartFinishOrder(pIdPedido, pFechaPedido): void {
    for (let i = 0; i < this.products.length; i++) {
      this.products[i].quantity = 0;
      this.products[i].add = false;
    }
    this.products = [];
    let ppIdPedido = firebase.firestore().doc(`pedidos/${pIdPedido}`);
    this.finishAbandonedCart(ppIdPedido, pFechaPedido);
    this.cart.next(this.products);
    this.localStorage.setItem('cart', JSON.stringify([]));
  }

  /**
   * Clean the shopping cart and add the set of products
   * @param products Products to add
   */
  public cleanAndAddProducts(products: Product[]): void {
    this.cleanShoppingCart();
    this.products = products;
    this.cart.next(this.products);
    this.localStorage.setItem('cart', JSON.stringify(this.products));
    this.initShoppingCart();
  }

  public initShoppingCart(): void {
    const cartLocalStorage: [] = JSON.parse(this.localStorage.getItem('cart'));
    if (cartLocalStorage && cartLocalStorage.length > 0) {
      cartLocalStorage.map((product) => {
        this.addCartAgain(product);
      });
    }
  }

  public getCartProductsAndPrices(): any {
    const subtotal = this.products
      .map((product) => {
        if (product.iva) {
          return product.quantity * product.subtotal;
        } else {
          if (product.pesoVariable) {
            return (
              (product.precio / product.pesoGramos) *
              product.pesoGramosVariable *
              product.quantity
            );
          } else {
            return product.precio * product.quantity;
          }
        }
      })
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);

    const products = this.products.map((product) => {
      product.cantidad = product.quantity;
      return product;
    });

    const totales = this.products
      .map((product) => {
        if (product.pesoVariable) {
          return (
            (product.precio / product.pesoGramos) *
            product.pesoGramosVariable *
            product.quantity
          );
        } else {
          return product.precio * product.quantity;
        }
      })
      .reduce((acumulator, currentValue) => acumulator + currentValue, 0);

    const totalTaxes = this.products
      .map((product) => product.quantity * (product.precio - product.subtotal))
      .reduce((acumulator, currentValue) => acumulator + currentValue, 0);

    const domicilio =
      this.shippingMethod.type === ShippingMethodType.SHIPPING
        ? this.deliveryTax
        : 0;

    const cartInfo = {
      products,
      subtotal: totales,
      totalTaxes,
      deliveryTax: domicilio,
      subtotalTax: totales,
      total: totales + domicilio,
    };
    return new BehaviorSubject(cartInfo).asObservable();
  }
}
