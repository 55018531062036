<div class="container-fluid">
  <label class="title h3"> Búsqueda </label>
  <div class="row">
    <div
      class="col-product"
      *ngFor="
        let product of allProducts
          | productSearchPipe: searchProduct as filteredProducts
      "
    >
      <app-product
        [product]="product"
        id="{{ product.nombre + 'search' }}"
        [spSelected]="true"
      ></app-product>
    </div>
  </div>
</div>
