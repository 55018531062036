<div class="container-fluid max" *ngIf="relatedProducts?.length > 0">
    <div class="degradant" disabled></div>
    <div class="row row-header">
      <div class="col-main">
        <p class="h3 text-category">Productos relacionados</p>
      </div>
    </div>
  
    <div class="scrolling-wrapper" id="relatedDivScroll">
      <div class="container-product d-flex" id="relatedDiv">
        <button class="btn btn-outline-secondary button-left banner-btn" (click)="onScrollLeft('relatedContainer')" [class.no-view]="productsWidth < bodyWidth">
          <em class="fas fa-angle-left"></em>
        </button>
        <div class="subcontainer-product" id="relatedContainer">
          <app-product *ngFor="let product of relatedProducts" [product]="product" id="product" [spSelected]="true" [home]="true"></app-product>
        </div>
        <button class="btn btn-outline-secondary button-right banner-btn" (click)="onScrollRight('relatedContainer')" [class.no-view]="productsWidth < bodyWidth">
          <i class="fas fa-angle-right"></i>
        </button>
      </div>
    </div>
  </div>
  