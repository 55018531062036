import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { GlobalModule } from './global/global.module';
import { CoreModule } from './core/core.module';
import { ProductsModule } from './products/products.module';
import { LayoutComponent } from './layout/layout.component';
import { RouterModule } from '@angular/router';
import { AngularFireModule } from 'angularfire2';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { ReactiveFormsModule } from '@angular/forms';
import * as firebase from 'firebase';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CurrencyPipe } from '@angular/common';
import { RecetasModule } from './recetas/recetas.module';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { OurStoresModule } from './our-stores/our-stores.module';


//
firebase.initializeApp(environment.firebaseConfig);

@NgModule({
  declarations: [AppComponent, LayoutComponent,],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    ProductsModule,
    GlobalModule,
    CoreModule,
    RouterModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    ReactiveFormsModule,
    FormsModule,
    GoogleMapsModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    }),
    NgxSpinnerModule,
    RecetasModule,
    AutocompleteLibModule,
    OurStoresModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CurrencyPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
