<!-- Floats -->
<div class="wp-float">
  <button class="btn-wp h5" data-toggle="modal" data-target="#wpp-info-segmentation">
    <img width="50px" height="50px" class="icon" src="../../../assets/icons/home-main/icon-WP.png" />
  </button>
</div>
<div class="buttons-float-right">
  <div class="info-plan3000" *ngIf="isPlan3000">
    <div class="info-plan3000__content" style="background-color:#7cb3ff">
      <div class="info-plan3000__content-img">
        <img class="nevera-img" src="../../../assets/icons/home-main/nevera.png" alt="nevera-img" />
      </div>
      <span class="m-auto ponter-plan3000" data-toggle="modal" data-target="#info-plan3000-modal" data-dismiss="modal">
        Eres un usuario plan 3000
      </span>
    </div>
  </div>

  <div class="info-plan3000" *ngIf="isNegocio">
    <div class="info-plan3000__content" style="background-color:#202020;">
      <div class="info-plan3000__content-img" style="margin-right: 10%;">
        <img class="negocio-img" src="../../../assets/icons/home-main/storeBusiness.png" alt="business-img" />
      </div>
      <div class="info-business-style">
        <span class="m-auto">
          Eres negocio Calypso
        </span>
      </div>
    </div>
  </div>


  <div class="info-pickup" *ngIf="pickupCode">
    <div class="info-pickup__content">
      <p class="mb-0 h5">Código de retiro</p>
      <strong class="h4">{{ pickupCode }}</strong>
    </div>
  </div>
  <div class="orderPen-float" (click)="onInProgressOrderItem()" style="display: none;">
    <button *ngIf="ordersType === 'ACTIVE'" class="btn-order h5">
      <div class="div-numprod">
        <span class="number-products">
          {{ ordersNumber > 9 ? '9+' : ordersNumber }}
        </span>
      </div>
      Ver pedidos en curso
      <img width="20px" height="20px" class="icon" src="../../../assets/icons/home-main/carrito.png" alt="cart-img" />
    </button>
    <button *ngIf="ordersType === 'CART'" class="btn-order h5">
      <div class="content-btn row no-gutters">
        <div class="div-numprod">
          <span class="number-products">
            {{ ordersNumber > 9 ? '9+' : ordersNumber }}
          </span>
        </div>
        <span class="normal-text">Ver carrito</span>
        <img class="icon" src="../../../assets/icons/home-main/carrito.png" />
      </div>
    </button>
  </div>
</div>

<div class="modal fade" id="info-plan3000-modal" aria-labelledby="mapCoverageLabel">
  <div class="modal-dialog">
    <div class="modal-content img-variable-weigth">
      <div class="modal-body">
        <p class="close-info-modal" data-dismiss="modal">X Cerrar</p>
        <div class="info-plan3000">
          <div class="info-plan3000__content info-plan3000__content--modal">
            <div class="info-plan3000__content-img">
              <img class="nevera-img--modal" src="../../../assets/icons/home-main/nevera.png" alt="nevera-img" />
            </div>
            <strong
              class="m-auto ponter-plan3000 h4"
              data-toggle="modal"
              data-target="#info-plan3000-modal"
              data-dismiss="modal"
            >
              Eres un usuario plan 3000
            </strong>
          </div>
        </div>
        <strong class="h4">Para tener en cuenta.</strong>
        <br />
        <br />
        <p>Ser usuario del plan 3.000 significa que cuentas con una o más neveras en tu negocio.</p>
        <hr />
        <p>
          Los productos y precios que visualizas en la plataforma son los que se han incluido en el plan para tu nevera.
        </p>
        <hr />
        <p>
          Si requieres información adicional o asesoría personalizada escríbenos a servicioalcliente@calypso.la
        </p>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="wpp-info-segmentation" #dialogDetailWpp>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row" style="justify-content: center;">
          <div class="col-12" style="text-align: center;">
            <img
              alt="logo-calypso.png"
              src="../../../assets/icons/header/logo-calypso.png"
              class="img"
            />
          </div>
          <div class="col-12 tittle-question">
            <h3>¿Necesitas soporte App?</h3>
          </div>
          <div class="col-8 body-question">
            <h6>Si necesitas ayuda con tu pedido o tienes dificultades con la app.</h6>
          </div>
          <div class="col-12 div-btn">
            <button class="btn btn-wpp" (click)="onSendWpp('support')">
              <img width="20px" height="20px" class="icon" src="../../../assets/icons/home-main/icon-WP.png" alt="WPP-icon"/>
              <strong class="text-btn">Ingresa aquí.</strong>
            </button>
          </div>
          <div class="col-12 tittle-question">
            <h3>Escríbenos tus PQRS</h3>
          </div>
          <div class="col-8 body-question">
            <h6>Si tienes preguntas, quejas, reclamos o solicitudes.</h6>
          </div>
          <div class="col-12 div-btn">
            <button class="btn btn-wpp" (click)="onSendWpp('PQRS')">
              <img width="20px" height="20px" class="icon" src="../../../assets/icons/home-main/icon-WP.png" alt="WPP-icon"/>
              <strong class="text-btn">Ingresa aquí.</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
