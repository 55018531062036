<label for="" class="visa-y-mastercard"
  >Aceptamos solo Visa y Mastercard.</label
>
<div *ngFor="let paymentMethod of user?.metododepago | splitPairs" class="row">
  <div class="col-6 left">
    <app-card-list-item
      [cardSelected]="cardSelected"
      [card]="paymentMethod[0]"
      (selectedCard)="onSelectCard($event)"
    ></app-card-list-item>
  </div>
  <div class="col-6 right" *ngIf="paymentMethod[1]">
    <app-card-list-item
      [cardSelected]="cardSelected"
      [card]="paymentMethod[1]"
      (selectedCard)="onSelectCard($event)"
    ></app-card-list-item>
  </div>
</div>
<app-add-credit-card (added)="onCardAdded($event)"></app-add-credit-card>
