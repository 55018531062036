import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Product } from 'src/app/interfaces/products.interface';

export interface SeoData {
  /** Título de la página para SEO y redes */
  title: string;
  /** Descripción de la página */
  description: string;
  /** Palabras claves de la página. String con palabras o frases separadas por comas. Opcional */
  keywords?: string;
  /** Ruta de la imagen a mostrar. Puede ser real ('https://www.calypso.la/.../imagen') o relativa ('.../imagen'). Opcional. */
  image?: string;
  /** URL a la que dirigiran las redes sociales. DEBE ser la URL relativa, el método agrega 'https://www.calypso.la/'. Opcional. */
  url?: string;
  /** Booleano que indica si se configura el SEO para la página principal o para otras páginas. Opcional. */
  main?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(public title: Title, public meta: Meta) { }

  /**
   * Método para configurar el SEO de la página que lo invoque
   * @param data Los datos para configurar el Seo de la página
   */
  public setSEO(data: SeoData): void {
    const concat = data.main ? '' : ' | Calypso del Caribe';
    // Se configura el titulo de la página
    this.title.setTitle(data.title + concat);
    this.meta.updateTag({ name: 'title', content: data.title + concat});
    this.meta.updateTag({ itemprop: 'name', content: data.title + concat});
    this.meta.updateTag({ property: 'og:title', content: data.title + concat});
    this.meta.updateTag({ property: 'twitter:title', content: data.title + concat});

    // Se configuran los parámetros básicos de los tags
    this.meta.updateTag({ property: 'og:type', content: 'website'});
    this.meta.updateTag({ property: 'twitter:card', content: 'summary_large_image'});

    // Se configura la descripción de la página en metatags, incluídos las descripciones para redes sociales
    if (data.description) {
      this.meta.updateTag({ name: 'description', content: data.description});
      this.meta.updateTag({ itemprop: 'description', content: data.description});
      this.meta.updateTag({ property: 'og:description', content: data.description});
      this.meta.updateTag({ property: 'twitter:description', content: data.description});
    }

    // Se configuran las keywords
    if (data.keywords) {
      this.meta.updateTag({ name: 'keywords', content: data.keywords});
    }

    // Se configura la imagen para redes sociales
    if (data.image) {
      this.meta.updateTag({ property: 'twitter:image', content: data.image});
      this.meta.updateTag({ property: 'og:image', content: data.image});
    }

    // Se configuran las urls para redes sociales
    if (data.url) {
      this.meta.updateTag({ property: 'twitter:url', content: 'https://www.calypso.la/' + data.url});
      this.meta.updateTag({ property: 'og:url', content: 'https://www.calypso.la/' + data.url});
    }
  }

  /**
   * Método para configurar los metatags de la información de los productos para catálogo
   * @param producto Producto a configurar
   */
  public setProductInfo(product: Product): void {
    this.clearProductInfo();
    if (product) {
      this.setSEO({
        title: product.nombre,
        description: product.descripcion,
        image: product.imagen,
        url: product.slug,
        main: false
      });
      if (product.marca) {
        this.meta.addTag({ property: 'product:brand', content: product.marca });
      }
      if (product.precio) {
        this.meta.addTag({ property: 'product:price:amount', content: product.precio + '' });
        this.meta.addTag({ property: 'product:price:currency', content: 'COP' });
      }
      this.meta.addTag({ property: 'product:availability', content: 'in stock' });
      if (product.sku) {
        this.meta.addTag({ property: 'product:retailer_item_id', content: product.sku });
      }
      if (product.categoria) {
        this.meta.addTag({ property: 'product:item_group_id', content: product.categoria });
      }
    }
  }

  /**
   * Método para eliminar los tags de información del producto
   */
  public clearProductInfo(): void {
    this.meta.removeTag('property="product:brand"');
    this.meta.removeTag('property="product:price:amount"');
    this.meta.removeTag('property="product:price:currency"');
    this.meta.removeTag('property="product:availability"');
    this.meta.removeTag('property="product:retailer_item_id"');
    this.meta.removeTag('property="product:item_group_id"');
  }
}
