import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: number, currencyCode: string = 'USD', display: string = 'symbol', digitsInfo: string = '1.0-0', locale: string = 'en-US'): string | null {
    // Use the built-in currency pipe to format the value first
    let formattedValue = this.currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);

    if (formattedValue) {
      // Replace comma with point
      formattedValue = formattedValue.replace(/,/g, '.');
    }

    return formattedValue;
  }

}
