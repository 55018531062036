<div class="row container-fluid max" *ngIf="product.video" >
  <div class="col-12">
    <div class="video-responsive">
        <iframe
          loading="lazy"
          allowfullscreen="allowfullscreen"
          class="border-radius20"
          [src]="sUrl"
          frameborder="0"
          title="video-producto"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
    </div>
  </div>
</div>