import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politicas-garantias-cambios-devoluciones',
  templateUrl: './politicas-garantias-cambios-devoluciones.component.html',
  styleUrls: ['./politicas-garantias-cambios-devoluciones.component.scss']
})
export class PoliticasGarantiasCambiosDevolucionesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
