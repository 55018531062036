export interface SalePoint {
  barrio: string;
  bodega: string;
  codigoDepartamento: string;
  codigoMunicipio: string;
  createdAt: string;
  direccionSegmentada: {
    ciudad: string;
    nomenclatura: string;
    numeroUno: string;
    numeroDos: string;
    numeroTres: string;
  };
  horaApertura?: string;
  horaCierre?: string;
  horarios: object[];
  id: string;
  lat: number;
  lng: number;
  nombre: string;
  nombreMunicipio: string;
  tpv: string;
  valorDomicilio: number;
  whatsapp: string;
  zonaCobertura: any;
  distribuidor?: string;
}
export interface Address {
  nomenclatura: string;
  ciudad: string;
  numeroUno: string;
  numeroDos: string;
  numeroTres: string;
  indicaciones?: string;
  inventario?: string;
  lat: number;
  lng: number;
  etiqueta?: string;
  cobertura?: boolean;
  principal?: boolean;
  municipioAledano?: string;
  salePoint?: SalePoint;
}

export enum ShippingMethodType {
  SHIPPING = "Domicilio",
  IN_STORE = "Recogida"
}

export interface ShippingMethod {
  type: ShippingMethodType,
  address?: Address,
  salePoint?: SalePoint
}