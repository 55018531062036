import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AbandonedShoppingCartService } from '../abandoned-shopping-cart/abandoned-shopping-cart.service';
import { LocalstorageService } from '../localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public isFromSignIn: boolean = false;
  constructor(
    private abandonedCartService: AbandonedShoppingCartService,
    private router: Router,
    private toastr: ToastrService,
    private afAuth: AngularFireAuth,
    private localStorage: LocalstorageService,
    @Inject(PLATFORM_ID) private platFormId: Object
  ) { }

  /**
   * Login function
   * @param email string. User email
   * @param password string. User password
   */
  async loginToResetPassword(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.afAuth.auth
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  /**
   * A user logs into firebase
   * @param email
   * @param password
   */
  async login(email: string, password: string): Promise<any> {
    if (isPlatformBrowser(this.platFormId)) {
      try {
        await this.afAuth.auth.signInWithEmailAndPassword(email, password);
        this.localStorage.setItem('userLogged', JSON.stringify(true));
        return true;
      } catch (error) {
        console.log(`Failed to login: ${error}`);
        if (error.message == 'The password is invalid or the user does not have a password.') {
          return 'incorrectPassword';
        } else if (
          error.message == 'There is no user record corresponding to this identifier. The user may have been deleted.'
        ) {
          return 'incorrectEmail';
        }
      }
    }
  }

  /**
   * A user registers into firebase
   * @param email
   * @param password
   */
  async signin(email: string, password: string): Promise<any> {
    try {
      const signinRef = await this.afAuth.auth.createUserWithEmailAndPassword(email, password);
      return signinRef;
    } catch (error) {
      console.log(`Failed  user signup: ${error}`);
      return error;
    }
  }

  /**
   * This function verifies the users who enter and register with the telephone.
   * @param phone telephone number
   * @param appVerifier reCaptcha
   */
  async sendLoginCode(phone: string, appVerifier: any): Promise<any> {
    try {
      const signinRef = await this.afAuth.auth.signInWithPhoneNumber(phone, appVerifier);
      return signinRef;
    } catch (error) {
      console.log(`${error}`);
      switch (error.message) {
        case 'signInWithPhoneNumber failed: First argument "phoneNumber" must be a valid string.':
          this.toastr.error('Vuelve a ingresar tu número');
          break;
        case 'We have blocked all requests from this device due to unusual activity. Try again later.':
          this.toastr.error('Se han bloqueado todas las solicitudes de este dispositivo debido a una actividad inusual. Vuelve a intentarlo más tarde.');
          break;
      }
    }
  }

  /**
   * Logout user
   *
   */
  async logout(): Promise<void> {
    if (isPlatformBrowser(this.platFormId)) {
      try {
        await this.afAuth.auth.signOut();
        let carritoAbandonadoAct = JSON.parse(this.localStorage.getItem('abandonedCart'));
        if (carritoAbandonadoAct) {
          this.abandonedCartService.deleteAbandonedCart(carritoAbandonadoAct);
        }
        this.localStorage.clear();
        this.localStorage.setItem('cobertura', 'SIN-SESION');
        this.router.navigate(['/']);
        window.location.reload();
      } catch (error) {
        console.log(`Failed to logout: ${error}`);
      }
    }
  }
  async resetPassword(email: string): Promise<void> {
    try {
      return this.afAuth.auth.sendPasswordResetEmail(email);
    } catch (error) {
      console.log(`Failed to reset password: ${error}`);
    }
  }

  /**
   * Change the user password
   * @param newPassword
   */
  async changePassword(newPassword: string) {
    try {
      return this.afAuth.auth.currentUser.updatePassword(newPassword);
    } catch (error) {
      console.log(`Error changing password: ${error}`);
    }
  }
}
