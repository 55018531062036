import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  @Input() product: any;

  /**
  * Initial map variables if you do not have a location or a defined area
  */
  public lat = 4.68;
  public lng = -74.08;
  public zoom = 13;

  public showMap = false;
  polygons: Array<google.maps.Polygon> = [];

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    const horaAperturaParts = this.product.horaApertura.split(':');
    this.product.horaApertura = new Date(0, 0, 0, parseInt(horaAperturaParts[0]), parseInt(horaAperturaParts[1]));
    const horaCierreParts = this.product.horaCierre.split(':');

    this.product.horaCierre = new Date(0, 0, 0, parseInt(horaCierreParts[0]), parseInt(horaCierreParts[1]));

    this.product.horarios.domingo.inicio = new Date(0, 0, 0, this.product.horarios.domingo.inicio, 0);
    this.product.horarios.domingo.final = new Date(0, 0, 0, this.product.horarios.domingo.final, 0);
  }

  openGoogleMapsAddress(latitude: number, longitude: number): void {
    window.open(`https://www.google.com/maps?q=${latitude},${longitude}`, '_blank');
  }

  /**
   * Creates coverage map for sale points
   * Creates a polygon with specified coordinates, sets it on a map, calculates the center of the polygon
   * @param map
   * @param coverage array of coordinates
   */
  mapReady(map, coverage) {
    // Define polygon coordinates
    const polygonCoords = coverage;
    // Create a polygon with coordinates
    const polygon = new google.maps.Polygon({
      paths: polygonCoords,
      fillColor: '#ffdb05'
    });
    
    // Sets the polygon on the map
    polygon.setMap(map);
    // Calculate the center of the polygon
    const bounds = new google.maps.LatLngBounds();
    polygonCoords.forEach(coord => bounds.extend(new google.maps.LatLng(coord.lat, coord.lng)));
    const center = bounds.getCenter();
    // Sets the center of the polygon on the map.
    map.setCenter(center);
    // Save the polygon for later use.
    this.polygons.push(polygon);
  }

}
