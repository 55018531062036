<div class="container-fluid">
  <div class="HomeMainComponent">
    <div class="row main-row">
      <div class="col-9 logo-calypso">
        <div class="row custom-row">
          <img src="../../../assets/icons/header/logo-calypso.png" class="img" alt=""/>
          <div class="title">Ahorra<br>Mercando</div>
        </div>
      </div>
      <div (click)="navigate('/')" class="col-3 text-1">
        <img src="../../../assets/icons/arrow_back.png" class="icon-arrow" alt=""/>
        Regresar a la tienda
      </div>
    </div>
  </div>
</div>
<div class="row address-row no-gutters container-fluid fluid-addres">
  <div class="col-4 col-sm-3 col-address">
    <p class="address" (click)="openAddressModal()" *ngIf="activatedShippingMethod && activatedShippingMethod.type === 'Domicilio' && activatedShippingMethod.address?.nomenclatura">
      <i class="fas fa-map-marker-alt"></i> Enviar a:
      {{ activatedShippingMethod.address?.nomenclatura }}
              {{ activatedShippingMethod.address?.numeroUno }} # {{ activatedShippingMethod.address?.numeroDos }} -
              {{ activatedShippingMethod.address?.numeroTres }}
      <span *ngIf="coverage === 'NO'">Sin cobertura</span>
    </p>
    <p class="address" (click)="openAddressModal()" *ngIf="activatedShippingMethod && activatedShippingMethod.type === 'Recogida'">
      <i class="fas fa-map-marker-alt"></i>
      Recoger en: {{activatedShippingMethod.salePoint.nombre}}
    </p>
    <p
      class="address"
      (click)="openAddressModal()"
      *ngIf="!activatedShippingMethod || (activatedShippingMethod.type === 'Domicilio' && !activatedShippingMethod.address)"
    >
      <i class="fas fa-map-marker-alt"></i>
      Agrega tu método de entrega
    </p>
  </div>
</div>

<!-- Buttons which open modals -->
<button [hidden]="true" id="openDeliverySelector" data-toggle="modal" data-target="#deliverSelectorModal"></button>
<!-- Address -->
<app-address-main> </app-address-main>


