import { Component, OnInit, Input, PLATFORM_ID, Inject } from '@angular/core';
import { Category, Product } from 'src/app/interfaces/products.interface';
import { isPlatformBrowser } from '@angular/common';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { Router } from '@angular/router';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart/shopping-cart.service';
import { first } from 'rxjs/operators';
import { User } from '../../../interfaces/user.interface';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.scss']
})
export class RelatedProductsComponent implements OnInit {

  _product: Product;

  /**
   * Almacena el usuario
   */
   user: User;

  @Input() set product(value: Product) {
    this._product = value;
    this.updateProduct();
  };
  /**
   * Get the current pruduct 
   */ 
  get product(): Product {
    return this._product;
  }

  /**
   * Saved scroll left position
   */
  scrollLeft = 0;

  /**
   * Get body container width
   */
  bodyWidth: number;

  /**
   * Get products container width
   */
  productsWidth: number;

  /**
   * Get width product
   */
  widthProduct: number;

  /**
   * Related products
   */
  relatedProducts: Product[];

  constructor(
    @Inject(PLATFORM_ID) private platFormId,
    private productService: ProductsService,
    private localStorage: LocalstorageService,
  ) {
    if (isPlatformBrowser(this.platFormId)) {
      this.user = JSON.parse(this.localStorage.getItem('user')) as User;
    }
  }


  ngOnInit(): void {
    if (isPlatformBrowser(this.platFormId)) {

      this.updateProduct();

      //  Get the size of the body container and the sum of the product containers
      setTimeout(() => {
        this.productsWidth = document.getElementById('relatedContainer')?.scrollWidth;
        this.bodyWidth = document.getElementById('relatedDiv')?.offsetWidth - 70;
        this.widthProduct = document.getElementById('product')?.offsetWidth;
      }, 3000);
    }
  }

  /**
   * Updates the related products according to the specified product
   */
  async updateProduct() {
    if (this.product) {
      // Gets the related products
      if (this.product.productosRecomendados) {
        this.relatedProducts = this.product.productosRecomendados;
      } else {
        // en caso de que no tenga recomedados muestra los de su categoria.
        let allProducts = this.productService.allProductsResponse ?? await this.productService.getAllProductsAPI();
        this.relatedProducts = this.productService.getProductsByCategoryAPI(this.product.categoria, allProducts);
        this.relatedProducts = this.relatedProducts?.filter(p => p.id !== this.product.id);
        if (this.user) {
          this.relatedProducts.forEach(element => {
            element = this.productService.aplicaCambioPrecio(element);
            if (this.user.idListaPrecios && element.listaPrecios) {
              if (this.user.idListaPrecios in element.listaPrecios) {
                element.precio = element.listaPrecios[this.user.idListaPrecios]
              }
            }
          })
        }
      }
    }
  }

  /**
   * Scrolling to category container. Scroll to right
   * @param id DOM Element id
   */
  onScrollRight(id: string) {
    if (isPlatformBrowser(this.platFormId)) {
      const element = document.getElementById(id);
      element.scrollLeft += this.widthProduct;
      this.scrollLeft = element.scrollLeft;
    }
  }

  /**
   * Scrolling to category container. Scroll to left
   * @param id DOM Element id
   */
  onScrollLeft(id: string) {
    if (isPlatformBrowser(this.platFormId)) {
      const element = document.getElementById(id);
      element.scrollLeft -= this.widthProduct;
      this.scrollLeft = element.scrollLeft;
    }
  }

}