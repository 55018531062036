<div class="modal fade-modal" id="salePointSelectorModal">
  <div class="modal-dialog show2">
    <div class="modal-content">
      <div class="modal-body modal-contenido">
        <a class="back-btn title h5" (click)="openModal('#openDeliverySelector')">
          <img src="../../../assets/icons/header/arrow_left2.png" alt="icon-back" class="icon-img" />
        </a>
        <div class="col-title">
          <p class="title h3 mb-2">Selecciona el punto de venta</p>
        </div>
        <a (click)="closeModal()" class="modal-close">
          <img src="../../../assets/icons/header/clear.png" alt="icon-close" class="icon-button icon-img" />
        </a>
        <div class="mid-section">
          <h4 class="subtitle">Punto de venta</h4>
          <div class="map-div">
            <div class="row col-form">
              <div class="col-12">
                <select class="form-control {{ !selectedSP ? 'custom-form' : 'custom-form-sales' }}" [(ngModel)]="selectedSP">
                  <option selected [ngValue]="undefined">Selecciona una opción</option>
                  {{ sortSalePointsByName() }}
                  <option *ngFor="let sp of salePoints" [ngValue]="sp">Sede {{ sp?.nombre }}</option>
                </select>
                <div class="icon-down-div">
                  <img src="../../../assets/icons/header/arrow_down2.png" alt="arrow" class="icon-button icon-img-arrow" />
                </div>
              </div>
            </div>
            <div class="{{ !selectedSP ? 'custom-map' : 'custom-map-unselect'}}">
              <google-map
                width="100%"
                height="100%"
                [center]="(selectedSP ? { lat: selectedSP?.lat, lng: selectedSP?.lng } : undefined) || center"
                [zoom]="selectedSP ? zoomIn : zoomOut"
                [options]="mapOptions"

              >
                <map-marker
                  *ngFor="let sp of salePoints"
                  [position]="{ lat: sp?.lat, lng: sp?.lng }"
                  (mapClick)="changeSP(sp)"
                  [options]="markerOptions"
                ></map-marker>
              </google-map>
            </div>
          </div>
        </div>
        <div *ngIf="selectedSP" class="sp-info">
          <p><strong>Dirección:</strong></p>
          <ul>
            <li>
              <p>
                <span>
                  {{ selectedSP?.direccion }}
                </span>
              </p>
            </li>
          </ul>
          <p><strong>Horario de atención:</strong></p>
          <ul>
            <li *ngIf="selectedSP?.horarios.entreSemana.inicio">
              <p class="custom-p">
                Lunes a viernes:
                <span>{{ selectedSP?.horarios.entreSemana.inicio * 60 | hoursFormat }} - </span>
                <span>{{ selectedSP?.horarios.entreSemana.final * 60 | hoursFormat }}</span>
              </p>
            </li>
            <li *ngIf="selectedSP?.horarios.sabado.inicio">
              <p class="custom-p">
                Sábados:
                <span>{{ selectedSP?.horarios.sabado.inicio * 60 | hoursFormat }} - </span>
                <span>{{ selectedSP?.horarios.sabado.final * 60 | hoursFormat }}</span>
              </p>
            </li>
            <li *ngIf="selectedSP?.horarios.domingo.inicio">
              <p class="custom-p">
                Domingos:
                <span>{{ selectedSP?.horarios.domingo.inicio * 60 | hoursFormat }} - </span>
                <span>{{ selectedSP?.horarios.domingo.final * 60 | hoursFormat }}</span>
              </p>
            </li>
            <li *ngIf="selectedSP?.horarios.festivo.inicio">
              <p class="custom-p">
                Festivos:
                <span>{{ selectedSP?.horarios.festivo.inicio * 60 | hoursFormat }} - </span>
                <span>{{ selectedSP?.horarios.festivo.final * 60 | hoursFormat }}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer" *ngIf="selectedSP" (click)="updateUserInStore()" [disabled]="!selectedSP">
        <button class="btn btn-primary confirm-btn custom-btn" *ngIf="loading==false">
          Confirmar punto de venta
        </button>
        <div class="spinner-border spinner-border-sm text-primary float-right" role="status" *ngIf="loading">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <!-- Button which close this modal -->
      <button [hidden]="true" id="closeSalePointSelectorModal" data-dismiss="modal"></button>
    </div>
  </div>
</div>
