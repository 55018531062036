<div class="modal-body">
  <p class="section">
    Por favor leer estos términos y condiciones cuidadosamente. Al acceder a CALYPSO APP en adelante
    <span class="sub-bold">LA APLICACION o APP,</span>
    el
    <span class="sub-bold-usuario">USUARIO</span>
    /CLIENTE y cualquier tercero que en virtud de cualquier tipo de relación con el
    <span class="sub-bold-usuario">usuario</span>
    tenga o pueda tener acceso a
    <span class="sub-bold">LA APLICACION</span>
    , conviene en obligarse según los términos y condiciones que aparecen a continuación:
  </p>
  <p class="sub-bold-underline">OBLIGATORIEDAD Y ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES.</p>
  <p class="section">
    A través de este documento,
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    , quien de ahora en adelante se le denominará
    <span class="sub-bold">LA EMPRESA</span>
    , establece los términos y condiciones de su plataforma y determina la forma en que cada
    <span class="sub-bold-usuario">USUARIO o CLIENTE</span>
    , independientemente su rol, se relaciona con aquella y los servicios que allí ofrecen. La simple utilización de LA
    APLICACIÓN y de los servicios que esta pone a su disposición, cualquiera de ellos, se considera una aceptación
    expresa de cada una de las cláusulas que se encuentran a continuación del presente documento; si usted no está de
    acuerdo con el uso concedido o el servicio ofrecido, por favor absténgase de utilizar
    <span class="sub-bold">LA APLICACION</span>
    .
    <br />
    <span class="sub-bold">LA APLICACION</span>
    está disponible sólo para su uso personal, sujeto a los términos y condiciones establecido en el presente acuerdo,
    junto con nuestra Política de Privacidad y manejo de datos personales. Con el acceso a
    <span class="sub-bold">LA APLICACION</span>
    , el
    <span class="sub-bold-usuario">usuario</span>
    , de manera serena, madura y completamente voluntaria, entiende y comprende y por lo mismo ACEPTA en forma libre y
    espontánea que al acceder a
    <span class="sub-bold">LA APLICACION</span>
    y a hacer uso de la clave de acceso asignada, para realizar alguna transacción, acepta los presentes términos y
    condiciones de uso de
    <span class="sub-bold">LA APLICACION</span>
    .
    <br />
    <span class="sub-bold">LA APLICACION</span>
    ofrece una serie de recursos en línea que incluye mensajes, foros, avisos, consultas, encuestas, notificaciones y
    diferentes servicios de email. Al aceptar los presentes Términos y Condiciones el
    <span class="sub-bold-usuario">Usuario</span>
    entiende y acepta que podrá recibir comunicaciones de
    <span class="sub-bold">LA APLICACION</span>
    o de quienes CALYPSO DEL CARIBE designe, incluyendo, pero no limitándose a requeridas o no por el ordenamiento
    jurídico vigente. En caso de optar por salir de nuestra lista de destinatarios de comunicaciones de marketing
    directo, podrá ejercer tal opción enviando la solicitud a mercadeocalypso@gmail.com
  </p>
  <p class="sub-bold-underline">DEFINICIONES GENERALES</p>
  <p class="section">
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    es el dueño y responsable de
    <span class="sub-bold">LA APLICACION</span>
    , en lo sucesivo denominado
    <span class="sub-bold">LA APLICACION</span>
    o EL SITIO. Un
    <span class="sub-bold-usuario">usuario</span>
    de LA APLICACION se denominará en adelante, el
    <span class="sub-bold-usuario">USUARIO</span>
    o CLIENTE.
  </p>
  <p class="sub-bold-underline">RESPONSABLE DE LA PRESTACIÓN DEL SERVICIO.</p>
  <p class="section">
    <span class="sub-bold">LA EMPRESA</span>
    , es quien, a través de su equipo, aplicación o plataforma web, se encarga de poner a disposición del
    <span class="sub-bold-usuario">USUARIO</span>
    la posibilidad de ordenar domicilios de productos alimenticios para ser llevados a un sitio en particular o para ser
    retirados en los establecimientos de comercio de
    <span class="sub-bold">LA EMPRESA</span>
    . En caso de que se presente un inconveniente, duda o sugerencia con el uso de
    <span class="sub-bold">LA APLICACION</span>
    o de los presentes Términos y Condiciones de Uso del Sitio, puede dirigir un correo electrónico con la solicitud y
    datos de contacto al correo electrónico: mercadeocalypso@gmail.com
  </p>
  <p class="sub-bold-underline">
    OBJETO DE
    <span class="sub-bold">LA APLICACION</span>
    .
  </p>
  <p class="section">
    <span class="sub-bold">LA APLICACION</span>
    es una plataforma que se encarga de poner a disposición del
    <span class="sub-bold-usuario">USUARIO</span>
    la posibilidad de ordenar domicilios de productos alimenticios para ser llevados a un sitio en particular o para ser
    retirados en los establecimientos de comercio de
    <span class="sub-bold">LA EMPRESA</span>
  </p>
  <p class="sub-bold-underline">PROCEDIMIENTO PARA REGISTRO.</p>
  <p class="sub-bold">REGISTRO A LA APP</p>
  <p class="section">
    Los
    <span class="sub-bold-usuario">usuarios</span>
    que deseen hacer uso de LA APLICACIÓN deberán registrarse y así obtener un
    <span class="sub-bold-usuario">usuario</span>
    y contraseña para el ingreso. Dicho
    <span class="sub-bold-usuario">usuario</span>
    y contraseña es personal e intransferible, cualquier uso indebido será responsabilidad del
    <span class="sub-bold-usuario">USUARIO</span>
    pues es en quien recae el deber de custodia de dicha información. Una vez se ingrese a LA APLICACIÓN se deberá ir
    diligenciando la información que se solicita, la cual tiene como fin ofrecerle al
    <span class="sub-bold-usuario">USUARIO</span>
    la posibilidad de ordenar domicilios de productos alimenticios para ser llevados a un sitio en particular o para ser
    retirados en los establecimientos de comercio de
    <span class="sub-bold">LA EMPRESA</span>
    .
  </p>
  <p class="sub-bold-underline">PROCEDIMIENTO PARA ELEGIR EL PRODUCTO.</p>
  <p class="section">
    Una vez el
    <span class="sub-bold-usuario">USUARIO</span>
    complete los formularios de recolección de información necesarios para generar los resultados, podrá escoger la
    opción que más se ajuste a sus necesidades. Se reitera que la función de LA APLICACIÓN es poner a disposición del
    <span class="sub-bold-usuario">USUARIO</span>
    la posibilidad de ordenar domicilios de productos alimenticios para ser llevados a un sitio en particular o para ser
    retirados en los establecimientos de comercio de
    <span class="sub-bold">LA EMPRESA</span>
    .
    <br />

    LA APLICACIÓN es una app para pedir alimentos congelados, secos, crudos y/o pre-cocidos de fácil preparación por
    domicilio. En el “Home” de la app encontrará los alimentos organizados por categorías, facilitando la navegación y
    el proceso de compra. El
    <span class="sub-bold-usuario">USUARIO</span>
    podrá agregar la cantidad de producto que le convenga, teniendo en cuenta la disponibilidad de inventario.
    <br />
    El esquema de funcionamiento de la aplicación es:
    <br />
  </p>
  <p class="sub-bold-underline">Módulos</p>
  <p class="section">
    - Inicio de sesión
    <br />
    Un
    <span class="sub-bold-usuario">usuario</span>
    debe iniciar sesión para poder realizar pedidos en la aplicación.
    <br />
    - Creación de cuenta
    <br />
    Un
    <span class="sub-bold-usuario">usuario</span>
    puede crear una cuenta en el sistema ingresando su información personal: Nombre, correo, dirección, celular y
    contraseña.
    <br />
    - Búsqueda de productos
    <br />
    Un
    <span class="sub-bold-usuario">usuario</span>
    puede navegar entre los productos de la oferta de Calypso del Caribe. Los productos se pueden navegar mediante un
    buscador por texto o por categorías.
    <br />
    - Carrito de compras
    <br />
    Un
    <span class="sub-bold-usuario">usuario</span>
    puede agregar los productos a un carrito de compras. Los productos que se agregan al carrito de compras deben estar
    limitados por la existencia en inventario en el punto al que se hace el pedido. El punto de venta se identifica como
    el más cercano al
    <span class="sub-bold-usuario">usuario</span>
    por la geolocalización del
    <span class="sub-bold-usuario">usuario</span>
    . Los precios de los productos pueden cambiar a discreción de
    <span class="sub-bold">la empresa</span>
    . Un producto puede no estar disponible porque se dejó de vender a través del app o porque no hay inventario en un
    momento específico.
    <br />
    - Realizar un pago
    <br />
    Un
    <span class="sub-bold-usuario">usuario</span>
    puede completar su pedido pagando de dos formas: * Pago en efectivo. En este caso le paga el pedido en efectivo al
    domiciliario que le entrega el pedido. * Pago en tarjeta. En este caso el
    <span class="sub-bold-usuario">usuario</span>
    debe primero agregar una tarjeta de crédito que se envía a la pasarela de pagos para procesar el pago.
    <br />
    - Pedido en curso
    <br />
    Un
    <span class="sub-bold-usuario">usuario</span>
    puede ver en qué estado se encuentra su pedido, si ha sido despachado o si ya lo tiene un domiciliario puede ver
    dónde viene ese domiciliario.
    <br />
    - Historial de pedidos
    <br />
    Un
    <span class="sub-bold-usuario">usuario</span>
    puede ver el historial de todos los pedidos que ha hecho en el sistema durante el último año.
    <br />
    - Perfil de
    <span class="sub-bold-usuario">usuario</span>
    <br />
    Un
    <span class="sub-bold-usuario">usuario</span>
    puede ver y editar su información personal.
    <br />
    - Puntos de venta
    <br />
    Un
    <span class="sub-bold-usuario">usuario</span>
    puede ver la ubicación y datos de contacto de los puntos de venta de
    <span class="sub-bold">la empresa</span>
    en el país.
    <br />
  </p>
  <p class="sub-bold-underline">PROCEDIMIENTO PARA EFECTUAR EL PAGO.</p>
  <p class="section">
    Una vez el
    <span class="sub-bold-usuario">USUARIO</span>
    seleccione la oferta que considere se ajuste a sus necesidades se habilitará la opción de pago. Los medios de pago
    con los que cuenta LA APLICACIÓN son:
    <br />
    - PSE
    <br />
    - EFECTIVO
    <br />
    - TARJETA DE CRÉDITO
    <br />
    - PASARELA DE PAGOS
    <br />
    <span class="sub-bold">La empresa</span>
    no queda con datos bancarios de las personas al ellos realizar la transacción.
    <br />
    En caso de que el proceso de pago se vea interrumpido el
    <span class="sub-bold-usuario">usuario</span>
    deberá contactarse al correo electrónico soporteapp@calypso.la o al teléfono 304 6341295, donde se dará
    solución al inconveniente a la brevedad posible, previo trámite del ticket de soporte correspondiente.
  </p>
  <p class="sub-bold-underline">CONTACTO VIA CORREO Y REDES SOCIALES</p>
  <p class="section">
    Cualquier persona que requiera información acerca de LA APLICACIÓN o del ingreso a la misma podrá hacerlo a través
    del correo: info@calypso.la o por medio de Facebook o Instagram.
  </p>
  <p class="sub-bold-underline">PROCESO DE PAGOS</p>
  <p class="section">
    Una vez el
    <span class="sub-bold-usuario">USUARIO</span>
    elija la opción que más se ajuste a sus necesidades, LA APLICACIÓN genera un link de pago, este link de pago se crea
    por medio de plataforma para realizar pagos on line a través de PSE, TARJETA DE CRÉDITO o PASARELA DE PAGOS.
    <span class="sub-bold">LA EMPRESA</span>
    no queda con datos bancarios de las personas al ellos realizar la transacción. En caso de que el proceso de pago se
    vea interrumpido el
    <span class="sub-bold-usuario">usuario</span>
    deberá contactarse al correo electrónico soporteapp@calypso.la o al teléfono 304 6341295, donde se dará
    solución al inconveniente a la brevedad posible, previo trámite del ticket de soporte correspondiente.
  </p>
  <p class="sub-bold-underline">PARTES DE LA APLICACIÓN PROTEGIDAS POR CONTRASEÑA</p>
  <p class="section">
    Las partes protegidas por contraseña de LA APLICACIÓN, están destinadas exclusivamente al
    <span class="sub-bold-usuario">USUARIO</span>
    . Tenga en cuenta que la contraseña no debe ser dada a terceros y debe estar protegida contra el acceso no
    autorizado. Si tiene conocimiento de cualquier uso no autorizado de su contraseña, deberá notificar inmediatamente a
    info@calypso.la.
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    no asume ninguna responsabilidad por los daños causados por el uso indebido de su contraseña
  </p>
  <p class="sub-bold-underline">DECLARACION DE PRIVACIDAD Y POLITICAS DE LA APLICACIÓN.</p>
  <p class="section">
    Todos los datos personales proporcionados a LA APLICACIÓN se gestionarán de conformidad con la política de
    tratamiento de datos personales del sitio Web. Toda la información proporcionada LA APLICACIÓN durante su uso debe
    ser correcta, completa y actualizada. Si tenemos razones para creer que se ha suministrado información incorrecta,
    incompleta o desactualizada, el acceso a LA APLICACIÓN puede ser limitado o bloqueado.
  </p>
  <p class="sub-bold-underline">PROPIEDAD INTELECTUAL Y USO DE MATERIALES DE LA APLICACIÓN.</p>
  <p class="section">
    Los desarrollos tecnológicos implementados, así como la marca de LA APLICACIÓN, son elementos debidamente protegidos
    mediante su depósito ante la Dirección Nacional de Derechos de Autor y registro ante la Superintendencia de
    Industria y Comercio. Los derechos sobre productos o marcas comerciales que no sean propiedad de
    <span class="sub-bold">LA EMPRESA</span>
    son propiedad de sus respectivos titulares.
    <br />
    LA APLICACIÓN incluye información y desarrollo de propiedad de LA EMPRESA o terceros y que se encuentra protegida
    por copyright. Ningún contenido de este sitio puede ser copiado, reproducido, recopilado, cargado, publicado,
    transmitido, distribuido, o utilizado sin la autorización del titular de los derechos.
    <br />
    Como única excepción a lo anterior deberá entenderse los casos en los que
    <span class="sub-bold">LA EMPRESA</span>
    de forma inequívoca y explícita otorgue permisos para el uso o acceso a la información contenida (ej: disposición de
    información para su descarga), entendiendo dicho permiso para el uso personal o académico sin que en ningún momento
    se otorgue autorización para ser utilizada con fines económicos y de usufructo. Esta autorización incluye: (i) la
    imposibilidad de modificar el contenido descargado y (ii) la obligación de respetar todos los derechos de autor,
    confidencialidad y de propiedad industrial. Las descargas se regirán por su propio documento de términos y
    condiciones de uso.
    <br />
    Los contenidos presentados en esta aplicación son sujetos de derechos de autor y propiedad industrial, así como
    secreto empresarial, el uso indebido o no autorizado acarrea las sanciones civiles y penales correspondientes.
    <br />
    En los casos en que
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    autoriza el uso de sus contenidos, se debe entender que la autorización quedará inmediatamente revocada por la
    infracción de cualquiera de los términos o condiciones acá planteados o en los respectivos documentos.
    <br />
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    no concede ningún tipo de licencia sobre sus derechos de propiedad intelectual o cualquier otro tipo de propiedad a
    menos que se indique de manera clara y expresa.
    <br />
    El desconocimiento de los compromisos descritos conlleva demandas civiles y penales según lo señalan las leyes
    colombianas. PARÁGRAFO el
    <span class="sub-bold-usuario">usuario</span>
    responderá de las infracciones, daños o perjuicios en que pudiera incurrir en caso de que destine los productos o
    servicios acá contemplados o resultantes de la labor encargada para una actividad diferente a la acá pactada.
  </p>
  <p class="sub-bold-underline">NORMAS DE CONFIDENCIALIDAD Y PROTECCIÓN DE DATOS PERSONALES</p>
  <p class="section">
    Se considera datos personales toda aquella información personal que el
    <span class="sub-bold-usuario">usuario</span>
    ingresa libre y voluntariamente a LA APLICACIÓN, así como aquella que es de obligatorio ingreso. El
    <span class="sub-bold-usuario">USUARIO</span>
    podrá ingresar sus datos personales durante su registro en la aplicación. El
    <span class="sub-bold-usuario">USUARIO</span>
    puede modificar o actualizar parte de esta información en cualquier momento, de esta manera
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    podrá brindarle servicios y contenidos más acordes con su perfil.
    <br />
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    informa al
    <span class="sub-bold-usuario">usuario</span>
    que se compromete a adoptar una política de confidencialidad y protección de datos, con el objeto de proteger la
    privacidad de la información personal obtenida a través de su aplicativo.
    <br />
    De igual manera,
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    se reserva la potestad de modificar el presente documento para adaptarlo a nuevos requerimientos legislativos,
    jurisprudenciales, técnicos o todos aquellos que le permitan brindar mejores y más oportunos servicios y contenidos
    informativos.
  </p>
  <p class="sub-bold-underline">PROTECCIÓN DE LA INFORMACIÓN PERSONAL</p>
  <p class="section">
    La información proporcionada por el
    <span class="sub-bold-usuario">usuario</span>
    al ingresar a LA APLICACIÓN de
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    , está resguardada tecnológicamente.
    <br />
    Si bien
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    posee un sistema de protección tecnológico que va desde sus servidores hasta la salida a Internet, ninguna
    transmisión por Internet puede garantizar su seguridad al 100%.
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    no puede garantizar que la información ingresada a su sitio web o transmitida utilizando su servicio sea
    completamente segura, con lo cual el
    <span class="sub-bold-usuario">usuario</span>
    corre su propio riesgo.
  </p>
  <p class="sub-bold-underline">CONFIDENCIALIDAD DE LA INFORMACIÓN</p>
  <p class="section">
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    se compromete a que no compartirá ni revelará la información confidencial con terceros, excepto que tenga expresa
    autorización de quienes se suscribieron, o cuando ha sido requerido por orden judicial o legal, o para proteger los
    derechos de propiedad intelectual u otros derechos del aplicativo.
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    utiliza la información recolectada únicamente para fines estadísticos y de conocimiento sobre el tráfico de
    <span class="sub-bold-usuario">usuario</span>
    hacia LA APLICACIÓN, así como para enviar boletines con información de interés para el
    <span class="sub-bold-usuario">usuario</span>
    . El
    <span class="sub-bold-usuario">usuario</span>
    , en cualquier momento, tiene la posibilidad de revocar la autorización para el almacenamiento de su información,
    así como para anular la suscripción a los boletines periódicos.
  </p>
  <p class="sub-bold">LIMITACION DE RESPONSABILIDAD, RENUNCIA DE GARANTIAS E INDEMNIZACION</p>
  <p class="section">
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    hará esfuerzos razonables para garantizar que la información y los materiales proporcionados en esta aplicación sean
    correctos. Sin embargo,
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    no puede garantizar la exactitud de la información y los materiales y no asume ninguna responsabilidad ni obligación
    por la exactitud, integridad y autenticidad de cualquier información y materiales contenidos en LA APLICACIÓN. El
    <span class="sub-bold-usuario">USUARIO</span>
    acepta mantener indemne, indemnizar, defender y eximir a
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    de cualquier responsabilidad, daño o pérdida relacionados con cualquier violación por parte suya de estas
    condiciones de uso de LA APLICACIÓN.
  </p>
  <p class="sub-bold-underline">
    OBLIGACIONES QUE ASUME
    <span class="sub-bold">LA EMPRESA</span>
    .
  </p>
  <p class="section">
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    asume la responsabilidad de procurar que la plataforma brinde el funcionamiento mínimo requerido para que el
    <span class="sub-bold-usuario">USUARIO</span>
    pueda darle la utilización propuesta en la declaración de servicios ofrecidos.
    <br />
    Además de esto,
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    se obliga a:
    <br />

    1. Respetar y garantizar el estricto cumplimiento de las políticas de privacidad según lo indica la Ley 1581 de
    2012.
    <br />

    2. Prestar soporte funcional al
    <span class="sub-bold-usuario">USUARIO</span>
    para el uso de LA APLICACIÓN.
    <br />

    3. Garantizar que los contenidos publicados dentro de la plataforma no:

    <br />
    a. Promueven o contienen material sexual explícito. b. Promueven el uso de drogas o sustancias sicoactivas, de
    tránsito restringido y/o relacionadas.
    <br />
    c. Promueven la violencia o contenga material violento explícito.
    <br />
    d. Promueven o contienen material difamatorio o calumnioso que esté en contra del buen nombre u honra de alguna
    persona natural o jurídica.
    <br />
    e. Promueven la discriminación o emplean prácticas o términos discriminatorios, basados en raza, sexo, nacionalidad,
    incapacidad, orientación sexual, edad, etc.
    <br />
    f. Promueven o implican actividades ilícitas o ilegales.
    <br />
    g. Violan los derechos de propiedad intelectual de terceros.
    <br />
    4. Salvaguardar la información registrada en LA APLICACIÓN, por el
    <span class="sub-bold-usuario">USUARIO</span>
    .
    <br />
    5. Mantener los servicios de LA APLICACIÓN actualizados con la última versión del software disponible.
    <br />
    6. Mantener absoluta confidencialidad de la información sensible conocida durante la prestación del servicio.
    <br />
    7. Disponer las medidas de seguridad que se consideren necesarias para que LA APLICACIÓN y las bases de datos
    conectadas a esta cuenten con las condiciones mínimas requeridas para impedir accesos no autorizados, pérdida de
    datos, fallos de funcionamiento, revelación de información confidencial y otros relacionados.
    <br />
    8. Prestar el servicio de LA APLICACIÓN de acuerdo con los presentes “TÉRMINOS Y CONDICIONES”.
  </p>
  <p class="sub-bold-underline">
    OBLIGACIONES QUE NO ASUME
    <span class="sub-bold">LA EMPRESA</span>
    .
  </p>
  <p class="section">
    El
    <span class="sub-bold-usuario">Usuario</span>
    podrá realizar reclamaciones cuando el Producto entregado no corresponda al producto indicado, cuando el Producto no
    se encuentre en el estado indicado en la Plataforma o no se haya entregado un Producto que se encontraba en la Orden
    de Compra, esta podrá realizarla a través del correo electrónico soporteapp@calypso.la
    <br />
    Para todos los cambios se debe realizar la solicitud por escrito y los productos deben conservar las características
    originales.
  </p>
  <p class="sub-bold-underline">
    DERECHOS GENERALES DEL
    <span class="sub-bold-usuario">USUARIO</span>
    .
  </p>
  <p class="section">
    El
    <span class="sub-bold-usuario">USUARIO</span>
    de la plataforma tiene los siguientes derechos:
    <br />
    1. Tener acceso a los contenidos publicados en LA APLICACIÓN siempre y cuando estos sean públicos.
    <br />
    2. A recibir el servicio solicitado siempre y cuando haya sido cancelado el valor correspondiente.
    <br />
    3. Los demás que conceda la ley de manera específica para este tipo de negocios jurídicos.
  </p>
  <p class="sub-bold-underline">
    OBLIGACIONES GENERALES DEL
    <span class="sub-bold-usuario">USUARIO</span>
    DE LA APLICACIÓN:
  </p>
  <p class="section">
    Todos el
    <span class="sub-bold-usuario">USUARIO</span>
    tienen las siguientes obligaciones:
    <br />
    1. Conocer los términos y condiciones de LA APLICACIÓN.
    <br />
    2. No promover de ninguna manera los comentarios, productos, imágenes u otros medios:
    <br />
    a. Material sexual explícito.
    <br />
    b. El uso de drogas o sustancias sicoactivas, de tránsito restringido y/o relacionadas.
    <br />
    c. La violencia o contenga material violento explícito.
    <br />
    d. Material difamatorio o calumnioso, que esté en contra del buen nombre u honra de alguna persona natural o
    jurídica.
    <br />
    e. Discriminación o emplee prácticas o términos discriminatorios, basados en raza, sexo, nacionalidad, incapacidad,
    orientación sexual, edad, etc.
    <br />
    f. Actividades ilícitas o ilegales.
    <br />
    g. Violación a los derechos de propiedad intelectual de terceros.
    <br />
    3. Abstenerse de borrar, destruir, realizar comentarios ofensivos sobre las obras digitalizadas.
    <br />
    4. Autorizar a
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    a dar tratamiento a sus datos personales de conformidad con la ley y nuestras políticas de privacidad y tratamiento
    de datos personales. Usted puede tener acceso a nuestra política de privacidad a través del aplicativo.
    <br />
    5. Realizar las solicitudes de soporte técnico a través del correo electrónico soporteapp@calypso.la
    <br />
    6. Proporcionar información verídica y verificable al momento de realizar el registro.
    <br />
    7. Dar aviso inmediato a
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    a través del correo electrónico soporteapp@calypso.la de cualquier circunstancia que amenace y/o ponga en
    riesgo la integridad de los datos almacenados en LA APLICACIÓN.
    <br />
    8. Consultar de manera permanente el texto de Términos y Condiciones de LA APLICACIÓN para conocer los cambios que
    se presenten en el mismo.
    <br />
    9. Cumplir con la normatividad nacional vigente, de manera responsable, respetuosa diligente, actuando siempre de
    buena fe.
    <br />
    10. Cumplir con las normas que se establezcan en los presentes TÉRMINOS Y CONDICIONES GENERALES DE FUNCIONAMIENTO DE
    LA APLICACIÓN y aquellas que se agreguen en el momento que los mismos sean actualizados.
    <br />
  </p>
  <p class="sub-bold-underline">
    OBLIGACIONES ESPECIALES DEL
    <span class="sub-bold-usuario">USUARIO</span>
    :
  </p>
  <p class="section">
    <br />
    1. Suministrar de manera completa, suficiente y veraz la información solicitada por LA APLICACIÓN.
    <br />
    2. Cumplir con los términos y condiciones de uso de LA APLICACIÓN.
    <br />
    3. Efectuar el pago de la forma convenida.
  </p>
  <p class="sub-bold-underline">PROHÍBICIONES ESPECIALES DE LOS CLIENTES:</p>
  <p class="section">
    Los CLIENTES en general, tienen especialmente prohibido:
    <br />
    1. Crear identidades falsas y/o actuar en nombre de terceros sin su autorización expresa y escrita.
    <br />
    2. Introducir datos falsos como direcciones o productos ficticios, por lo cual estas deben ser exactas y veraces.
    <br />
    3. Infringir derechos de terceros, en especial de propiedad industrial e intelectual.
    <br />
    4. Faltar a la verdad, falsificando documentos, firmas, infringir leyes tanto del ámbito nacional como
    internacional.
    <br />
    5. Utilizar LA APLICACIÓN con el propósito de perpetrar un acto criminal o contrario a la legislación vigente.
    <br />
    6. Utilizar LA APLICACIÓN para transferir, comunicar, reproducir, poner a disposición y/o retransmitir contenidos
    que resulten discriminatorios, ofensivos, de contenido sexual y otros que puedan afectar, ofender y/o agredir a los
    destinatarios del contenido y/o a la sociedad.
    <br />
    7. Vender, compartir, copiar, redistribuir, revender, transferir, licenciar y en general realizar cualquier acto de
    explotación o disposición de los derechos sobre LA APLICACIÓN.
    <br />
    8. Utilizar la plataforma con el propósito de infringir la propiedad intelectual de terceros y/o de
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    <br />
    9. Realizar ingeniería inversa respecto de LA APLICACIÓN con el propósito de acceder a su código fuente, salvo los
    casos en que la normatividad vigente se lo permita expresamente y, aun así, sin que pueda utilizar la información
    accedida para realizar copias, obras derivadas o aplicaciones que puedan afectar o no la seguridad de LA APLICACIÓN.
    <br />
    10. Utilizar los signos distintivos de
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    y en general, la imagen corporativa de
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    sin obtener previamente la autorización expresa y escrita de sus titulares.
    <br />
    11. Realizar cualquier tipo de actividad, maniobra o acción que logre o tenga por efecto atentar en contra la
    estabilidad de LA APLICACIÓN.
    <br />
  </p>
  <p class="sub-bold-underline">INCUMPLIMIENTO DE LAS OBLIGACIONES O PROHÍBICIONES ESPECIALES DE LA APLICACIÓN:</p>
  <p class="section">
    En caso de que cualquier
    <span class="sub-bold-usuario">USUARIO</span>
    incumpla con las obligaciones que se derivan del uso de LA APLICACIÓN o incurra en alguna de las prohibiciones
    especiales,
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    podrá optar por llevar a cabo una o varias de las siguientes acciones:
    <br />
    1. Cancelar la cuenta de servicio al
    <span class="sub-bold-usuario">USUARIO</span>
    para LA APLICACIÓN y como consecuencia de ello prohibir el uso de la aplicación de manera definitiva.
    <br />
    2. Borrar de manera definitiva y permanente todo tipo de información ingresada a la plataforma que contraríen las
    normas establecidas en los presentes términos y condiciones.
    <br />
    3. Iniciar las acciones de tipo civil, penal y administrativo que correspondan cuando a criterio de
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    la infracción de los términos y condiciones previstos en este documento así lo amerite.
    <br />
    La no aplicación inmediata de alguna de las sanciones previstas en este acápite no significa que
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    renuncie al derecho que se abroga de sancionar las conductas indebidas al interior de LA APLICACIÓN y/o a la
    facultad que le concede la ley de iniciar las acciones legales pertinentes para proteger su integridad y/o la de sus
    bienes.
    <br />
  </p>
  <p class="sub-bold-underline">
    DERECHOS DE
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    :
  </p>
  <p class="section">
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    tiene los siguientes derechos:
    <br />
    1. Recibir el pago de los servicios ofrecidos en la plataforma.
    <br />
    2. Modificar LA APLICACIÓN según considere apropiado para el desarrollo de la prestación del servicio y de su plan
    de negocios.
    <br />
    3. Determinar el horario de atención al
    <span class="sub-bold-usuario">USUARIO</span>
    .
    <br />
    4. Recibir retroalimentación por parte del
    <span class="sub-bold-usuario">USUARIO</span>
    sobre alguna eventualidad en el proceso de atención del ticket.
    <br />
    5. Imponer al
    <span class="sub-bold-usuario">USUARIO</span>
    las sanciones que se establecen en el presente documento.
    <br />
    6. Las demás que prevea la ley para este tipo de negocios jurídicos.
    <br />
  </p>
  <p class="sub-bold-underline">RIESGOS DERIVADOS DEL USO DE LA APLICACIÓN:</p>
  <p class="sub-bold">a. Pérdida o robo de la información</p>
  <p class="section">
    Se advierte que los equipos de cómputo, los programas de ordenador, las redes de telecomunicaciones son propensas a
    sufrir daños o averías ocasionados por el uso constante, la falta de mantenimiento, el riesgo eléctrico, el actuar
    de personas inescrupulosas, las pérdidas de señal que son imputables directamente a los proveedores de servicios de
    internet y otros que pueden llegar a causar fallas catastróficas en el uso de la plataforma, la generación de
    contenidos, el intercambio de información y/o su almacenamiento. Frente a lo anterior CALYPSO DEL CARIBE no asume
    ninguna responsabilidad por la pérdida de información que se derive de:
    <br />
    1. El acceso no autorizado de terceros a las cuentas del
    <span class="sub-bold-usuario">USUARIO</span>
    cuando este pierda sus credenciales de acceso o tolere que terceros las conozcan.
    <br />
    2. Cuando el
    <span class="sub-bold-usuario">USUARIO</span>
    pierda la información por daño en sus equipos móviles o de computo.
    <br />
    3. Cuando se presenten otras razones que se relacionen con las anteriores o que sin tener ninguna relación con lo ya
    mencionado, se den como consecuencia de la mala utilización, irresponsabilidad del
    <span class="sub-bold-usuario">USUARIO</span>
    o se deriven de casos fortuitos, fuerza mayor, culpa exclusiva de la víctima o la acción de un tercero.
    <br />
    En caso de pérdida de datos personales dar aviso a las entidades de control y asumir las responsabilidades que
    aquellas determinen. En caso de que usted tenga dudas acerca de los riesgos implícitos que se derivan del uso de LA
    APLICACIÓN por favor remita un correo a info@calypso.la; éstas serán resueltas diligentemente.
    <br />
    Si usted no está dispuesto a tomar las medidas de prevención necesarias y asumir directamente los riesgos que se le
    ponen de presente, le pedimos que se abstenga de utilizar LA APLICACIÓN.
    <br />
  </p>
  <p class="sub-bold-underline">
    MEDIDAS DE MITIGACIÓN DEL RIESGO DESPLEGADAS POR
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    :
  </p>
  <p class="sub-bold">a. Para la prestación del servicio</p>
  <p class="section">
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    realiza constantemente pruebas del servicio ofrecido a través de LA APLICACIÓN para garantizar su disponibilidad y
    fiabilidad.
  </p>

  <p class="sub-bold">b. Para evitar la pérdida o robo de información.</p>
  <p class="section">
    Con el fin de evitar que los riesgos implícitos en el uso de la plataforma se materialicen sobre la información y/o
    datos personales del
    <span class="sub-bold-usuario">USUARIO</span>
    ,
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    se compromete a trabajar constantemente para desarrollar actualizaciones del software buscando adaptar el
    funcionamiento de LA APLICACIÓN para dar solución a los posibles errores de programación que sean encontrados dentro
    de un término prudencial que aquella misma determinará caso por caso.
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    podrá dejar de dar soporte técnico a una versión de LA APLICACIÓN cuando se considere que, por razones del paso del
    tiempo, el avance de la tecnología, el tipo de programación o el riesgo que implica la versión, se ha tornado
    obsoleta. Las actualizaciones y el acceso a versiones posteriores de la LA APLICACIÓN pueden tener un costo
    adicional que al momento de ser puestas a disposición del público será fijado por
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    <br />
    LA APLICACIÓN se encuentra alojada por
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    en un servidor que está rigurosamente protegido y configurado con altos estándares de seguridad, lo cual es garantía
    de protección de la información personal que el
    <span class="sub-bold-usuario">USUARIO</span>
    han introducido en ella, no obstante, ninguna medida de seguridad puede asegurar que no existirá ningún tipo de
    filtración que se deba a una acción criminal, un caso fortuito o una fuerza mayor.
    <br />
    Conforme con lo anterior, ninguna de las medidas tomadas suplanta, deroga o reemplaza el cuidado que cada
    <span class="sub-bold-usuario">USUARIO</span>
    debe tener respecto de sus equipos e información.
    <br />
    En todo caso,
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    ha habilitado la dirección de correo electrónico soporteapp@calypso.la a través de la cual estará atenta
    recibir cualquier petición, queja o reclamo respecto de la prestación del servicio.
    <br />
  </p>
  <p class="sub-bold-underline">COMPROMISO DE CONFIDENCIALIDAD Y SEGURIDAD DELINFORMACIÓN:</p>
  <p class="section">
    Con el uso que el
    <span class="sub-bold-usuario">USUARIO</span>
    hace de LA APLICACIÓN autorizan expresamente a
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    para utilizar los datos almacenados en sus bases de datos con el fin de generar estadísticas anónimas de la
    utilización de la plataforma y/o remitirles información comercial respecto de los servicios ofrecidos, promociones y
    demás que correspondan por parte
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    y/o sus aliados. Cuando se realizan trabajos estadísticos, los datos son utilizados sin ser relacionados con su
    titular, es decir, son tratados de manera anónima, para efecto de la realización de mediciones de las preferencias
    del
    <span class="sub-bold-usuario">USUARIO</span>
    en general. Las mediciones realizadas y los resultados de éstas podrán ser comercializados posteriormente por parte
    de
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    sin relacionar los datos directamente con el
    <span class="sub-bold-usuario">USUARIO</span>
    .
    <br />
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    desplegará todos los medios que se encuentren a su alcance y que se deriven de las obligaciones previstas en la ley
    para procurar proteger los datos personales y demás información almacenada en sus bases de datos.
    <br />
    Los datos personales que
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    recaude en desarrollo de la prestación del servicio se encuentran sometidos al manual de protección de datos
    personales de la LA APLICACIÓN. En caso que usted tenga una petición, queja, reclamo o desee ejercer cualquiera de
    los derechos que se derivan del tratamiento de datos personales, por favor remita un correo a info@calypso.la
    <br />
  </p>
  <p class="sub-bold-underline">DISPONIBILIDAD DEL SERVICIO:</p>
  <p class="section">
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    ha dispuesto las medidas de seguridad y almacenamiento que considera necesarias para otorgar un adecuado grado de
    disponibilidad de la plataforma y de las bases de datos a las que la misma tiene acceso por medio de la conexión a
    internet.
    <br />
    No obstante, dado que toda infraestructura y producto de software y hardware son propensos a la existencia de fallas
    en la comunicación, diseño y funcionamiento,
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    no asume responsabilidad por cortes inesperados del servicio, pérdida de información debido a la falta de conexión y
    otros que se relacionen con el nivel de disponibilidad de la plataforma cuando estos se deban a un caso fortuito,
    fuerza mayor o la culpa de un tercero.
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    hará su mejor esfuerzo para contrarrestar posibles fallas del servicio y mantener la plataforma disponible en todo
    momento.
    <br />
  </p>
  <p class="sub-bold-underline">DISPONIBILIDAD DEL SERVICIO DE SITIOS ENLAZADOS:</p>
  <p class="section">
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    no asume ningún tipo de responsabilidad por la disponibilidad de la información, servicio ofrecido, contenido
    publicado y/o brechas de seguridad existentes en plataformas o páginas de terceros que resulten enlazadas desde LA
    APLICACIÓN. Cuando el
    <span class="sub-bold-usuario">USUARIO</span>
    accede a un sitio de terceros desde un enlace publicitario o no, publicado en la LA APLICACIÓN, se somete a los
    términos y condiciones de uso de dicho sitio.
    <br />
    Al respecto, se aclara que algunos servicios ofrecidos por CALYPSO DEL CARIBE se podrán financiar con ingresos
    obtenidos por concepto de publicidad ubicada en la LA APLICACIÓN. Dado lo anterior, el
    <span class="sub-bold-usuario">USUARIO</span>
    acepta que
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    puede presentar publicidad en la LA APLICACIÓN, sin que por ello se haga responsable de los avisos presentados por
    los anunciantes, sin que tenga que remunerarles por permitir el uso de publicidad en la plataforma y sin que tenga
    que revelar sus contratos de publicidad.
    <br />
  </p>
  <p class="sub-bold-underline">REQUERIMIENTOS TÉCNICOS PARA LA UTILIZACIÓN DE LA LA APLICACIÓN:</p>
  <p class="section">
    Para acceder a LA APLICACIÓN, se requiere contar con las últimas versiones de LA APLICACIÓN. Igualmente las cookies
    y JavaScript deben estar. Para ingresar a LA APLICACIÓN, desde dispositivos móviles se recomienda contar con la
    última versión de Android o IOS a fin de brindar una experiencia única.
  </p>
  <p class="section">
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    se encuentra ubicada en Tocancipá, en la República de Colombia y todo servicio ofrecido a través de la LA APLICACIÓN
    se considera prestado en el territorio colombiano. Así las cosas, la legislación aplicable en razón al origen de
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    y el lugar donde se entiende prestado el servicio, es la República de Colombia. Dado lo anterior, cualquier
    conflicto entre CALYPSO DEL CARIBE y cualquiera de los
    <span class="sub-bold-usuario">USUARIOS</span>
    de la plataforma LA APLICACIÓN, será resuelto ante la jurisdicción colombiana.
    <br />
    En caso de que cualquier aparte de los términos y condiciones del presente documento llegare a ser declarado nulo,
    inexistente y/o inaplicable por un organismo judicial y/o administrativo, CALYPSO DEL CARIBE y el
    <span class="sub-bold-usuario">USUARIO</span>
    procurarán dar aplicación a los apartes restantes del presente documento atendiendo a las normas supletivas que
    resulten aplicables y finalmente a la equidad, toda vez que las intenciones son dar efectivo cumplimiento a la
    prestación del servicio.
    <br />
  </p>
  <p class="sub-bold-underline">LEY APLICABLE A LA APLICACIÓN:</p>
  <p class="section">
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    se encuentra ubicada en Tocancipá, en la República de Colombia y todo servicio ofrecido a través de la LA APLICACIÓN
    se considera prestado en el territorio colombiano. Así las cosas, la legislación aplicable en razón al origen de
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    y el lugar donde se entiende prestado el servicio, es la República de Colombia. Dado lo anterior, cualquier
    conflicto entre CALYPSO DEL CARIBE y cualquiera de los
    <span class="sub-bold-usuario">USUARIOS</span>
    de la plataforma LA APLICACIÓN, será resuelto ante la jurisdicción colombiana.
    <br />
    En caso de que cualquier aparte de los términos y condiciones del presente documento llegare a ser declarado nulo,
    inexistente y/o inaplicable por un organismo judicial y/o administrativo, CALYPSO DEL CARIBE y el
    <span class="sub-bold-usuario">USUARIO</span>
    procurarán dar aplicación a los apartes restantes del presente documento atendiendo a las normas supletivas que
    resulten aplicables y finalmente a la equidad, toda vez que las intenciones son dar efectivo cumplimiento a la
    prestación del servicio.
  </p>
  <p class="sub-bold-underline">EXCLUSIÓN DE RESPONSABILIDAD:</p>
  <p class="section">
    Al usar LA APLICACIÓN, usted como
    <span class="sub-bold-usuario">USUARIO</span>
    acepta que CALYPSO DEL CARIBE no asume ningún tipo de responsabilidad contractual o extracontractual, civil,
    comercial o penal, por concepto de afectaciones a su información empresarial o personal, su integridad física, sus
    bienes y los de quienes tiene a cargo, cuando dichas afectaciones se deriven de la existencia de fallas en la
    disponibilidad del servicio no imputables a
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    , la pérdida de la información por mal uso de la plataforma y la filtración de la información por acceso indebido de
    terceros que se derive de la exposición de los datos de acceso por su propia culpa o descuido.
    <br />
    Al usar LA APLICACIÓN, el
    <span class="sub-bold-usuario">USUARIO</span>
    declara expresamente que conoce los riesgos de uso de la misma y que ha leído los presentes términos y condiciones y
    luego de hacerlo, han tomado las medidas de seguridad necesarias para proteger su integridad personal y los bienes y
    la información del cual son titulares.
    <br />
  </p>
  <p class="sub-bold-underline">MODIFICACIONES A LA APLICACIÓN Y A LOS TÉRMINOS Y CONDICIONES:</p>
  <p class="section">
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    se reserva el derecho a modificar total o parcialmente LA APLICACIÓN en cualquier momento pudiendo eliminar o
    agregar servicios de pago o gratuitos sin que para ello deba solicitar ningún tipo de autorización al
    <span class="sub-bold-usuario">USUARIO</span>
    , en caso que esto suceda, se remitirá a cada uno de ellos un documento y/o notificación electrónica en la cual se
    detallen los cambios realizados a la plataforma y el impacto que los mismos tienen respecto de los presentes
    “Términos y condiciones” y/o la forma en que se prestará el servicio, recibido el mensaje o notificación electrónica
    por parte del
    <span class="sub-bold-usuario">USUARIO</span>
    , cada uno podrá aceptar por separado los cambios y modificaciones o negarse a hacerlo, en este último caso el
    <span class="sub-bold-usuario">USUARIO</span>
    que no acepte los cambios podrá dar por terminada su vinculación jurídica con
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    de manera unilateral y sin proceder al pago de perjuicios. En caso de que el
    <span class="sub-bold-usuario">USUARIO</span>
    de por terminada su relación jurídica por la razón antes mencionada,
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    tampoco se verá obligada al reconocimiento y pago de perjuicios, costos de cancelación y otros relacionados.
    <br />
    <span class="sub-bold">CALYPSO DEL CARIBE</span>
    SE RESERVA LA POTESTAD DE MODIFICAR EL PRESENTE DOCUMENTO PARA ADAPTARLO A NUEVOS REQUERIMIENTOS LEGISLATIVOS,
    JURISPRUDENCIALES Y TÉCNICOS.
    <br />
  </p>
</div>
