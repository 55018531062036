<div class="pagination-main" *ngIf="totalItems > 0">
  <button class="btn-pagination btn-before" (click)="onPreviousPage()" *ngIf="currentPage > 1">
    <figure>
      <img src="../../../../assets/icons/arrow_right_alt.svg" alt="icono flecha">
    </figure>
  </button>
  <div class="paginator-option" (click)="onRedirectToPage(page)" [class.page-active]="currentPage === page" *ngFor="let page of displayedPages">
    <p>{{ page }}</p>
  </div>
  <button class="btn-pagination" *ngIf="currentPage < totalPages" (click)="onNextPage()">
    <figure>
      <img src="../../../../assets/icons/arrow_right_alt.svg" alt="icono flecha">
    </figure>
  </button>
</div>
