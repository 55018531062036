<div class="container-fluid" *ngIf="product">
  <label class="title h5" (click)="backCategory(category)">
    <em class="fas fa-angle-left"></em> Volver
  </label>
  <div class="row">
    <div class="col-md-5 col-xs-12">
      <div class="card">
        <div class="card-body">
          <div id="carrouselImages" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="img-container">
                  <img alt="Imagen principal" [src]="product.imagen" width="290px" height="320px" />
                </div>
              </div>

              <div
                class="carousel-item"
                *ngFor="let imagesSeconds of product.imagenSegundaria"
              >
                <div class="img-container">
                  <img alt="Imagen secundaria" [src]="imagesSeconds" width="290px" height="320px" />
                </div>
              </div>
            </div>
            <a
              class="carousel-control-prev"
              href="#carrouselImages"
              role="button"
              data-slide="prev"
            >
              <em class="fas fa-angle-left"></em>

              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carrouselImages"
              role="button"
              data-slide="next"
            >
              <em class="fas fa-angle-right"></em>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div class="card-footer">
          Imagen de referencia
        </div>
      </div>
    </div>

    <div class="col-md-7 col-xs-12 col-info">
      <label class="name h2"> {{ product.nombre }}</label>
      <div class="row">
        <div class="col-12">
          <div class="variable-weight-tag" *ngIf="product.pesoVariable">
            Peso variable
          </div>
        </div>
      </div>
      <label class="price h1" *ngIf="product.pesoVariable">
        {{ pricePerPound(product.precio, product.pesoGramosVariable, product.pesoGramos) | customCurrency }} Libra
      </label>
      <label class="price h1" *ngIf="!product.pesoVariable">
        {{ product.precio | customCurrency }}
      </label>
      <label class="description h5">Descripción</label>

      <div class="row">
        <div class="col-12 description h6" *ngIf="product?.descripcion">
          {{ product.descripcion }}
        </div>
        <div class="col-6 description h6" *ngIf="product.pesoVariable">
          - Peso aproximado
        </div>
        <div class="col-6 description h6" *ngIf="!product.pesoVariable">
          - Peso neto
        </div>
        <div class="col-6 description h6" *ngIf="product.pesoVariable">
          {{ product.pesoGramosVariable / 1000 }} Kg | {{ (product.pesoGramosVariable / 1000) * 2 }} Lib.
        </div>
        <div class="col-6 description h6" *ngIf="!product.pesoVariable">
          {{ product.pesoGramos }} gr
        </div>

        <div class="col-6 description h6">- Precio por gramo</div>
        <div class="col-6 description h6">${{ costForGram | number }}/gr</div>

        <div class="col-6 description h6">- Empaque</div>
        <div class="col-6 package h6">{{ product.unidad }}</div>
      </div>

      <ng-container *ngIf="product && user.tipoCuenta !== 'B2B'">
        <div class="row row-btn-quantity" *ngIf="product.cobertura">
          <div *ngIf="product.add" class="ml-3 col-quantity">
            <button class="remove" (click)="removeProduct()">-</button>
            <button class="quantity">{{ product.quantity }}</button>
            <button class="add" (click)="moreOneProduct()">+</button>
          </div>
  
          <div *ngIf="!product.add" class="ml-3" (click)="addProduct()">
            <div type="button" class="btn card-button">
              + Agregar al carrito
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="user.tipoCuenta === 'B2B'">
        <div class="row-btn-quantity" style="gap: 8px;" *ngIf="product.cobertura">
          <div *ngIf="product.add" class="d-flex">
            <button class="add-b2b" (click)="removeQuantityProduct()">
              <img src="../../../../assets/icons/shopping-cart/delete.svg" alt="trash can icon">
            </button>
          </div>
            <input type="number" min="0" max="999" class="cantidad-b2b" [(ngModel)]="quantityProduct"
              placeholder="Cantidad" [value]="(product.quantity > 0) ? product.quantity : ''"
              (ngModelChange)="changeQuantityProduct()" maxlength="3" (input)="validateInput($event)">
          <button *ngIf="product.quantity === 0 || !checkProductIcon" (click)="addQuantityProduct()" type="button" class="btn add-b2b">
            <img src="../../../../assets/imgs/Img-our-products/add_product.svg" alt="add product icon">
          </button>
          <button *ngIf="product.quantity > 0 && checkProductIcon" type="button" class="btn add-b2b">
            <img src="../../../../assets/icons/shopping-cart/check-product.svg" alt="check product icon">
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="row" style="justify-content:left;" *ngIf="nutriInfo">
    <div class="col-md-5 col-xs-12">
      <label class="description-infoNutri h5">Información nutricional</label>
    </div>
  </div>
  <div class="row" style="justify-content:left;" *ngIf="nutriInfo">
    <div class="col-md-5 col-xs-12">
      <div class="card">
        <div class="card-body">
          <div id="carrouselInfoNutri" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">              
              <div
                class="carousel-item"
                *ngFor="let nutritionalImage of product.infoNutricional let i = index"
                [ngClass]="{'active': i == 0 }"
              >
                <div class="img-infoNutri">
                  <img alt="InfoNutricional" [src]="nutritionalImage" width="310px" height="340px" />
                </div>
              </div>
            </div>
            <a
              class="carousel-control-prev"
              href="#carrouselInfoNutri"
              role="button"
              data-slide="prev"
            >
              <em class="fas fa-angle-left"></em>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carrouselInfoNutri"
              role="button"
              data-slide="next"
            >
              <em class="fas fa-angle-right"></em>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-related-products [product]="product"></app-related-products>
  <app-video-product [product]="product"></app-video-product>
</div>
