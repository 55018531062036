<div class="d-flex my-3 brands-slider" *ngIf="brands?.length > 0">
  <button class="btn btn-outline-secondary flex-grow-0 btn-arrow" (click)="onScrollLeft()" [ngClass]="{'hide-arrows': containerWidth >= scrollWidth}">
    <em class="fas fa-angle-left"></em>
  </button>
    <div class="flex-grow-1 brand-container" #scrollable>
    <div *ngFor="let brand of brands" class="brand-btn" [class.btn-active]="brand === selectedBrand" (click)="selectBrand(brand)">
      <img src="{{brand.logo}}" alt="Logo de una marca" class="brand-logo" loading=lazy>{{brand.nombre}}
    </div>
  </div>
  <button class="btn btn-outline-secondary flex-grow-0 btn-arrow" (click)="onScrollRight()" [ngClass]="{'hide-arrows': containerWidth >= scrollWidth}">
    <i class="fas fa-angle-right"></i>
  </button>
</div>
