import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart/shopping-cart.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { Address, ShippingMethod, ShippingMethodType } from 'src/app/interfaces/salePoints.interface';
import $ from 'jquery';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { Category } from 'src/app/interfaces/products.interface';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
declare var $: $;

@Component({
  selector: 'app-base-header',
  templateUrl: './base-header.component.html',
  // template: ` <p>base-header works!</p> `,
  styleUrls: ['./base-header.component.scss'],
})
export class BaseHeaderComponent implements OnInit, OnDestroy {
  /** */
  activatedShippingMethod: ShippingMethod;
  /**The user is initialized and store*/
  public user;
  public productsNumber = 0;
  /** Save the status of the user according coverage  */
  public coverage: string;
  public subscribeShippingMethodGlobal: Subscription;
  constructor(
    protected router: Router,
    protected userService: UserService,
    protected productService: ProductsService,
    protected shoppingCartService: ShoppingCartService,
    protected authService: AuthService,
    protected localStorage: LocalstorageService,
    @Inject(PLATFORM_ID) protected platFormId: Object
  ) {}

  ngOnInit(): void {
    this.shoppingCartService.cart$.subscribe((products) => {
      this.productsNumber = products.length;
    });

    this.user = JSON.parse(this.localStorage.getItem('user'));

    this.activatedShippingMethod = this.userService.globalShippingMethod;

    this.coverage = this.localStorage.getItem('cobertura');
    this.subscribeShippingMethodGlobal = this.userService.sendGlobalShippingMethodObservable.subscribe(
      (shippingMethod) => {
        this.getActivatedShippingMethod(shippingMethod);
        this.coverage = this.localStorage.getItem('cobertura');
      }
    );
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platFormId)) {
      this.subscribeShippingMethodGlobal?.unsubscribe();
    }
  }
  /**
   * Function which sends the link that enters by parameters
   * @param option route to navigate
   */
  public navigate(option): void {
    this.router.navigate([option]);
  }

  /**
   * Navigate to specific category
   * @param category products category
   */
  public navigateCategory(category: Category): void {
    this.productService.onSendCategoryParam(category.nombre);
    this.router.navigate(['/categories', category.slug]);
  }

  /**
   * Open the address modal
   */
  async openAddressModal() {
    this.user = await JSON.parse(this.localStorage.getItem('user'));
    $('#openDeliverySelector').click();
  }

  /**
   * Get activated shippingMethod
   * @param user User
   */
  getActivatedShippingMethod(shippingMethod: ShippingMethod): void {
    this.activatedShippingMethod = shippingMethod;
    if (shippingMethod) {
      if (shippingMethod.address && !shippingMethod.address.cobertura) {
        $('#popover').popover('show');
      }
    }
  }

  /**
   * Deploy of the shopping cart
   * @memberof HeaderComponent
   */
  public onDeployShoppingCart(): void {
    this.shoppingCartService.changeDeployShoppingCart({
      data: { success: true },
    });
  }

  /**
   * Logout from the user session
   */
  public logout(): void {
    this.shoppingCartService.cleanShoppingCart();
    this.authService.logout();
    this.userService.onSendGlobalShippingMethod({type: ShippingMethodType.SHIPPING});
  }
}
