import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceRecetasService {

  private recipe = new BehaviorSubject('');
  public selectedRecipe = this.recipe.asObservable();

  constructor() { }

  getRecetas() {
    return firebase
      .firestore()
      .collection('recetas')
      .where('estado', '==', 'Activa')
      .get();
  }

  getReceta(id) {
    return firebase
      .firestore()
      .collection('recetas')
      .where('id', '==', id)
      .get();
  }

  getRecomendadas(categoria) {
    return firebase
      .firestore()
      .collection('recetas')
      .where('categoria', '==', categoria)
      .get();
  }

  getCategorias(){
    return firebase
      .firestore()
      .collection('categorias_recetas')
      .get();
  }

  setRecipe(recipe) {
    this.recipe.next(recipe);
  }
}
