<app-header></app-header>
<app-mobile-header></app-mobile-header>

<div class="recipe-container" *ngIf="receta">
  <div class="recipe-header">
    <label class="h6 cursor">Inicio / Recetas / <a (click)="redirectRecipe(receta.categoria)">{{ receta.categoria }}</a>
      / {{ receta.nombre }}</label>
    <h2 class="recipe-title">{{ receta.nombre }}</h2>
    <div class="row video-contianer " *ngIf="receta.video">
      <div class="col-12">
        <div class="video-responsive">
          <iframe [src]="receta?.video" allowfullscreen="allowfullscreen" class="border-radius20"
            [src]="getVideoIframe(receta?.video)" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="img-container" *ngIf="!receta.video">
      <div class="d-flex justify-content-center" *ngIf="receta.fotoSecundaria">
        <div class="border-radius" [ngStyle]="{
              'background-size': 'cover',
              'background-position': 'center',
              'background-image': receta.fotoSecundaria[idposImg] 
                ? 'url(' + receta.fotoSecundaria[idposImg] + ')' 
                : 'url(assets/img/no-foto-noticia.png)',
              'width': '80%'
              }">
        </div>
      </div>
    </div>
  </div>

  <div class="recipe-data">
    <div class="show-ingredients">
      <button class="btn btn-show-ingredients"
        onclick="document.getElementById('ingredientsCard').style.display = 'block'">Ver ingredientes</button>
    </div>
    <div class="recipe-info row">
      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 recipe-steps">
        <div class="preparation">
          <span>Preparación:</span>
        </div>
        <ng-container *ngFor="let paso of receta.pasos; index as i">
          <div class="recipe-step">
            <label class="text-2-sub">Paso {{ i + 1 }}</label>
            <p class="text">{{ paso.text }}</p>
          </div>
        </ng-container>

        <div class="custom-reco" *ngIf="receta.Recomendaciones">
          <label>Tip Calypso</label>
          <p>{{ receta.Recomendaciones }}</p>
        </div>

        <div class="slider-recipes" *ngIf="recetasRecomendadas.length > 0">
          <h2>Recetas que te pueden interesar:</h2>
          <div class="scrolling-wrapper" id="{{'sugestedRecipes' + 'i'}}">
            <button class="btn btn-outline-secondary button-left banner-btn" (click)="onScrollLeft('sugestedRecipes')"
              [class.no-view]="containerWith < bodyWidth">
              <em class="fas fa-angle-left"></em>
            </button>
            <div class="subcontainer-recipe" id="sugestedRecipes">
              <ng-container *ngFor="let recipe of recetasRecomendadas">
                <a href="/recetas/{{recipe.id}}">
                  <div class="recipe-card">
                    <img [src]="recipe.fotoSecundaria[0]" [alt]="recipe.nombre">
                    <div class="info-container">
                      <div class="recipe-title">{{ recipe.nombre }}</div>
                      <div class="row">
                        <div class="col-xl-6 col-sm-12 card-info">
                          <p class="recipe-subtitle">Tiempo de preparación</p>
                          <p class="recipe-info">{{ recipe.tiempoPreparacion }}</p>
                        </div>
                        <div class="col-xl-6 col-sm-12 card-info">
                          <p class="recipe-subtitle">Dificultad</p>
                          <p class="recipe-info">{{ recipe.nivel }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </ng-container>
            </div>
            <button class="btn btn-outline-secondary button-right banner-btn" (click)="onScrollRight('sugestedRecipes')"
              [class.no-view]="containerWith < bodyWidth">
              <em class="fas fa-angle-right"></em>
            </button>
          </div>
        </div>


      </div>

      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 recipe-ingredients" id="ingredientsCard">
        <div class="prod-custom">
          <div class="ingredients-header">
            <label>Ingredientes:</label>
            <span onclick="document.getElementById('ingredientsCard').style.display = 'none'">&times;</span>
          </div>
          <div class="ingredient-container">
            <ng-container *ngFor="let product of receta.productos">
              <ng-container *ngIf="product !== undefined">
                <app-product [product]="product" [spSelected]="true"></app-product>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="fondo-modal" *ngIf="modal">
  <div class="contenedor-terminos height-unset">
    <div class="row">
      <div class="col-12">
        <div class="modal-header">
          <div class="col-12">
            <label class="title-modal float-left"> Productos</label>
            <label class="title-modal float-right" (click)="modal = false">
              <i class="fas fa-times"></i></label>
          </div>
        </div>
        <div class="modal-body cuerpo">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-3" *ngFor="let product of receta.productos; let i = index">
                  <div class="row">
                    <div class="col-md-12 img-container">
                      <img [src]="product.imagen" class="img-fluidw img-modal" />
                    </div>
                    <div class="col-12 col-text pad-0">
                      <p class="text-title h3 text-center">
                        {{ product.precio | currency: "USD":"symbol":"1.0-0" }}
                      </p>
                      <p class="text-body h5 prod-name">
                        {{
                        product.nombre.length > 28
                        ? (product.nombre | slice: 0:28) + "..."
                        : product.nombre
                        }}
                      </p>

                      <p class="text-body h6 prod-name">
                        {{ product.pesoGramos }}gr - $
                        {{ product.precio / product.pesoGramos | number }}/gr
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <span class="float-right">
            <button type="button" class="btn btn-primary h5 agrergar-btn" (click)="placeProductsInCart()">
              Agregar al carrito
            </button></span>
        </div>
      </div>
    </div>
  </div>
</div>