<ng-container *ngIf="currentUser.tipoCuenta === 'B2B' && !detailUrl">
  <div class="recipe-info">
    <div class="title-info">Factura:</div>
    <div class="row">
      <div class="col-9">
        <div class="title-deliver">
          {{ currentUser.nombre }}
        </div>
      </div>
      <div class="col-3">
        <div class="price-text d-flex justify-content-end">
          NIT:{{ hideNit(currentUser.nit) }}
        </div>
      </div>
    </div>
  </div>
  <hr class="solid">
</ng-container>
<div class="title-info" *ngIf="currentUser.tipoCuenta === 'B2B' && !detailUrl">Costo total:</div>
<div class="row" [ngClass]="{ 'detail-space': currentUser.tipoCuenta === 'B2B' && detailUrl }">
  <div class="col-9">
    <div class="title-deliver" *ngIf="order">Precios por productos</div>
    <div class="title-deliver" *ngIf="currentUser.tipoCuenta === 'B2B' && !detailUrl">Subtotal:</div>
    <div class="title-deliver" *ngIf="currentUser.tipoCuenta === 'B2B' && !detailUrl">Total IVA:</div>
    <div class="title-deliver" *ngIf="!order">Costo domicilio</div>
  </div>
  <div class="col-3">
    <div class="d-flex justify-content-end price-text" *ngIf="currentUser.tipoCuenta === 'B2B' && productsInfo">
      {{ productsInfo.subtotal | customCurrency }}
    </div>
    <div class="d-flex justify-content-end price-text" *ngIf="currentUser.tipoCuenta === 'B2B' && productsInfo && !detailUrl">
      {{ productsInfo.totalTaxes | customCurrency }}
    </div>
    <div class="d-flex justify-content-end" *ngIf="!order">
      <span class="delivery-free" *ngIf="freeDelivery">Domicilio gratis</span>
      <span class="cost-delivery">{{ deliveryCost | customCurrency }}</span>
    </div>
  </div>
</div>
<div class="row" [ngClass]="{ 'detail-space': currentUser.tipoCuenta === 'B2B' && detailUrl }" *ngIf="order">
  <div class="col-9">
    <div class="title-deliver">
      Costo de domicilio
      <span class="delivery-free" *ngIf="order.valorDomicilio === 0 || freeDelivery">(Domicilio gratis!)</span>
    </div>
  </div>
  <div class="col-3">
    <div class="price-text d-flex justify-content-end">
      {{ order.valorDomicilio | customCurrency }}
    </div>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <div class="text-price" *ngIf="order">Total:</div>
    <div class="title-cost" *ngIf="!order"><strong>Total:</strong></div>
  </div>
  <div class="col-6 d-flex justify-content-end total-price" *ngIf="!order">
    {{ getTotal() | customCurrency }}
  </div>
  <div class="col-6 d-flex justify-content-end total-price" *ngIf="order">
    <div *ngIf="!order.aplicoCreditos">{{ order.valorPedido | customCurrency }}</div>
    <div *ngIf="order.aplicoCreditos">
      {{ order.totalcobrarConCreditos | customCurrency }}
    </div>
  </div>
</div>