import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitPairs',
})
export class SplitPairsPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      return value
        .filter((v, i) => i % 2 === 0)
        .map((v, i) => [value[i * 2], value[i * 2 + 1]]);
    }
    return value;
  }
}
