<div class="banner">
    <div class="HomeMainComponent">
        <div class="row main-row">
            <div class="logo-calypso">
                <img src="../../../assets/icons/header/calypso-logo-renew.svg" class="img" />
            </div>
            <h1 class="text-1">Política de Garantía y Cambios o Devoluciones de Alimentos</h1>
        </div>
    </div>
</div>
<div class="container px-0 px-md-1">
    <div class="doc-container modal-body mt-1 mt-md-5">
        <h5>
            <p class="title">POLÍTICA GARANTÍA Y CAMBIOS O DEVOLUCIONES DE ALIMENTOS CALYPSO DEL CARIBE S.A</p>
        </h5>
        <hr />
        <div class="politics-content">
            <p>
                La garantía está definida por el Estatuto del Consumidor Ley 1480 de 2011, artículo 5, numeral 5, como
                una obligación temporal, solidaria a cargo del productor y el proveedor, de responder por el buen estado
                del producto y la conformidad del mismo con las condiciones de idoneidad, calidad y seguridad legalmente
                exigibles o las ofrecidas. La garantía legal no tendrá contraprestación adicional al precio del
                producto. CARACTERÍSTICAS DE LA GARANTÍA PARA PRODUCTOS DE ALIMENTOS CALYPSO DEL CARIBE S.A
            </p>
            <ul>
                <li>
                    <strong>Temporalidad:</strong> La garantía de cualquier bien o servicio tiene un marco de
                    temporalidad definido por
                    la ley o de manera convencional por las partes. Es decir, la obligación de responder por el buen
                    estado del producto no es eterna (a menos que así lo hayan establecido de manera contractual). La
                    garantía de nuestros productos tendrá una vigencia de 13 días antes la fecha de vencimiento o
                    expiración, por tratarse de productos perecederos (artículo 8, Ley 1480 de 2011).
                </li>
                <li>
                    <strong>Solidaridad:</strong> El consumidor pueda solicitar la efectividad de la garantía bien al
                    productor, bien al
                    comercializador. En tal sentido, si el consumidor compra un producto marca CALYPSO en un almacén de
                    cadena, podrá solicitar que se le responda por el buen estado de este de manera directa a CALYPSO
                    S.A.S, por medio de los canales dispuestos para ello.
                </li>
                <li>
                    <strong>Idoneidad:</strong> Aptitud del producto para satisfacer la necesidad o necesidades para las
                    cuales ha sido
                    producido o comercializado. Se debe entender como la “adecuada función del bien”. Las condiciones de
                    idoneidad de los productos CALYPSO DEL CARIBE estarán marcadas básicamente por unos buenos procesos
                    de refrigeración, conservación y empacado.
                </li>
                <li>
                    <strong>Calidad:</strong> Es el elemento preponderante en la garantía.
                    <br>
                    Condición en que un producto cumple con las características inherentes y las atribuidas por la
                    información que se suministre sobre él. Se hace vital cumplir de manera fehaciente con lo dispuesto
                    en los productos que se comercializan.
                </li>
                <li>
                    <strong>Seguridad:</strong> Nuestra compañía cuenta con las medidas sanitarias necesarias para que
                    no se presenten
                    riesgos irrazonables para la salud o integridad de los consumidores. TIEMPO DE LA GARANTÍA La
                    garantía de nuestros productos tendrá una vigencia hasta la fecha de vencimiento o expiración,
                    (artículo 8, Ley 1480 de 2011)
                </li>
            </ul>
            <p class="title">EXONERACIÓN DE RESPONSABILIDAD DE LA GARANTÍA</p>
            <p>
                El productor o proveedor se exonerará de la responsabilidad que se deriva de la garantía, cuando
                demuestre que el defecto proviene de El uso indebido del bien por parte del consumidor que no atendió
                las recomendaciones de uso impresa en los empaques.
            </p>
            <p class="title">REQUISITOS DE LA GARANTÍA El trámite de la garantía tiene el cumplimiento de unas
                condiciones legales:</p>
            <ol>
                <li>El consumidor está obligado a informar el daño que tiene el producto.</li>
                <li>El trámite deberá ser realizado dentro de la vigencia de vencimiento o expiración del producto</li>
                <li>Sin consumo parcial o total, en su empaque original y, con las etiquetas adheridas al mismo.</li>
                <li>Conservar la cadena de frio</li>
                <li> Poner el bien a disposición del expendedor en el mismo sitio en el que le fue entregado al
                    adquirirlo en los puntos de atención dispuestos para el efecto, a elección del consumidor, Alimentos
                    CALYPSO DEL CARIBE S.A, se hace responsable de su transporte en caso de ser necesario.
                    <br>
                    <strong>Nota aclaratoria:</strong>
                    En los casos que Alimentos CALYPSO DEL CARIBE S.A determine que por motivos logísticos no es posible
                    la recogida del producto, el cliente debe suministrar registro fotográfico que evidencie el daño del
                    producto, lote y fecha de vencimiento.
                </li>
                <li>la fecha de la compra o de la celebración del contrato correspondiente. </li>
                <li>Al solicitar cambio o devolución del producto, el cliente debe presentar y/o informar sobre:
                    <ul>
                        <li>Fecha de la realización de la compra o de la celebración del contrato correspondiente.</li>
                        <li>Factura de venta (si dispone de ella), y/o número de la factura de venta.</li>
                        <li>Cédula y/o número de identificación del cliente.</li>
                    </ul>
                </li>

            </ol>
            <p class="title">OPCIONES DEL CONSUMIDOR PARA TÉRMINOS DE LA GARANTIA El artículo 11 de la ley 1480 de 2011
                establece que:</p>
            <p>
                “Como regla general, reparación totalmente gratuita de los defectos del bien, así como su transporte, de
                ser necesario, y el suministro oportuno de los repuestos. Si el bien no admite reparación, se procederá
                a su reposición o a la devolución del dinero”.
            </p>
            <p>
                Alimentos CALYPSO DEL CARIBE SA dispone del siguiente proceso para hacer efectiva la garantía legal,
                siempre y cuando el cliente cumpla con los requisitos de la garantía.
            </p>
            <p>
                La primera instancia es reposición de producto, de no ser posible cumplir con las similares
                características o especificaciones técnicas del producto, se procederá con la devolución del dinero:
            </p>
            <p>
                <strong>Reposición de producto:</strong>
                Alimentos CALYPSO DEL CARIBE SA entregará producto de similares características o especificaciones
                técnicas, las cuales en ningún caso podrán ser inferiores a las del producto que dio lugar a la
                garantía, el consumidor podrá reclamar dicho producto en los PUNTOS de VENTA de propiedad de Alimentos
                La CALYPSO DEL CARIBE SA o se enviará por transportadora cuando sea en ciudades diferentes a la
                ubicación de los puntos.
                <br>
                Si no es posible la reposición del producto se procede con la devolución del dinero
            </p>
            <p>
                <strong>RESPONSABILIDAD POR DAÑOS POR PRODUCTO DEFECTUOSO El Artículo 20 de la ley 1480 de 2011
                    indica:</strong>
                <br>
                Responsabilidad por daño por producto defectuoso. El productor y el expendedor serán solidariamente
                responsables de los daños causados por los defectos de sus productos, sin perjuicio de las acciones de
                repetición a que haya lugar.
            </p>
            <p>
                Para efectos de este artículo, cuando no se indique expresamente quién es el productor, se presumirá
                como
                tal quien coloque su nombre, marca o cualquier otro signo o distintivo en el producto.
                <br>
                Como daño, se entienden los siguientes:
            </p>
            <ol>
                <li>Muerte o lesiones corporales, causadas por el producto defectuoso;</li>
                <li>
                    Los producidos a una cosa diferente al producto defectuoso, causados por el producto defectuoso. Lo
                    anterior, sin perjuicio de que el perjudicado pueda reclamar otro tipo de indemnizaciones de acuerdo
                    con la ley.
                </li>
            </ol>
            <p>
                <strong>Alimentos CALYPSO DEL CARIBE SA dispone del siguiente proceso para hacer el respectivo análisis
                    de la responsabilidad por daños de producto defectuoso:</strong>
                <br>
                De acuerdo con el artículo 21 de la ley 480 de 2011, para determinar la responsabilidad, el afectado
                deberá demostrar el defecto del bien, la existencia del daño y el nexócausal entre este y aquel.
                <br>
                Para esto, deberá hacer llegar a Alimentos CALYPSO DEL CARIBE SA, copia de dictamen médico que indique
                la causa del daño asociado al producto.
                <br>
                El procedimiento podrá ser realizado por el consumidor a través de los canales de atención dispuestos
                por CALYPSO DEL CARIBE SA para tal fin.
            </p>
            <p class="title">POLÍTICA REVERSIÓN DEL PAGO</p>
            <p>
                Es un mecanismo legal que protege los derechos de los consumidores o clientes que compran productos o
                servicios a través de comercio electrónico y canales no presenciales, y a su vez, realizan el pago con
                cualquier instrumento de pago electrónico.
            </p>
            <p>
                Este mecanismo permite solicitar el reembolso de lo pagado, a cambio de la devolución del producto
                comprado (cuando aplique), siempre que se presente 1 de los 5 casos que mencionamos a continuación y se
                cumplan algunos requisitos.
            </p>
            <p class="title">¿EN QUÉ CASOS PROCEDE? En conformidad con la Ley 1480 de 2011 Art 51, la reversión del pago
                procede cuando:</p>
            <ol>
                <li>Cuando el consumidor haya sufrido un fraude.</li>
                <li>Cuando el cargo o pago por los productos corresponda a una operación que no haya sido solicitada por
                    el consumidor.</li>
                <li>Cuando el producto comprado no haya sido entregado en la fecha programada de entrega que el cliente
                    seleccionó al realizar su compra.</li>
                <li>Cuando el producto entregado no corresponda al comprado, no cumpla con las características
                    atribuidas por la información que se suministró sobre éste o las que son propias del mismo.</li>
                <li>Cuando el producto entregado se encuentre defectuoso. (Ver nuestra política de garantía).</li>
            </ol>
            <p class="title">REQUISITOS PARA REVERSIÓN DEL PAGO</p>
            <ol>
                <li>Que la compra se haya realizado por un canal de venta no presencial.</li>
                <li>La compra debe haberse pagado utilizando tarjeta de crédito, débito o cualquier otro instrumento de
                    pago electrónico.</li>
                <li>Presentar la solicitud de reversión del pago oportunamente.</li>
            </ol>
            <p>
                Es decir, dentro de los cinco (5) días hábiles siguientes a:
            </p>
            <ul>
                <li>La fecha en que el cliente se enteró de que fue víctima de una operación fraudulenta o un pago que
                    no fue solicitado por él.</li>
                <li>La fecha en que el cliente debió recibir el producto y no lo recibió.</li>
                <li>La fecha en que el cliente recibió el producto, el cual que resultó defectuoso o que no correspondía
                    al que compró.</li>
                <li>La solicitud de Reversión del Pago la puede realizar el titular de la compra o el titular del medio
                    de pago a través de nuestros canales de atención, realizando el procedimiento correspondiente.</li>
            </ul>
            <p class="title">RECUERDE QUE:</p>
            <ul>
                <li>
                    Dentro de este mismo término, el cliente deberá notificar al Banco que presentó una reclamación ante
                    el comercio.
                </li>
                <li>
                    Una vez recibida la notificación del cliente, ALIMENTOS CALYPSO DEL CARIBE SA y la entidad
                    financiera cuentan con quince (15) días hábiles para hacer efectiva la solicitud de reversión de
                    pago.
                </li>
                <li>
                    Existe la posibilidad de cargar definitivamente la transacción reclamada, cuando medie una decisión
                    administrativa o jurisdiccional en contra del consumidor que ordenó injustificadamente la Reversión
                    del Pago.
                </li>
                <li>
                    Nuestros productos son alimentos perecederos; por tanto, en caso de recibir un producto que resulte
                    defectuoso o que no corresponda al que compró, por favor informarnos de manera inmediata por los
                    canales de atención dispuestos por CALYPSO DEL CARIBE SA para tal fin, tanto de manera virtual como
                    presencial.
                </li>
            </ul>
            <p>
                El procedimiento podrá ser realizado por el consumidor a través de los canales de atención dispuestos
                por ALIMENTOS CALYPSO DEL CARIBE SA para tal fin.
            </p>
        </div>
    </div>
</div>