<div class="scrollable" #containerCatProds id="div-scroll">
  <div class="container-fluid">
    <!-- Se deja comentado ya que se puede utilizar en funcionalidad -->
    <!-- <app-brands
      [brandNames]="brandNames"
      [selectedBrand]="selectedBrand"
      (brandChange)="filterByBrand($event)"
    ></app-brands> -->
    <label class="title h3">
      {{
      categorySelected.nombre === "Tortillas y panificacion"
      ? "Tortillas y panificación"
      : categorySelected.nombre
      }}
    </label>
    <div class="row no-gutters">
      <div class="col-product" *ngFor="let product of productsShow | productSearchPipe: searchProduct">
        <ng-container *ngIf="user">
          <app-product 
            [product]="product" 
            id="{{ product.nombre }}" 
            [spSelected]="true" 
            [home]="true" 
            [isB2B]="user.tipoCuenta === 'B2B'">
          </app-product>
        </ng-container>
      </div>
      <div class="text-center col-12" *ngIf="
          (productsShow | productSearchPipe: searchProduct)?.length === 0 &&
          searchProduct != ''
        ">
        <div class="w-100">
          <img class="select-img d-block m-auto" src="assets/imgs/img-about/distribuidores/seleccionar.png"
            alt="Logo en blanco y negro de Calypso" />
          <span class="text d-block">No hay resultados para tu búsqueda.</span>
        </div>
      </div>
    </div>
    <div *ngIf="categorySelected.slug !== 'todos'">
      <label class="title h3 mt-3">Más Productos</label>
      <div class="row no-gutters">
        <div class="col-product" *ngFor="let product of allCategories.productos">
          <app-product [product]="product" id="{{ product.nombre }}" [home]="true"></app-product>
        </div>
      </div>
    </div>
  </div>
</div>