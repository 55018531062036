<div class="container-fluid" style="padding-top: 70px; padding-bottom: 40px;">
  <div class="row main-row max" style="padding-left: 40px; padding-right: 40px;">
    <div class="col-sm-12 col-lg-4">
      <div class="row">
        <div class="col-12 resp-cel">
          <div class="row row-resp" style="max-width: 400px;">
            <div class="col-6 movile-social">
              <h4 class="text redes-title">
                Redes sociales
              </h4>
            </div>
            <div class="row">
                <div class="col-4">
                  <a href="https://www.facebook.com/TiendasCalypsoSA" target="_blank">
                    <img
                      src="../../../assets/imgs/imgs-tests/facebook.png"
                      class="icon icon-redes"
                    />
                  </a>
                </div>
                <div class="col-4">
                  <a href="https://www.instagram.com/tiendascalypso/" target="_blank">
                    <img
                      src="../../../assets/imgs/imgs-tests/instagram.png"
                      class="icon icon-redes"
                    />
                  </a>
                </div>
                <div class="col-4">
                  <a href="https://youtube.com/@TiendasCalypso." target="_blank">
                    <img
                      src="../../../assets/imgs/imgs-tests/youtube.png"
                      class="icon icon-redes"
                    />
                  </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-4 icon-col d-flex flex-column align-items-center" style="align-self: center;">
      <div class="movile-h6 d-flex justify-content-center">
        <h5 class="text contact-title d-flex justify-content-center">
          Contáctanos
        </h5>
      </div>
      <div class="row row-contact justify-content-center">
        <div class="col-3 movile-h3">
          <h6 class="text movile-contact">
            Servicio al cliente:
          </h6>
          <h6 class="text movile-contact">
            <a href="mailto:servicio.cliente@calypso.la" target="_blank" style="color: white;">servicio.cliente@calypso.la</a>
          </h6>
        </div>
        <div class="col-3 movile-h3">
          <h6 class="text movile-contact">
            Canal de denuncias:
          </h6>
          <h6 class="text movile-contact">
            <a href="mailto:transparencia@calypso.la" target="_blank" style="color: white;">transparencia@calypso.la</a>
          </h6>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 col-sm-6 colom-col">
      <p>
        <img
          src="../../../assets/imgs/imgs-tests/colombia.png"
          class="icon"
        />
      </p>
      <h6 class="text text-resp">Orgullosamente Colombianos</h6>
    </div>
  </div>
  <div class="col-12">
    <hr>
  </div>
  <div class="col-12">
    <h6 class="text movile-contact" style="text-align: center;">
      <a href="" target="_blank">Copyright © Calypso Del Caribe</a> |
      <a href="https://drive.google.com/file/d/1qPaJefidCtKumgK8VKHuoFJRbo1Q0FMu/view" target="_blank" rel="noopener">Política de SAGRILAFT</a> |
      <a href="/terminos-y-condiciones" target="_blank">Términos y condiciones</a>
        |
      <a href="/politica-de-datos" target="_blank">Políticas de privacidad</a> |
      <a href="/politicas-garantias" target="_blank">Políticas de garantías</a>
    </h6>
  </div>
</div>
