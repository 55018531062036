import { Injectable } from '@angular/core';
import { Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalstorageService } from '../../services/localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardLogin implements CanActivateChild {
  constructor(
    private router: Router,
    private localStorage: LocalstorageService,
  ) {}
  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    let userLogged = JSON.parse(this.localStorage.getItem('userLogged'));
    if (userLogged) {
      this.router.navigate(['user-profile/orders-info']);
      return false;
    } else {
      return true;
    }
  }
}
