import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SalePointsService {
  baseURL = environment.backendUrl;
  headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient) {}
  /**
   * Get the sale points from firebase
   */
  getSalePoints() {
    try {
      return firebase.firestore().collection('puntosDeVentaIntegracion');
    } catch (error) {
      console.log(`Error getting sale points: ${error}`);
    }
  }

  getSalePointByWarehouse(bodega) {
    try {
      return firebase.firestore().collection('puntosDeVentaIntegracion').where('bodega', '==', bodega).get();
    } catch (error) {
      console.log(`Error getting sale point by warehouse: ${error}`);
    }
  }

  /**
   * Gets the salePoint with the specified ID
   * @param id id of the SalePoint
   */

  getSalePointByID(id) {
    try {
      return firebase.firestore().collection('puntosDeVentaIntegracion').where('id', '==', id).get();
    } catch (error) {
      console.log(`Error getting sale point by id: ${error}`);
    }
  }

  getHollidays(): any {
    return this.httpClient.get(this.baseURL + 'api/get-holiday', { headers: this.headers });
  }
}

export class SignalModalEmitterService {
  constructor() { }
  static onChange: EventEmitter<String> = new EventEmitter<String>();
  static sendSignal(signal: String) {
    this.onChange.emit(signal);
  }
}
