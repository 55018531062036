<div class="mt-3 mb-2">
  <img
    class="star-icon"
    *ngFor="let index of maxStartsToShow"
    (click)="onSelectedStar(index)"
    [ngClass]="{selected: index <= selectedStars }"
    [src]="index <= selectedStars ? '../../../assets/icons/order-tracking/estrella-activo.svg' : '../../../assets/icons/order-tracking/estrella-inactivo.svg'"
    alt="Estrella"
>
</div>
