import { Pipe, PipeTransform } from '@angular/core';
import { Product } from 'src/app/interfaces/products.interface';
import { GlobalFunctionsService } from '../../core/services/global-functions/global-functions.service';
import * as _ from 'lodash';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
@Pipe({
  name: 'productSearchPipe',
})
export class ProductSearchPipePipe implements PipeTransform {
  constructor(
    private globalFunctions: GlobalFunctionsService,
    private localStorage: LocalstorageService
  ) {}
  
  transform(products: any[], search: string, salePoint: String): Product[] {
    let shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod'));
    let bodega

    if(shippingMethod === null || shippingMethod == undefined){
      bodega = ''
    }else if(shippingMethod.type === 'Domicilio'){
      if(shippingMethod.address) bodega = shippingMethod.address.inventario
    }
    else{
      bodega=shippingMethod.salePoint.bodega;
    }

    for (let product of products) {
      product.nombreSearch = this.globalFunctions.normalizedText(
        product.nombre
      );
    }
    if (!search || search == ''){
      var doubleFilteredProducts = []
      if(bodega === ''){
        doubleFilteredProducts = products
      }else{
        products.forEach(function(element){
          if(element['inventarioBodega'][bodega] !== 0 ){
            doubleFilteredProducts.push(element)
          }
        })
      }
      doubleFilteredProducts=_.orderBy(doubleFilteredProducts, ['index'], ['asc'])
      return doubleFilteredProducts;
    } 
    else {
      var filteredProducts = products.filter((product) =>
      product.nombreSearch.includes(search));
      var doubleFilteredProducts = []
      if(bodega === ''){
        doubleFilteredProducts=filteredProducts
      }else{
        filteredProducts.forEach(function(element){
          if(element['inventarioBodega'][bodega] !== 0 ){
            doubleFilteredProducts.push(element)
          }
        })
      }
      doubleFilteredProducts=_.orderBy(doubleFilteredProducts, ['index'], ['asc'])
      return doubleFilteredProducts
    }
  }
}
