import { AbstractControl, ValidatorFn } from '@angular/forms';

/** Control standard for cellphone numbers*/
export const cellPattern: any = /^[0-9]{10}$/;
/**Control pattern for emails */
export const emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/** Control pattern for nit */
export const nitPattern: any = /^(?![-\s])\d{5,13}(?:-\d{1,7})?$/;
/** Control pattern for name */
export const namePattern: any = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
/** Control pattern for nameBussines */
export const nameBussinesPattern: any = /^[a-zA-Z0-9]+([ ][a-zA-Z0-9]*){0,2}$/;
/** Control pattern for codeReferral */
export const referralPattern: any = /^\d{8,8}$/;
/** Template for printing quotes with calculator */
function createPrinterTemplate(inputValue, outputValue, miniPrinter:boolean) {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    if (!miniPrinter) {
        return `
        <!DOCTYPE html>
        <html lang="es">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Cotización Calypso</title>
          <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet">
          <style>
            body {
              font-family: "Poppins", sans-serif;
              background-color: #f4f4f4;
              margin: 0;
              padding: 0;
            }
            
            .logo {
              width: 150px;
            }
            
            .table th, .table td {
              vertical-align: middle;
            }
            
            .table th {
              background-color: #f8f9fa;
            }
            
            .text-right p {
              margin: 0;
            }
          </style>
        </head>
        <body>
          <div class="container my-4">
            <div class="row">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <img src="../../../assets/icons/header/calypso-logo-renew.svg" alt="Calypso Logo" class="logo">
                <div class="text-right">
                  <p>Fecha: ${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}</p>
                  <p>${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}</p>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <h2 class="text-center">DATOS INGRESADOS</h2>
                <table class="table table-bordered text-center mt-3">
                  <thead class="thead-light">
                    <tr>
                      <th>Consumo mensual (kg)</th>
                      <th>Puntos de venta</th>
                      <th>Frecuencia semanal</th>
                      <th>Valor centralizado</th>
                      <th>Valor descentralizado</th>
                      <th>Precio por kg en pesos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>${inputValue.consumoMesKg}</td>
                      <td>${inputValue.PDV}</td>
                      <td>${inputValue.frecuenciaSemanal}</td>
                      <td>$${(inputValue.valorCentralizado !== null) ? inputValue.valorCentralizado : '0' }</td>
                      <td>$${(inputValue.valorDescentralizado !== null) ? inputValue.valorDescentralizado : '0' }</td>
                      <td>${inputValue.precioKgEnPesos}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <h2 class="text-center">NUESTRA COTIZACIÓN</h2>
                <table class="table table-bordered text-center mt-3">
                  <tbody>
                    <tr>
                      <th>Semanal</th>
                      <td>${outputValue.semanal}</td>
                    </tr>
                    <tr>
                      <th>Por entrega según frecuencia</th>
                      <td>${outputValue.entregaFrecuencia}</td>
                    </tr>
                    <tr>
                      <th>Por punto de entrega</th>
                      <td>${outputValue.porPDV}</td>
                    </tr>
                    <tr>
                      <th>Costo por entrega</th>
                      <td>${outputValue.costoEntrega}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-right mt-3">
                  <p><strong>Costo mensual</strong> $${outputValue.costoMensual}</p>
                  <p><strong>Costo anual</strong> $${outputValue.costoAnual}</p>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12 text-center">
                <p>Terminos & Condiciones</p>
                <p>Copyright © Calypso Del Caribe</p>
              </div>
            </div>
          </div>
          <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"></script>
          <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
        </body>
        </html>    
        `;
    } else {
        return  `
        <!DOCTYPE html>
        <html lang="es">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Cotización Calypso</title>
          <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet">
          <style>
            body {
                font-family: Arial, sans-serif;
                background-color: #f9f9f9;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                margin: 0;
            }
            .container {
                background-color: white;
                width: 300px;
                padding: 20px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                text-align: center;
            }
            .logo {
                width: 80px;
                margin: 0 auto;
            }
            .header {
                margin-top: 10px;
                margin-bottom: 20px;
            }
            .header p {
                margin: 5px 0;
            }
            .quote {
                text-align: left;
            }
            .quote h2 {
                margin-bottom: 20px;
            }
            .quote .item {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
            }
            .quote .item .value {
                font-weight: bold;
            }
            .footer {
                margin-top: 20px;
                font-size: 12px;
                color: #999;
            }
        </style>
        </head>
        <body>
          <div class="container">
              <img src="../../../assets/icons/header/calypso-logo-renew.svg" alt="Calypso Logo" class="logo">
              <div class="header">
                <p>Fecha: ${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}</p>
                <p>${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}</p>
              </div>
              <div class="quote">
                  <h2>Cotización</h2>
                  <div class="item">
                      <span>Consumo Mensual (kg)</span>
                      <span class="value">${inputValue.consumoMesKg}</span>
                  </div>
                  <div class="item">
                      <span>Puntos de venta</span>
                      <span class="value">${inputValue.PDV}</span>
                  </div>
                  <div class="item">
                      <span>Frecuencia Semanal</span>
                      <span class="value">${inputValue.frecuenciaSemanal}</span>
                  </div>
                  <div class="item">
                      <span>Valor Centralizado</span>
                      <span class="value">$${inputValue.valorCentralizado}</span>
                  </div>
                  <div class="item">
                      <span>Valor Descentralizado</span>
                      <span class="value">$${inputValue.valorDescentralizado}</span>
                  </div>
                  <div class="item">
                      <span>Precio por kg en pesos</span>
                      <span class="value">$${inputValue.precioKgEnPesos}</span>
                  </div>
                  <div class="item">
                      <span>Semanal</span>
                      <span class="value">${outputValue.semanal}</span>
                  </div>
                  <div class="item">
                      <span>Por entrega según frecuencia</span>
                      <span class="value">${outputValue.entregaFrecuencia}</span>
                  </div>
                  <div class="item">
                      <span>Por punto de entrega</span>
                      <span class="value">${outputValue.porPDV}</span>
                  </div>
                  <div class="item">
                      <span>Costo por entrega</span>
                      <span class="value">$${outputValue.costoEntrega}</span>
                  </div>
                  <div class="item">
                      <span>Costo mensual</span>
                      <span class="value">$${outputValue.costoMensual}</span>
                  </div>
                  <div class="item">
                      <span>Costo anual</span>
                      <span class="value">$${outputValue.costoAnual}</span>
                  </div>
              </div>
              <div class="footer">
                  Copyright © Calypso Del Caribe
              </div>
          </div>
        </body>
        </html>    
        `;
    }
}

function getPricePerPound(price: number, weightGramsVariable: number, weightGrams: number) {
  const pesoEnKg = weightGramsVariable / 1000;
  const precioEnKg = (price / weightGrams) * weightGramsVariable
  const precioPorLibra = precioEnKg / (pesoEnKg * 2);    
  return precioPorLibra;
}

function passwordMatchValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const newPassword = control.get('newPassword');
    const confirmPassword = control.get('confirmPassword');
    if (newPassword && confirmPassword && newPassword.value !== confirmPassword.value) {
      return { 'passwordMismatch': true };
    }
    return null;
  };
}

function hideIdInfo(id: number): string {
  const idStr = id.toString();
  const maskedId = '****' + idStr.slice(-3);
  return maskedId;
}

export { createPrinterTemplate, getPricePerPound, passwordMatchValidator, hideIdInfo };
