import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/interfaces/products.interface';

@Component({
  selector: 'app-products-search-container',
  templateUrl: './products-search-container.component.html',
  styleUrls: ['./products-search-container.component.scss'],
})
export class ProductsSearchContainerComponent implements OnInit {
  @Input() searchProduct: string;
  @Input() allProducts: Product[];
  constructor() {}

  ngOnInit(): void {}
}
