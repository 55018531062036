import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'comensales'
})
export class ComnsalesPipe implements PipeTransform {

  transform(value: string, cantidad: number): any {
    return parseInt(value) * cantidad;
  }

}
