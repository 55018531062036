import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/core/services/user/user.service';
import { User } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-cards-list',
  templateUrl: './cards-list.component.html',
  styleUrls: ['./cards-list.component.scss']
})
export class CardsListComponent implements OnInit {
  user: User;
  cardSelected = null;
  @Output() selectedCard = new EventEmitter<{}>();

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.userService.getUserIdLogged().subscribe((userId) => {
      this.userService.getUserbyId(userId).get().then((getUserResult) => {
        this.user = getUserResult.data() as User;
      });
    });
  }

  onCardAdded(added: boolean): void {
    if (added) {
      this.userService.getUserIdLogged().subscribe((userId) => {
        this.userService.getUserbyId(userId).get().then((getUserResult) => {
          this.user = getUserResult.data() as User;
        });
      });
    }
  }

  onSelectCard(card): void {
    this.cardSelected = card;
    this.selectedCard.emit(card);
  }
}
