import { Component, OnInit, Output, EventEmitter, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart/shopping-cart.service';
import { Product } from 'src/app/interfaces/products.interface';
import { Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { isPlatformBrowser } from '@angular/common';
import { address, User } from 'src/app/interfaces/user.interface';
import { UserService } from 'src/app/core/services/user/user.service';
import { getPricePerPound } from 'src/app/global/utils';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
  /** Communication to close the modal */
  @Output() closeShoppingCard: EventEmitter<any> = new EventEmitter();
  /** products of the shopping cart */
  public products: Product[] = [];
  // The price the all products of the shopping cart
  public priceProducts = 0;
  // The warehouse
  public warehouse: string;
  // Subscription to the products in the shopping cart
  public products$: Observable<any>;
  // Coverage of the user location
  public coverage: string;
  // total out of stock
  public totalOutOfstock = false;
  // is variable
  public isVariableWeight = false;
  public shippingMethod
  public citySelected
  public subscriber
  /**
   * Current user
   */
  public user
  public isPlan3000 = false
  // is distribuidor
  public isDistribuidor = false;
  /** Validates if the user is checking out. */
  public validateCheckout: boolean = false;

  constructor(
    private shoppingCartService: ShoppingCartService,
    private userService: UserService,
    private router: Router,
    private productService: ProductsService,
    private localStorage: LocalstorageService,
    @Inject(PLATFORM_ID) protected platFormId
  ) {
    this.warehouse =
      this.userService.globalShippingMethod?.address?.inventario ||
      this.userService.globalShippingMethod?.salePoint?.bodega;
    // Nos subscribimos a los productos agregados. Realizamos un async pipe para las desubscripción.
    this.products$ = this.shoppingCartService.cart$.pipe(
      map((products) => {
        this.getPriceProducts(products);
        this.stockTotal(products);
        this.products = products;        
        return products.length;
      })
    );
  }

  ngOnDestroy(): void { }

  ngOnInit(): void {
    // let size = this.verifSize();
    // let cart = document.getElementById('cart');
    // cart.style.height = (size[1] - 187).toString();
    if (isPlatformBrowser(this.platFormId)) {
      this.coverage = this.localStorage.getItem('cobertura');
      this.user = JSON.parse(this.localStorage.getItem('user')) as User;
      if (this.user) {
        if (this.user.idListaPrecios && this.user.idPlan3000) {
          this.isPlan3000 = true
        }
      }
    }
    this.subscriber = this, this.shoppingCartService.cart.subscribe(
      (value) => {
        this.getPriceProducts(value);
        this.stockTotal(value);
        this.products = value;
        this.products.forEach((element) => {
          element.quantity = (element.quantity) ? element.quantity : element.cantidad;
          if (this.user.idListaPrecios) {
            if (!Object.keys(element.listaPrecios).includes(this.user.idListaPrecios)) {
              // The product does not have the price list associated with the user, deletes it.
              this.deleteProduct(this.products.indexOf(element));
            }
          }        
        });
      }
    );
    if (this.router.url === '/checkout') this.validateCheckout = true;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.onCloseShoppingCart();
      }
    })
    this.shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod',));
    this.citySelected = JSON.parse(this.localStorage.getItem('citySelected'));
    this.isDistribuidor = this.productService.verifyDistribuidor(this.shippingMethod, this.citySelected)
  }

  /**
   * Close the shopping cart
   */
  public onCloseShoppingCart(): void {
    this.closeShoppingCard.emit(this.priceProducts);
  }

  /**
   * It says if the products do not have stock
   * @param products;
   */
  public stockTotal(products) {    
    let count = 0;
    products.forEach((element) => {
      if (element.inventarioBodega[this.warehouse] < element.quantity) {
        count++;
      }
    });
    if (count === products.length) {
      this.totalOutOfstock = true;
    }
  }

  /**
   * less product
   *
   * @param position postion of the product in the array
   */
  public onLessProduct(position): void {
    this.products[position].quantity--;
    if (this.products[position].quantity === 0) {
      this.isVariableWeight = false;
      this.isDistribuidor = false;
      this.products[position].add = false;
    }
    this.shoppingCartService.addProductsCart(this.products[position]);

    // Validates if there are products in the cart for checkout
    this.products$.subscribe((products) => {
      if (products === 0 && this.router.url === '/checkout') {
        this.router.navigateByUrl('/');
        this.onCloseShoppingCart();
      }
    })
  }

  /**Seasonal Holidays 2022
Camera search

   * More product
   * @param position postion of the product in the array
   */
  public onMoreProduct(position): void {
    this.products[position].quantity++;
    this.shoppingCartService.addProductsCart(this.products[position]);
  }

  /**
   * Delete producto of the shopping cart
   * @param position postion of the product in the array
   */
  public deleteProduct(position): void {
    this.isVariableWeight = false;
    this.isDistribuidor = false;
    this.products[position].quantity = 0;
    this.products[position].add = false;
    this.shoppingCartService.addProductsCart(this.products[position]);

    // Validates if there are products in the cart for checkout
    if (this.products.length === 0 && this.router.url === '/checkout') {
      this.router.navigateByUrl('/');
      this.onCloseShoppingCart();
    }
  }

  /**
   * Clean shopping cart
   */
  public onEmptyShoppingCart(): void {
    this.isVariableWeight = false;
    this.isDistribuidor = false;
    this.shoppingCartService.emptyShoppingCart();

    // Redirect to homepage if the cart is emptied
    if (this.router.url === '/checkout') {
      this.router.navigateByUrl('/');
      this.onCloseShoppingCart();
    }
  }

  /**
   * Summation of the shopping cart
   * @param Products of the shopping cart
   */
  public getPriceProducts(products): void {
    let price = 0;    
    products.forEach((element) => {
      const quantity = (element.quantity) ? element.quantity : element.cantidad;
      if ( !this.warehouse || !this.isNoStock(element) ) {
        if (this.user && this.user.tipoCuenta === 'B2B' || element.inventarioBodega[this.warehouse] >= quantity) {
          if (element.pesoVariable) {
            price +=
              (element.precio / element.pesoGramos) *
              element.pesoGramosVariable *
              quantity;
          } else {
            price += element.precio * quantity;
          }
        }
      }
      if (element.pesoVariable) {
        this.isVariableWeight = false;
      }
    });
    this.priceProducts = price;
  }

  /**
   * Navigate to checkout
   * @param category products category
   */
  public navigateCheckout(): void {
    this.products = JSON.parse(JSON.stringify(this.products));
    var productosFiltrados = this.products.filter(
      (p) => {
        if (this.user && this.user.tipoCuenta === 'B2B') {
          return true;
        } else {
          return p.inventarioBodega[this.warehouse] >= p.quantity;
        }
      }
    );
    if (this.isPlan3000) {
      const productosFiltradosDos = productosFiltrados.filter(
        (fp) => {
          if (fp.listaPrecios) {
            if (fp.listaPrecios['028']) return fp;
          }
        }
      );
      productosFiltrados = productosFiltradosDos;
    }
    this.shoppingCartService.cleanAndAddProducts(productosFiltrados);
    this.router.navigate(['/checkout']);
    this.onCloseShoppingCart();
  }

  /**
   * Function to determine if the product does not have stock by plan 3000 or in general in the store
   * @param product Product to be verified
   * @returns boolean variable that indicates whether or not it has stock
   */
  public isNoStock(product) {
    var isGrayScale = false
    if (this.user && this.user.tipoCuenta !== 'B2B') {
      if (this.isPlan3000) {
        if (product.listaPrecios) {
          if (!product.listaPrecios['028']) isGrayScale = true
        }
      }
      if (product.inventarioBodega[this.warehouse] < product.quantity || product.inventarioBodega[this.warehouse] == undefined) {
        isGrayScale = true
      }
    }
    return isGrayScale
  }

  public isDisabled() {
    let disable = false
    if ((this.priceProducts == 0) || (this.user && this.user.estado && this.user.estado == 'Deshabilitado')) disable = true
    return disable
  }
  pricePerPound(price: number, weightGramsVariable: number, weightGrams: number) {
    return getPricePerPound(price, weightGramsVariable, weightGrams);
  }
}
